import { theme } from '@/components/graphs/types/Theme'
import { merge } from 'highcharts'

export default (other = {}) => merge(true, {
  chart: {
    type: 'area',
    zoomType: 'x'
  },
  boost: { seriesThreshold: 5000 },
  title: {
    text: ''
  },
  tooltip: {
    xDateFormat: '%e %b %k:%M'
  },
  credits: {
    enabled: false
  },
  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%e %b %k:%M}'
    }
  },
  time: {
    useUTC: false
  },
  yAxis: {
    min: 0,
    minRange: 0.1
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'bottom'
  },
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    }]
  },
  series: {
    area: {
      borderWidth: 1
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false
      }
    }
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 3
      }
    }
  }
}, theme(), other)
