import globalGroups from '@/utils/globalGroups'
import types from './types'
import irp_get from '@/api/methods/irp_get'
import { providerParameterMappings as ppm } from '@/components/config/data/parameterMappings'

// initial state
const state = {
  groups: [],
  providers: []
}

// getters
const getters = {
  getMergedGroups: state => { return globalGroups.mergeGroups(state.groups) }
}

// actions
const actions = {
  fetchGlobalGroups ({ commit }, instances) {
    const parameters = { section: 'config/v2', hosts: instances }

    return irp_get(parameters)
      .then(({ data }) => {
        commit(types.GROUPS, data)
        commit(types.PROVIDERS, data)
      })
  },
  fetchGroups ({ commit }, instances) {
    const parameters = { section: 'config/v2', hosts: instances }

    return irp_get(parameters).then(({ data }) => commit(types.GROUPS, data))
  },
  fetchProviders ({ commit }, instances) {
    const parameters = { section: 'config/v2', hosts: instances }

    return irp_get(parameters).then(({ data }) => commit(types.PROVIDERS, data))
  }
}

// mutations
const mutations = {
  [types.GROUPS] (state, GlobalGroups) {
    const groups = []
    Object
      .entries(GlobalGroups)
      .forEach(([instanceId, { data }]) => {
        if (data && data.GlobalGroup) {
          data.GlobalGroup.forEach(({ id, parameters }) => {
            groups.push({
              id,
              instanceId,
              ...parameters
            })
          })
        }
      })

    state.groups = groups
  },

  [types.PROVIDERS] (state, Providers) {
    const providers = []
    Object
      .entries(Providers)
      .forEach(([instanceId, { data }]) => {
        if (data && data.Provider) {
          data.Provider.forEach(({ id, parameters }) => {
            providers.push({
              id,
              instanceId,
              [ppm.shortName]: parameters[ppm.shortName],
              [ppm.globalGroup]: parameters[ppm.globalGroup] || null
            })
          })
        }
      })

    state.providers = providers
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
