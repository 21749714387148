import types from './types'
import { get } from '@/utils/lodash'
import {
  dashboards_create,
  dashboards_duplicate,
  dashboards_list,
  dashboards_update,
  dashboards_delete,
  widgets_create,
  widgets_list,
  widgets_update,
  widgets_delete
} from '../../../api/methods'

export default {
  fetchDashboards ({ commit }) {
    return dashboards_list()
      .then(response => {
        const data = get(response, 'data', [])
        commit(types.DASHBOARDS, data)
        return data
      })
  },

  duplicateDashboard ({ commit }, body) {
    return dashboards_duplicate({ id: body.id, body })
      .then(({ data }) => {
        commit(types.ADD_DASHBOARD, data)
        return data
      })
  },

  updateDashboard ({ commit }, body) {
    return dashboards_update({ id: body.id, body })
      .then(() => {
        commit(types.UPDATE_DASHBOARD, body)
        return body
      })
  },

  deleteDashboard ({ commit }, dashboard) {
    if (!dashboard) return
    return dashboards_delete(dashboard)
      .then(() => commit(types.REMOVE_DASHBOARD, dashboard))
  },

  createDashboard ({ commit }, body) {
    return dashboards_create({ body })
      .then(({ data }) => {
        commit(types.ADD_DASHBOARD, data)
        return data
      })
  },

  /**
   *
   * @param commit
   * @param {String} idDashboard
   * @returns {Promise} Promise will resolve an Array of widget Objects
   */
  fetchWidgets ({ commit }, idDashboard) {
    return widgets_list({ idDashboard })
      .then(({ data }) => {
        commit(types.WIDGETS, data)
        return data
      })
  },

  /**
   *
   * @param commit
   * @param {Object} widget
   * @param {String} idDashboard
   * @param {Boolean} add
   * @returns {Promise}
   */
  createWidget ({ commit }, { widget, idDashboard }) {
    return widgets_create({ idDashboard, body: widget })
      .then(({ data }) => {
        return data
      })
  },

  /**
   *
   * @param commit
   * @param {Object<{ idDashboard, widgets }>} params MUST CONTAIN dashboard id and Array of widgets
   * @returns {Promise}
   */
  updateWidgets ({ commit }, { widgets, idDashboard }) {
    return widgets_update({ body: widgets, idDashboard })
      .then(({ data }) => {
        if (Array.isArray(data)) {
          data.forEach(w => {
            commit(types.UPDATE_WIDGET, w)
          })
        }
        return data
      })
  },

  /**
   *
   * @param commit
   * @param {Object} widget MUST CONTAIN dashboard id and widget id
   * @returns {Promise}
   */
  deleteWidget ({ commit }, widget) {
    return widgets_delete({ idDashboard: widget.idDashboard, idWidget: widget.id })
      .then(data => {
        commit(types.REMOVE_WIDGET, widget.id)
        return data
      })
  }
}
