const Configuration = () => import(/* webpackChunkName: "Configuration" */ '@/views/Configuration/Configuration')

export default function () {
  return {
    path: '/configuration',
    name: 'configuration',
    component: Configuration,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-cfg',
      crumbName: 'Configuration',
      roles: ['manager', 'admin'],
      menu: true,
      hotkey: 'SHIFT + C'
    },
    children: [
      {
        path: ':instanceId/:tab',
        name: 'configuration_tab',
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout'
        }
      }
    ]
  }
}
