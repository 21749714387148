const Search = () => import(/* webpackChunkName: "Search" */ '@/views/Search/Search')
const SearchParameters = () => import(/* webpackChunkName: "Search" */ '@/views/Search/SearchParameters')
const SearchRecent = () => import(/* webpackChunkName: "SearchRecent" */ '@/views/Search/SearchRecent')

export default function () {
  return {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'search',
      menu: false,
      crumbMenu: false,
      roles: ['manager', 'admin', 'user']
    },
    children: [
      {
        path: '/search/active',
        name: 'search_parameters',
        component: SearchParameters,
        meta: {
          icon: 'search',
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Active Search',
          crumbMenu: false,
          roles: ['manager', 'admin', 'user']
        }
      },
      {
        path: '/search/recent',
        name: 'search_recent',
        component: SearchRecent,
        meta: {
          icon: 'global',
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Recent Searches',
          crumbMenu: false,
          roles: ['manager', 'admin', 'user']
        }
      }
    ]
  }
}
