import types from './types'
import { isEqual } from 'lodash'

const getMwIndex = (allMw, mw) => {
  const ids = allMw.map(m => m.id ?? m.ids)
  return ids.findIndex(id => isEqual(id, mw.id ?? mw.ids))
}

export default {
  [types.TODAY_MAINTENANCE_STATUS] (state, value) {
    state.todayMaintenanceStatus = value
  },
  [types.MAINTENANCE_WINDOWS] (state, maintenanceWindows) {
    state.all = maintenanceWindows
  },
  [types.ADD_MAINTENANCE_WINDOW] (state, { instanceId, maintenanceWindow }) {
    const foundIndex = getMwIndex(state.all[instanceId], maintenanceWindow)
    if (foundIndex === -1) {
      state.all[instanceId].push(maintenanceWindow)
    }
  },
  [types.UPDATE_MAINTENANCE_WINDOW] (state, { instanceId, maintenanceWindow }) {
    const foundIndex = getMwIndex(state.all[instanceId], maintenanceWindow)
    if (foundIndex !== -1) {
      state.all[instanceId].splice(foundIndex, 1, maintenanceWindow)
    }
  },
  [types.REMOVE_MAINTENANCE_WINDOW] (state, { instanceId, maintenanceWindowId }) {
    const foundIndex = getMwIndex(state.all[instanceId], { id: maintenanceWindowId })
    if (foundIndex !== -1) {
      state.all[instanceId].splice(foundIndex, 1)
    }
  }
}
