import { difference } from '@/utils/lodash'
import { getItem, setItem, clearItem } from '@/utils/localStorage'
import types from './types'

/**
 * Search state holds the current search object. Which have next structure.
 * {
 *   query: { source, type, search },
 *   instances: { [instanceId>]: { status: 'partial', result: {} } }
 * }
 */
export default {
  state: {
    searchState: null,
    recentSearches: getItem('searchRecent', [])
  },
  actions: {
    [types.GET_GLOBAL_RESULTS] ({ commit, state }, payload) {
      const { instanceId, response: { data } } = payload
      try {
        const status = data?.state ?? 'error'

        const { hist_improvements = {}, policies = {}, problem = {} } = data ?? {}

        commit(types.ADD_SEARCH_STATE, { instanceId, results: { hist_improvements, policies, problem }, status })
      } catch (e) {
        console.error(e)
      }
    },
    [types.GLOBAL_SEARCH] ({ commit, state }, { type, search, instances, source }) {
      commit(types.CLEAR_SEARCH_RESULT)

      commit(types.INIT_STATE, { instances, type, search, source })

      commit(types.ADD_RECENT, { source, type, search, instances })
    }
  },
  getters: {
    searchState: state => state.searchState,
    searchRunning: state => {
      return Object.keys(state.searchState?.instances ?? []).some(id => state.searchState.instances[id].status === 'partial' || state.searchState.instances[id].status === 'processing')
    },
    searchComplete: state => {
      const instanceIds = Object.keys(state.searchState?.instances ?? {})
      return instanceIds.length === 0 ? false : instanceIds.every(s => id => state.searchState.instances[id].status === 'complete' || state.searchState.instances[id].status === 'error')
    },
    searchStatuses: state => Array.isArray(state.searchState) ? state.searchState.map(s => s.status) : []
  },
  mutations: {
    [types.ADD_RECENT] (state, parameters) {
      const recent = state.recentSearches.find(d => {
        return d.type === parameters.type &&
          d.search === parameters.search &&
          difference(d.instances, parameters.instances).length === 0 &&
          d.source === parameters.source
      })

      if (recent) {
        recent.dateAt = Date.now()
      } else {
        state.recentSearches.push({
          ...parameters,
          dateAt: Date.now()
        })
      }

      setItem('searchRecent', state.recentSearches)
    },
    [types.REMOVE_RECENT] (state, entry) {
      const index = state.recentSearches.findIndex(s => {
        return s.search === entry.search &&
          s.type === entry.type &&
          s.source === entry.source
      })

      if (index !== -1) {
        state.recentSearches.splice(index, 1)
        setItem('searchRecent', state.recentSearches)
      }
    },
    [types.INIT_STATE] (state, { source, type, search, instances }) {
      state.searchState = {
        query: { source, type, search },
        instances: instances.reduce((acc, instanceId) => {
          acc[instanceId] = { status: 'processing' }
          return acc
        }, {})
      }
    },
    [types.ADD_SEARCH_STATE] (state, { instanceId, status, results }) {
      const current = state.searchState.instances[instanceId]
      if (current) {
        current.status = status
        current.results = results
      } else {
        state.searchState.instances[instanceId] = { status }
      }

      setItem('searchState', state.searchState, { minutes: 10 })
    },
    [types.CLEAR_SEARCH_RESULT] (state) {
      state.searchState = null
      clearItem('searchState')
    }
  }
}
