import { get } from '@/utils/lodash'
import types from './types'
import { saved_report_list } from '../../../api/methods'

export default {
  fetchSavedReports ({ commit }) {
    return saved_report_list()
      .then(response => {
        const savedReports = get(response, 'data', [])

        commit(types.SET_SAVED_REPORTS, savedReports)

        return savedReports
      })
  }
}
