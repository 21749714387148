import { getDomain } from './request'
export const getInboundRuleList_RAW_URL = () => '/irp/inboundperformance/rules'

export const getInboundRuleList_URL = (parameters = {}) => {
  let path = getInboundRuleList_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.id !== undefined) {
    queryParameters.id = parameters.id
  }
  if (parameters.provider_id !== undefined) {
    queryParameters.provider_id = parameters.provider_id
  }
  if (parameters.text !== undefined) {
    queryParameters.text = parameters.text
  }
  if (parameters.status !== undefined) {
    queryParameters.status = parameters.status
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
