const PoliciesBase = () => import(/* webpackChunkName: "PoliciesBase" */ '@/views/Policies/PoliciesBase')
const RoutingPoliciesList = () => import(/* webpackChunkName: "RoutingPoliciesList" */ '@/views/Policies/routing/List')
const RoutingDetails = () => import(/* webpackChunkName: "RoutingDetails" */ '@/views/Policies/routing/RoutingDetails')
const FlowspecExempted = () => import(/* webpackChunkName: "FlowspecExempted" */ '@/views/Policies/flowspec/ExemptedList.vue')
const FlowspecAffected = () => import(/* webpackChunkName: "FlowspecAffected" */ '@/views/Policies/flowspec/AffectedList.vue')
const FlowspecHistory = () => import(/* webpackChunkName: "FlowspecAffected" */ '@/views/Policies/flowspec/HistoryList.vue')
const FlowSpecPoliciesList = () => import(/* webpackChunkName: "FlowSpecPoliciesList" */ '@/views/Policies/flowspec/List')

export default function () {
  return [
    {
      path: '/policies',
      name: 'policies',
      redirect: { name: 'routing' },
      component: PoliciesBase,
      meta: {
        requiredAuth: true,
        layout: 'LoggedLayout',
        icon: 'icon-policies',
        menu: true,
        crumbMenu: true,
        crumbName: 'Policies',
        hotkey: 'SHIFT + J'
      },
      children: [
        {
          name: 'routing',
          path: '/routing/list',
          component: RoutingPoliciesList,
          meta: {
            requiredAuth: true,
            layout: 'LoggedLayout',
            icon: 'policies',
            crumbName: 'Routing Policies'
          }
        },
        {
          name: 'flowspec',
          path: '/flowspec/list',
          component: FlowSpecPoliciesList,
          meta: {
            icon: 'flowspec',
            requiredAuth: true,
            layout: 'LoggedLayout',
            crumbName: 'Flowspec Policies'
          }
        }
      ]
    },
    {
      path: '/policies/routing/details/:id/:instance',
      name: 'policies-routing-details',
      component: RoutingDetails,
      meta: {
        requiredAuth: true,
        layout: 'LoggedLayout',
        menu: false
      }
    },
    {
      path: '/policies/flowspec/exempted',
      name: 'flowspec-exempted-details',
      component: FlowspecExempted,
      meta: {
        requiredAuth: true,
        layout: 'LoggedLayout',
        menu: false
      }
    },
    {
      path: '/policies/flowspec/affected',
      name: 'flowspec-affected-details',
      component: FlowspecAffected,
      meta: {
        requiredAuth: true,
        layout: 'LoggedLayout',
        menu: false
      }
    },
    {
      path: '/policies/flowspec/history',
      name: 'flowspec-history',
      component: FlowspecHistory,
      meta: {
        requiredAuth: true,
        layout: 'LoggedLayout',
        menu: false
      }
    }
  ]
}
