import { getDomain } from './request'
export const senders_delete_RAW_URL = () => '/senders/{id}'

export const senders_delete_URL = (parameters = {}) => {
  let path = senders_delete_RAW_URL()
  let query = ''
  let queryParameters = {}

  path = path.replace('{id}', `${parameters.id}`)

  if (parameters.confirm !== undefined) {
    queryParameters.confirm = parameters.confirm
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
