export default {
  search: {
    name: 'search',
    in: 'query',
    required: false,
    schema: { type: 'string', minLength: 0, maxLength: 30 },
    camelCaseName: 'search'
  },
  size: {
    name: 'size',
    in: 'query',
    required: false,
    schema: { type: 'number', minimum: 10, maximum: 30 },
    camelCaseName: 'size'
  }
}
