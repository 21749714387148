import { entityActions, schemaActions } from '../../helpers/actions'
import { entityMutations } from '../../helpers/mutations'
import subscriptions_list from '@/api/methods/subscriptions_list'
import subscriptions_create from '@/api/methods/subscriptions_create'
import subscriptions_update from '@/api/methods/subscriptions_update'
import subscriptions_delete from '@/api/methods/subscriptions_delete'

export default {
  state: {
    subscriptions: []
  },
  actions: {
    ...schemaActions('subscription', { create: subscriptions_create, update: subscriptions_update }),
    ...entityActions(
      'subscription',
      ['create', 'update', 'fetch'],
      { subscriptions_list, subscriptions_create, subscriptions_update }
    ),
    deleteSubscription: function (ctx, id) { return subscriptions_delete({ id }).then(response => response) }
  },
  mutations: {
    ...entityMutations('subscription', ['add', 'update', 'set', 'remove'])
  }
}
