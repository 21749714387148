import Vue from 'vue'
import { userDateFormat, userDateTimeFormat } from '@/utils/helpers'
import { isObject } from '@/utils/lodash'

export const capitalize = value => {
  return typeof value === 'string' && value.length ? value[0].toUpperCase() + value.slice(1, value.length).toLowerCase() : value
}

export const dateTime = value => userDateTimeFormat(value)

// Vue filter for formatting only date, in format selected by the user
Vue.filter('date', value => {
  if (value && (typeof value === 'string' || new Date(value).toString() !== 'Invalid Date')) {
    return userDateFormat(value)
  } else {
    return 'Invalid Date'
  }
})

// Vue filter for formatting full date and time format, selected by the user
Vue.filter('dateTime', dateTime)

Vue.filter('empty', value => {
  return value || '-'
})

Vue.filter('capitalize', capitalize)

Vue.filter('formatInstance', instance => {
  return typeof instance === 'object' ? instance.shortName || instance.description || instance.host : instance
})

Vue.filter('camelToWords', (str, separator = ' ') => {
  if (str) {
    const ensureStringValue = isObject(str) && !Array.isArray(str) ? JSON.stringify(str) : String(str).toString()
    return capitalize(ensureStringValue
      .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2'))
  }
})

Vue.filter('strTruncate', (value, num) => {
  return value.length > num ? value.slice(0, num) + '...' : value
})
