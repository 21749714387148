const Logout = () => import(/* webpackChunkName: "Logout" */ '@/views/Auth/Logout')

export default function () {
  return {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { requiredAuth: true, layout: 'AuthLayout' }
  }
}
