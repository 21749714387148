const Login = () => import(/* webpackChunkName: "Login" */ '@/views/Auth/Login')

export default function () {
  return {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
    meta: { requiredAuth: false, layout: 'AuthLayout' }
  }
}
