export default {
  id: {
    in: 'path',
    name: 'id',
    required: true,
    schema: { type: 'string', minLength: 16, maxLength: 16 },
    camelCaseName: 'id'
  },
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  }
}
