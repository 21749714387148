export default {
  FILTERS: 'FILTERS',
  WIDGETS: 'WIDGETS',
  ADD_WIDGET: 'ADD_WIDGET',
  REMOVE_WIDGET: 'REMOVE_WIDGET',
  UPDATE_WIDGET: 'UPDATE_WIDGET',
  DASHBOARDS: 'DASHBOARDS',
  ADD_DASHBOARD: 'ADD_DASHBOARD',
  UPDATE_DASHBOARD: 'UPDATE_DASHBOARD',
  REMOVE_DASHBOARD: 'REMOVE_DASHBOARD'
}
