export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  alias: {
    in: 'query',
    name: 'alias',
    required: true,
    schema: { type: 'string', maxLength: 200, minLength: 1 },
    camelCaseName: 'alias'
  }
}
