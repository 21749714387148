import { cloneDeep, merge, omit } from '@/utils/lodash'

/**
 * Function takes all filters definitions from described in irp.yml,
 * and generates full json schema for validation all filters parameters.
 * It is possible to generate any complexity of schema described by the draft-7.
 * Returned schema must be compiled by ajv.
 *
 * @param filters {Array} Array of filters definitions
 *
 * @returns {Object}
 */
export function buildSchema (filters, values) {
  return filters.reduce((reduced, filter) => {
    const { name = false, schema = false } = filter
    if (name && schema) {
      const cloned = cloneDeep(schema)

      // clear non schema words
      delete cloned['dictionary']

      // normalize enums
      if (Array.isArray(cloned.enum) && cloned.enum.length) {
        cloned.enum = cloned.enum.map(nm => {
          return typeof nm === 'object' && nm.value !== undefined
            ? nm.id ? nm.id : nm.value
            : nm
        })
      } else {
        delete cloned.enum
      }

      if (schema['x-if-then'] && schema.allOf) {
        const key = schema['x-if-then']
        const aggregated = []
        if (values[key]) {
          schema['allOf'].forEach(sc => {
            const value = sc['x-then']
            const ifThen = {
              if: {
                properties: { [key]: { const: value } }
              },
              then: {
                properties: {
                  [name]: omit(sc, ['x-then'])
                }
              }
            }
            aggregated.push(ifThen)
          })

          merge(reduced, { allOf: aggregated })
        } else {
          const oneOf = schema['allOf'].find(sc => sc['x-then'] === false)
          if (oneOf) {
            reduced.properties[name] = omit(oneOf, ['x-then'])
          }
        }
      } else {
        reduced.properties[name] = cloned
      }
    }

    return reduced
  }, { type: 'object', properties: {} })
}
