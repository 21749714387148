import Vue from 'vue'
import { isEmpty } from '@/utils/lodash'
import store from '@/store'
import socketio from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import Config from '@/config'

/**
 * Socket service, connect auth user to web socket after authentication, disconnected if logged out
 */
const io = socketio(`${Config.WEB_SOCKET_HOST()}`, Config.WS_OPTIONS) // socket initiated but not connected

store.watch(state => state.auth.profile, user => {
  if (user === null && io.connected === true) {
    io.disconnect()
  } else if (isEmpty(user) === true && io.connected === true) {
    io.disconnect()
  } else if (isEmpty(user) === false && io.connected === false) {
    io.connect()
  }
}, {
  immediate: true,
  deep: true
})

// close all open socket connections
window.addEventListener('beforeunload', () => {
  io.close()
})

Vue.use(new VueSocketIO({
  debug: process.env.NODE_ENV === 'development',
  connection: io,
  vuex: {
    store,
    actionPrefix: '',
    mutationPrefix: ''
  }
}))
