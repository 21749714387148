const MitigationBase = () => import(/* webpackChunkName: "MitigationBase" */ '@/views/ThreatMitigation/MitigationBase')
const Monitor = () => import(/* webpackChunkName: "BlackholingMonitor" */ '@/views/ThreatMitigation/Monitor/Monitor')
const History = () => import(/* webpackChunkName: "BlackholingMonitor" */ '@/views/ThreatMitigation/History/History')
const MitigationFeed = () => import(/* webpackChunkName: "MitigationFeed" */ '@/views/ThreatMitigation/MitigationFeeds/MitigationFeed')
const MitigationRules = () => import(/* webpackChunkName: "MitigationRules" */ '@/views/ThreatMitigation/MitigationRules/MitigationRules')

export default function () {
  return {
    path: '/mitigation',
    name: 'mitigation',
    redirect: { name: 'monitor' },
    component: MitigationBase,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-threat',
      menu: true,
      crumbName: 'Threat Mitigation',
      crumbMenu: true,
      hotkey: 'SHIFT + B'
    },
    children: [
      {
        name: 'monitor',
        path: 'monitor',
        component: Monitor,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'line_chart',
          crumbName: 'Monitor'
        }
      },
      {
        name: 'feeds',
        path: 'feeds',
        component: MitigationFeed,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'system_not',
          crumbName: 'Mitigation Feed'
        }
      },
      {
        name: 'rules',
        path: 'rules',
        component: MitigationRules,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'cfg',
          crumbName: 'Mitigation Rules'
        }
      },
      {
        name: 'history',
        path: 'history',
        component: History,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'timestamp',
          crumbName: 'History'
        }
      }
    ]
  }
}
