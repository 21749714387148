import { getDomain } from './request'
export const tools_looking_glass_RAW_URL = () => '/irp/tools/lookingGlass'

export const tools_looking_glass_URL = (parameters = {}) => {
  let path = tools_looking_glass_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.algo !== undefined) {
    queryParameters.algo = parameters.algo
  }
  if (parameters.destination !== undefined) {
    queryParameters.destination = parameters.destination
  }
  if (parameters.provider !== undefined) {
    queryParameters.provider = parameters.provider
  }
  if (parameters.packets !== undefined) {
    queryParameters.packets = parameters.packets
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
