import { cloneDeep, get } from '@/utils/lodash'

export default class Filter {
  constructor (definition, instanceId, version) {
    this._definition = definition
    this._dictionary = null
    this._options = null
    this._value = this._previous = get(definition, 'schema.default', '')
    this._isChanged = false
    this._isDefault = false
    this._instanceId = instanceId
    this._instanceVersion = version
  }

  get isDisplayed () {
    return !!get(this._definition, 'x-ui.component', '').length
  }

  get description () {
    return get(this._definition, 'description', '')
  }

  get name () {
    return get(this._definition, 'camelCaseName', '')
  }

  get instanceId () {
    return this._instanceId
  }

  get instance () {
    const { _instanceId: id, _instanceVersion: version } = this
    return { id, version }
  }

  get schema () {
    return get(this._definition, 'schema', {})
  }

  get ui () {
    return get(this._definition, 'x-ui', {})
  }

  /**
   * Return true if filter value set to default value.
   * @return {boolean}
   */
  get isDefault () {
    return (this.schema?.default ?? undefined) === this.value
  }

  /**
   * Return true if value is empty.
   *
   * @return {boolean}
   */
  get isEmpty () {
    return this.value === null ||
      this.value === undefined ||
      String(this.value).length === 0
  }

  /**
   * Is changed.
   */
  get isChanged () {
    return !!this._value && !this.isDefault
  }

  dictionaryName () {
    return get(this._definition, 'x-ui.dictionary', false)
  }

  get hasDictionary () {
    return typeof this.dictionary === 'function'
  }

  get dictionary () {
    return this._dictionary
  }

  set dictionary (v) {
    if (typeof v === 'function') {
      this._dictionary = v
    }
  }

  get value () {
    return this._value
  }

  set value (value) {
    this._value = value
    this._isChanged = true
  }

  get options () {
    return this._options
  }

  set options (value) {
    this._options = value
  }

  /**
   * Return value for display in ui.
   *
   * @param value {string, number, array, undefined} Parameter value
   * @return {string|boolean}
   */
  displayValueFor (value = undefined) {
    if (Array.isArray(this.options) && this.options.some(o => o.label && o.value)) {
      if (Array.isArray(value)) {
        return value.map(v => {
          const option = this.options.find(o => o.value === v)
          return option?.label ?? value
        }).join(', ')
      }

      const option = this.options.find(o => o.value === (value || this.value))
      return option?.label ?? value
    }
    return value
  }

  /**
   * Remember last state, for restore function
   */
  memoize () {
    this._previous = cloneDeep(this.value)
  }

  /**
   * Restore filter value to a previous state.
   */
  restore () {
    this._value = this._previous
  }

  reset () {
    this._value = this._previous = get(this._definition, 'schema.default', '')
    this._isChanged = false
  }
}
