import { getDomain } from './request'
export const userDirectoriesSync_RAW_URL = () => '/userDirectories/{id}/sync'

export const userDirectoriesSync_URL = (parameters = {}) => {
  let path = userDirectoriesSync_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
