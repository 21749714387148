import { getStats } from '@/components/graphs/utils/statsHelpers'
import { bandwidthFormatter } from '@/utils/helpers'

export function drawTotalBandwidthStats (series) {
  this.graph.legend.layout = 'vertical'
  delete this.graph.legend.align
  delete this.graph.legend.verticalAlign

  const [inboundStats, outboundStats, currentNinetyFivethInStats, currentNinetyFivethOutStats] = series.map(getStats)
  const totalBandwidthStats = [
    inboundStats,
    outboundStats,
    currentNinetyFivethInStats.avg,
    currentNinetyFivethOutStats.avg
  ]

  this.graph.legend.labelFormatter = function () {
    const stats = totalBandwidthStats[this._i]
    const hideStats = typeof stats === 'object' ? 'inline-block' : 'none'
    const averageSizeDisplay = stats?.avg ? stats.avg : stats

    return `
        <div style="width: 600px;">
            <div style="min-width: 120px; margin-right: 5px; display: inline-block">${this?.name}:</div>
            <div style="min-width: 90px; margin-right: 5px; display: inline-block">
                Avg: ${bandwidthFormatter(60, false)(averageSizeDisplay * 10e5)}
            </div>
            <div style="min-width: 90px; margin-right: 5px; display: ${hideStats}">
                Max: ${bandwidthFormatter(60, false)(stats?.max * 10e5)}
            </div>
            <div style="min-width: 90px; margin-right: 5px; display: ${hideStats}">
                Min: ${bandwidthFormatter(60, false)(stats?.min * 10e5)}
            </div>
        </div>
      `
  }

  this.graph.series.forEach((item, index, array) => {
    item.point = {
      events: {
        mouseOver: function () {
          const curr = {}
          curr[`${this.colorIndex}`] = bandwidthFormatter(60, false)(this.options?.y * 10e5)
          const [inboundStats, outboundStats, currentNinetyFivethInStats, currentNinetyFivethOutStats] = array.map(getStats)
          const totalBandwidthStats = [
            inboundStats,
            outboundStats,
            currentNinetyFivethInStats.avg,
            currentNinetyFivethOutStats.avg
          ]

          this.series.chart.legend.update({
            labelFormatter: function () {
              const stats = totalBandwidthStats[this._i]
              const hideStats = typeof stats === 'object' ? 'inline-block' : 'none'
              const averageSizeDisplay = stats?.avg ? stats.avg : stats

              return `
                <div style="width: 600px;">
                    <div style="min-width: 120px; margin-right: 5px; display: inline-block">${this?.name}:</div>
                     <div style="min-width: 90px; margin-right: 5px; display: inline-block">
                        Avg: ${bandwidthFormatter(60, false)(averageSizeDisplay * 10e5)}
                     </div>
                     <div style="min-width: 90px; margin-right: 5px; display: ${hideStats}">
                         Max: ${bandwidthFormatter(60, false)(stats?.max * 10e5)}
                     </div>
                     <div style="min-width: 70px; margin-right: 5px; display: ${hideStats}">
                         Min: ${bandwidthFormatter(60, false)(stats?.min * 10e5)}
                     </div>
                    <div style="min-width: 90px; margin-right: 5px; display: ${hideStats}">Current: ${curr[this._i] ?? 0}</div>
                </div>
                `
            }
          })
        }
      }
    }
  })
}

export function drawTotalAndImprovedTrafficStats (series) {
  this.graph.legend.layout = 'vertical'
  delete this.graph.legend.align
  delete this.graph.legend.verticalAlign
  const [inboundImproved, inboundTotal, outboundImproved, outboundTotal] = series.map(getStats)
  const totalAndImprovedTrafficStats = [
    inboundImproved,
    inboundTotal,
    outboundImproved,
    outboundTotal
  ]

  this.graph.legend.labelFormatter = function () {
    const stats = totalAndImprovedTrafficStats[this._i]

    return `
        <div style="width: 600px;">
            <div style="min-width: 140px; margin-right: 5px; display: inline-block">${this?.name}:</div>
            <div style="min-width: 90px; margin-right: 5px; display: inline-block">Avg: ${stats?.avg} Mbps</div>
            <div style="min-width: 90px; margin-right: 5px; display: inline-block">Max: ${stats?.max} Mbps</div>
            <div style="min-width: 90px; margin-right: 5px; display: inline-block">Min: ${stats?.min} Mbps</div>
        </div>
      `
  }

  this.graph.series.forEach((item, index, array) => {
    item.point = {
      events: {
        mouseOver: function () {
          const curr = {}
          curr[`${this.colorIndex}`] = this.options?.y.toFixed()
          const [inboundImproved, inboundTotal, outboundImproved, outboundTotal] = array.map(getStats)
          const totalAndImprovedTrafficStats = [
            inboundImproved,
            inboundTotal,
            outboundImproved,
            outboundTotal
          ]

          this.series.chart.legend.update({
            labelFormatter: function () {
              const stats = totalAndImprovedTrafficStats[this._i]

              return `
                <div style="width: 600px;">
                    <div style="min-width: 140px; margin-right: 5px; display: inline-block">${this?.name}:</div>
                    <div style="min-width: 90px; margin-right: 5px; display: inline-block">Avg: ${stats?.avg} Mbps</div>
                    <div style="min-width: 90px; margin-right: 5px; display: inline-block">Max: ${stats?.max} Mbps</div>
                    <div style="min-width: 90px; margin-right: 5px; display: inline-block">Min: ${stats?.min} Mbps</div>
                    <div style="min-width: 90px; margin-right: 5px; display: inline-block">Current: ${curr[this._i] ?? 0} Mbps</div>
                </div>
                `
            }
          })
        }
      }
    }
  })
}

export function drawBandwidthUsagePerProvider (series) {
  let totalBandwidthStats = []
  if (series.length === 4) {
    const [
      bandwidthStats, outboundStats, currentNinetyFivethOutStats, commitNinetyFivethOutStats
    ] = series.map(getStats)
    totalBandwidthStats = [
      bandwidthStats, outboundStats, currentNinetyFivethOutStats.avg, commitNinetyFivethOutStats.avg
    ]
  } else {
    const [
      bandwidthStats,
      inboundStats,
      outboundStats,
      currentNinetyFivethInStats,
      currentNinetyFivethOutStats,
      commitNinetyFivethInStats,
      commitNinetyFivethOutStats
    ] = series.map(getStats)

    totalBandwidthStats = [
      bandwidthStats,
      inboundStats,
      outboundStats,
      currentNinetyFivethInStats.avg,
      currentNinetyFivethOutStats.avg,
      commitNinetyFivethInStats.avg,
      commitNinetyFivethOutStats.avg
    ]
  }

  const calcCommit = point => `${point} Mbps`

  this.graph.legend.labelFormatter = function () {
    const stats = totalBandwidthStats[this._i]
    const hideStats = typeof stats === 'object' ? 'inline-block' : 'none'
    const averageSizeDisplay = stats?.avg ? stats.avg : stats

    return `
        <div style="width: 600px;">
            <div style="min-width: 120px; margin-right: 5px; display: inline-block">${this?.name}:</div>
            <div style="min-width: 90px; margin-right: 5px; display: inline-block">
                Avg: ${!this?.name.toLowerCase().includes('commit')
                        ? bandwidthFormatter(60, false)(averageSizeDisplay * 10e5)
                        : calcCommit(averageSizeDisplay)
                      }
            </div>
            <div style="min-width: 90px; margin-right: 5px; display: ${hideStats}">
                Max: ${bandwidthFormatter(60, false)(stats?.max * 10e5)}
            </div>
            <div style="min-width: 90px; margin-right: 5px; display: ${hideStats}">
                Min: ${bandwidthFormatter(60, false)(stats?.min * 10e5)}
            </div>
        </div>
      `
  }

  this.graph.series.forEach((item, index, array) => {
    item.point = {
      events: {
        mouseOver: function () {
          const curr = {}
          curr[`${this.colorIndex}`] = bandwidthFormatter(60, false)(this.options?.y * 10e5)
          if (array.length === 4) {
            const [
              bandwidthStats, outboundStats, currentNinetyFivethOutStats, commitNinetyFivethOutStats
            ] = series.map(getStats)
            totalBandwidthStats = [
              bandwidthStats, outboundStats, currentNinetyFivethOutStats.avg, commitNinetyFivethOutStats.avg
            ]
          } else {
            const [
              bandwidthStats,
              inboundStats,
              outboundStats,
              currentNinetyFivethInStats,
              currentNinetyFivethOutStats,
              commitNinetyFivethInStats,
              commitNinetyFivethOutStats
            ] = series.map(getStats)

            totalBandwidthStats = [
              bandwidthStats,
              inboundStats,
              outboundStats,
              currentNinetyFivethInStats.avg,
              currentNinetyFivethOutStats.avg,
              commitNinetyFivethInStats.avg,
              commitNinetyFivethOutStats.avg
            ]
          }

          this.series.chart.legend.update({
            labelFormatter: function () {
              const stats = totalBandwidthStats[this._i]
              const hideStats = typeof stats === 'object' ? 'inline-block' : 'none'
              const averageSizeDisplay = stats?.avg ? stats.avg : stats

              return `
                <div style="width: 600px;">
                    <div style="min-width: 120px; margin-right: 5px; display: inline-block">${this?.name}:</div>
                     <div style="min-width: 90px; margin-right: 5px; display: inline-block">
                        Avg: ${!this?.name.toLowerCase().includes('commit')
                                ? bandwidthFormatter(60, false)(averageSizeDisplay * 10e5)
                                : calcCommit(averageSizeDisplay)
                              }
                     </div>
                     <div style="min-width: 90px; margin-right: 5px; display: ${hideStats}">
                         Max: ${bandwidthFormatter(60, false)(stats?.max * 10e5)}
                     </div>
                     <div style="min-width: 70px; margin-right: 5px; display: ${hideStats}">
                         Min: ${bandwidthFormatter(60, false)(stats?.min * 10e5)}
                     </div>
                    <div style="min-width: 90px; margin-right: 5px; display: ${hideStats}">Current: ${curr[this._i] ?? 0}</div>
                </div>
                `
            }
          })
        }
      }
    }
  })
}
