const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ '@/views/Auth/ResetPassword')

export default function () {
  return {
    path: '/resetting/:option',
    name: 'Reset password',
    component: ResetPassword,
    meta: { requiredAuth: false, layout: 'AuthLayout' }
  }
}
