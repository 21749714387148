export default {
  page: {
    name: 'page',
    in: 'query',
    schema: { type: 'number', default: 1 },
    camelCaseName: 'page'
  },
  sortBy: {
    name: 'sortBy',
    in: 'query',
    schema: { type: 'string', default: 'ts' },
    camelCaseName: 'sortBy'
  },
  order: {
    name: 'order',
    in: 'query',
    schema: { type: 'string', enum: ['ASC', 'DESC'], default: 'DESC' },
    camelCaseName: 'order'
  },
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  prefix: {
    in: 'query',
    name: 'prefix',
    description: 'Prefix/IP',
    'x-ui': { component: 'ui-filter-autocomplete', dictionary: 'prefixSearch', props: { placeholder: 'Any Prefix/IP' } },
    schema: { type: 'string' },
    camelCaseName: 'prefix'
  },
  prefixSize: {
    name: 'prefixSize',
    in: 'query',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Prefix size' } },
    schema: { 'x-if-then': 'ipVer', type: 'number', allOf: [{ type: 'number', 'x-then': false, errorMessage: 'Out of range 8 - 24 32 - 48', oneOf: [{ minimum: 8, maximum: 24 }, { minimum: 32, maximum: 48 }] }, { type: 'number', minimum: 8, maximum: 24, errorMessage: 'Out of range 8 - 24', 'x-then': 4 }, { type: 'number', minimum: 32, maximum: 48, errorMessage: 'Out of range 32 - 48', 'x-then': 6 }] },
    description: 'Prefix size',
    camelCaseName: 'prefixSize'
  },
  providertonexthop: {
    name: 'providertonexthop',
    in: 'query',
    description: 'Next hop',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Any Next Hop' } },
    schema: { type: 'string' },
    camelCaseName: 'providertonexthop'
  },
  asnumber: {
    in: 'query',
    name: 'asnumber',
    description: 'AS number',
    'x-ui': { dictionary: 'textSearch', component: 'ui-filter-autocomplete', props: { placeholder: 'Any AS number' } },
    schema: { type: 'number' },
    camelCaseName: 'asnumber'
  },
  peerasnumber: {
    in: 'query',
    description: 'Peer AS Number',
    name: 'peerasnumber',
    'x-ui': { dictionary: 'textSearch', component: 'ui-filter-autocomplete', props: { placeholder: 'Any AS Number' } },
    schema: { type: 'string' },
    camelCaseName: 'peerasnumber'
  },
  peerasname: {
    in: 'query',
    description: 'Peer AS Name',
    name: 'peerasname',
    'x-ui': { dictionary: 'textSearch', component: 'ui-filter-autocomplete', props: { placeholder: 'Any AS name' } },
    schema: { type: 'string' },
    camelCaseName: 'peerasname'
  },
  bgpdMode: {
    name: 'bgpdMode',
    in: 'query',
    description: 'BGPd mode',
    'x-ui': { dictionary: 'bgpdModes', component: 'ui-filter-select', props: { placeholder: 'Any BGPd mode' } },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }], minimum: 0 },
    camelCaseName: 'bgpdMode'
  },
  ipVer: {
    name: 'ipVer',
    in: 'query',
    description: 'IPv4/IPv6',
    'x-ui': { dictionary: 'ipVersions', component: 'ui-filter-select', props: { placeholder: 'Any IP version' } },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'ipVer'
  },
  providerFrom: {
    name: 'providerFrom',
    in: 'query',
    description: 'From provider',
    'x-ui': { dictionary: 'providers', component: 'ui-filter-select', props: { placeholder: 'From any provider' } },
    schema: { type: 'number' },
    camelCaseName: 'providerFrom'
  },
  providerTo: {
    name: 'providerTo',
    in: 'query',
    description: 'To provider',
    'x-ui': { dictionary: 'providers', component: 'ui-filter-select', props: { placeholder: 'From any provider' } },
    schema: { type: 'number' },
    camelCaseName: 'providerTo'
  },
  probingSource: {
    name: 'probingSource',
    in: 'query',
    description: 'Probing source',
    'x-ui': { dictionary: 'probingSources', component: 'ui-filter-select' },
    schema: { type: 'number' },
    camelCaseName: 'probingSource'
  },
  improvementAge: {
    name: 'improvementAge',
    in: 'query',
    description: 'Improvement age',
    'x-ui': { dictionary: 'improvementAges', component: 'ui-filter-select' },
    schema: { type: 'number' },
    camelCaseName: 'improvementAge'
  },
  rd: {
    name: 'rd',
    in: 'query',
    description: 'RD',
    'x-ui': { dictionary: 'routingDomains', component: 'ui-filter-select', props: { placeholder: 'All RDs' } },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'rd'
  },
  type: {
    name: 'type',
    in: 'query',
    description: 'Improvement type',
    'x-ui': { dictionary: 'improvementCauses', component: 'ui-filter-select', props: { placeholder: 'Any type', multiple: true } },
    schema: { type: 'array', minLength: 1, items: { oneOf: [{ type: 'string' }, { type: 'number' }] } },
    camelCaseName: 'type'
  },
  start: {
    name: 'start',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'start'
  },
  end: {
    name: 'end',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'end'
  },
  pageSize: {
    name: 'pageSize',
    in: 'query',
    description: 'Number of records',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Results per page' } },
    schema: { type: 'number', default: 20, allOf: [{ type: 'number', minimum: 1, errorMessage: 'must be > 0' }, { type: 'number', maximum: 300, errorMessage: 'must be < 300' }] },
    camelCaseName: 'pageSize'
  }
}
