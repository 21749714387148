const Settings = () => import(/* webpackChunkName: "Settings" */ '@/views/Settings/Settings')
const SettingsInstances = () => import(/* webpackChunkName: "SettingsInstances" */ '@/views/Settings/SettingsInstances')
const SettingsUsers = () => import(/* webpackChunkName: "SettingsUsers" */ '@/views/Settings/SettingsUsers')
const SettingsSecurity = () => import(/* webpackChunkName: "SettingsSecurity" */ '@/views/Settings/SettingsSecurity')
const SettingsFrontend = () => import(/* webpackChunkName: "SettingsFrontend" */ '@/views/Settings/SettingsFrontend')
const SettingsSenders = () => import(/* webpackChunkName: "SettingsSenders" */ '@/views/Settings/SettingsSenders')

export default function () {
  return {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-admin',
      menu: true,
      crumbName: 'Management',
      crumbMenu: true,
      roles: ['manager', 'admin'],
      hotkey: 'SHIFT + S'
    },
    children: [
      {
        path: '/settings/instances',
        name: 'instances',
        component: SettingsInstances,
        meta: {
          icon: 'device',
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Instances',
          crumbMenu: false,
          roles: ['manager', 'admin']
        }
      },
      {
        path: '/settings/users',
        name: 'users',
        component: SettingsUsers,
        meta: {
          icon: 'user_2',
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'User Management',
          crumbMenu: false,
          roles: ['admin']
        }
      },
      {
        path: '/settings/security',
        name: 'security',
        component: SettingsSecurity,
        meta: {
          icon: 'shield',
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Access Restriction',
          crumbMenu: false,
          roles: ['admin']
        }
      },
      {
        path: '/settings/frontend',
        name: 'frontend',
        component: SettingsFrontend,
        meta: {
          icon: 'frontend',
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Frontend',
          crumbMenu: false,
          roles: ['admin']
        }
      },
      {
        path: '/settings/senders',
        name: 'senders',
        component: SettingsSenders,
        meta: {
          icon: 'emailing',
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Senders',
          crumbMenu: false,
          roles: ['admin']
        }
      }
    ]
  }
}
