import Types from './types'

export default {
  [Types.SET_SAVED_REPORTS] (state, savedReports) {
    state.list = savedReports.map(r => ({
      ...r,
      loading: !r.exportedAt
    }))
  },
  [Types.SAVED_REPORT_LOADING] (state, { reportId, loading }) {
    state.list = state.list.map(r => r.reportId !== reportId
      ? r
      : ({
        ...r,
        loading
      }))
  },
  [Types.SAVED_REPORT_ADDED] (state, savedReport) {
    state.list.unshift({
      ...savedReport,
      loading: true
    })
  },
  [Types.SAVED_REPORT_READY] (state, { reportId }) {
    state.list = state.list.map(r => r.reportId !== reportId
      ? r
      : ({
        ...r,
        loading: false
      }))
  }
}
