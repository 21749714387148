<template>
  <ui-dialog
    v-if="visibility"
    ref="dialog"
    :title="dialogTitle"
    :width="width"
    modal
    disable-close-btn
  >
    <template #title>
      <div :class="type" class="title">
        <ui-icon
          v-if="icon"
          class="icon"
          :class="type"
          :icon="icon"
          test="icon"
        />
        <span>{{ dialogTitle }}</span>
      </div>
    </template>
    <template #body>
      <div>
        <slot name="text">
          <p
            :class="type"
            v-text="dialogText"
          />
        </slot>
        <slot name="extension" />
      </div>
    </template>
    <template #footer>
      <div>
        <ui-button
          color="gray"
          rounded
          test="reject-btn"
          @click="userRejected"
        >
          {{ rejectButtonText }}
        </ui-button>
        <ui-button
          v-if="showDiscardBtn"
          rounded
          color="gray"
          test="discard-btn"
          @click="userDiscarded"
        >
          {{ discardButtonText }}
        </ui-button>
        <ui-button
          color="accent"
          rounded
          :disabled="!canProceed"
          test="confirm-btn"
          @click="userConfirmed"
        >
          {{ confirmButtonText }}
        </ui-button>
      </div>
    </template>
  </ui-dialog>
</template>
<script>
import UiDialog from '@/components/common/DialogWrapper'
import UiButton from 'uilib/src/Button.vue'
import UiIcon from 'uilib/src/Icon'

export default {
  name: 'ConfirmationDialog',
  components: {
    UiDialog,
    UiButton,
    UiIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: undefined,
      validator: function (value) {
        return ['warning', 'danger', 'info'].indexOf(value) !== -1
      }
    },
    confirmText: {
      type: String,
      default: ''
    },
    rejectText: {
      type: String,
      default: ''
    },
    discardText: {
      type: String,
      default: ''
    },
    showDiscardBtn: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '450px'
    },
    canProceed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visibility: false,
      dialogTitle: this.title,
      dialogText: this.text,
      reject: null,
      resolve: null
    }
  },
  computed: {
    icon () {
      switch (this.type) {
        case 'warning': return 'st_warn'
        case 'danger': return 'st_warn'
        case 'info': return 'info'
        default: return ''
      }
    },
    confirmButtonText () {
      return this.confirmText || 'Confirm'
    },
    rejectButtonText () {
      return this.rejectText || 'Reject'
    },
    discardButtonText () {
      return this.discardText || 'Discard and continue'
    }
  },
  watch: {
    visibility (to) {
      this.$emit('visibilityChange', to)
      if (to) {
        setTimeout(() => {
          document.body.appendChild(this.$refs.dialog.$el)
        })
      }
    }
  },
  methods: {
    show () {
      this.visibility = true
    },
    hide () {
      this.visibility = false
    },
    confirm (properties) {
      if (properties && properties.title) {
        this.dialogTitle = properties.title
      }
      if (properties && properties.text) {
        this.dialogText = properties.text
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        this.show()
      })
    },
    /**
     * Returns nothing when for confirm operation through Promise.resolve
     * @method userConfirmed
     * @return ''
     * */
    userConfirmed () {
      this.visibility = false
      this.resolve()
    },
    /**
     * Returns string cancel when for cancel operation through Promise.reject
     * @method userRejected
     * @return 'cancel'
     * */
    userRejected () {
      this.visibility = false
      this.reject('cancel')
    },
    /**
     * Returns string discard when for discard operation through Promise.resolve
     * @method userDiscarded
     * @return 'discard'
     * */
    userDiscarded () {
      this.visibility = false
      this.resolve('discard')
    }
  }
}
</script>
<style lang="scss" scoped>
  .title {
    .danger {
      color: #cb4444;
    }

    .icon {
      margin-right: 6px;
      color: #969696;

      &::before {
        color: unset;
      }

      &.info {
        color: #648ac6;
      }

      &.danger {
        color: #cb4444;
      }
    }
  }
</style>
