export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  algo: {
    in: 'query',
    name: 'algo',
    schema: { type: 'string', enum: ['mtr', 'traceroute', 'ping'] },
    required: true,
    camelCaseName: 'algo'
  },
  destination: {
    in: 'query',
    name: 'destination',
    required: true,
    schema: { type: 'string' },
    camelCaseName: 'destination'
  },
  provider: {
    in: 'query',
    name: 'provider',
    schema: { type: 'number' },
    required: true,
    camelCaseName: 'provider'
  },
  packets: {
    in: 'query',
    name: 'packets',
    schema: { type: 'number', default: 10 },
    required: true,
    camelCaseName: 'packets'
  }
}
