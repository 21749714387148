const Events = () => import(/* webpackChunkName: "SystemEvents" */ '@/views/Events/Events')

export default function () {
  return {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      menu: false,
      crumbName: 'Events',
      roles: ['admin', 'manager']
    }
  }
}
