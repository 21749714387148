import { getDomain } from './request'
export const deleteInboundImprovement_RAW_URL = () => '/irp/inboundImprovement'

export const deleteInboundImprovement_URL = (parameters = {}) => {
  let path = deleteInboundImprovement_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.prefixes !== undefined) {
    queryParameters.prefixes = parameters.prefixes
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
