import { getDomain } from './request'
export const getPerformanceImprovements_RAW_URL = () => '/irp/reports/performanceImprovements'

export const getPerformanceImprovements_URL = (parameters = {}) => {
  let path = getPerformanceImprovements_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.start !== undefined) {
    queryParameters.start = parameters.start
  }
  if (parameters.end !== undefined) {
    queryParameters.end = parameters.end
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
