import { getDomain } from './request'
export const widgets_delete_RAW_URL = () => '/dashboard/{idDashboard}/widgets/{idWidget}'

export const widgets_delete_URL = (parameters = {}) => {
  let path = widgets_delete_RAW_URL()
  let query = ''

  path = path.replace('{idDashboard}', `${parameters.idDashboard}`)
  path = path.replace('{idWidget}', `${parameters.idWidget}`)
  return getDomain() + path + query
}
