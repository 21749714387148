export default {
  tool: {
    in: 'query',
    name: 'tool',
    schema: { type: 'string' },
    camelCaseName: 'tool'
  },
  isHost: {
    in: 'query',
    name: 'isHost',
    schema: { type: 'string' },
    camelCaseName: 'isHost'
  },
  offset: {
    in: 'query',
    name: 'offset',
    schema: { type: 'number' },
    camelCaseName: 'offset'
  },
  limit: {
    in: 'query',
    name: 'limit',
    schema: { type: 'number' },
    camelCaseName: 'limit'
  }
}
