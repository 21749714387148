import { getDomain } from './request'
export const deleteCurrentImprovement_RAW_URL = () => '/irp/tools/improve'

export const deleteCurrentImprovement_URL = (parameters = {}) => {
  let path = deleteCurrentImprovement_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.prefixes !== undefined) {
    queryParameters.prefixes = parameters.prefixes
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
