export default {
  idDashboard: {
    name: 'idDashboard',
    in: 'path',
    schema: { type: 'string', minLength: 16, maxLength: 16 },
    required: true,
    camelCaseName: 'idDashboard'
  },
  idWidget: {
    name: 'idWidget',
    in: 'path',
    schema: { type: 'string', minLength: 16, maxLength: 16 },
    required: true,
    camelCaseName: 'idWidget'
  }
}
