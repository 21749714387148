import Type from './types'
import { get, matches } from '@/utils/lodash'
import { tools_create_task, tools_get_tasks, tools_update_task, tools_remove_task } from '@/api/methods'
import Task from '@/models/Task'

export default {
  createTask: ({ commit }, body) => {
    return tools_create_task({ body })
      .then(response => {
        const task = get(response, 'data', body)
        if (body.isHost) {
          task.isHost = body.isHost
        } else task.isHost = ''
        const newTask = new Task(task)
        commit(Type.ADD_TASK, newTask)
        return newTask
      })
  },
  updateTask: ({ state, commit }, body) => {
    return tools_update_task({ id: body.id, body })
      .then(response => {
        const { id, result, error, updatedAt, favorite } = get(response, 'data', body)
        const task = {
          id,
          result,
          error,
          favorite,
          updatedAt
        }
        commit(Type.UPDATE_TASK, task)
        return task
      })
  },
  clearAllTasks: ({ state, dispatch }, { tool, favorite }) => {
    const removes = state.tasks
      .filter(t => t.tool === tool && t.favorite === favorite)
      .map(t => dispatch('deleteTask', { id: t.id }))
    return Promise
      .all(removes)
  },
  deleteTask: ({ state, commit }, { id }) => {
    return tools_remove_task({ id })
      .then(() => {
        commit(Type.REMOVE_TASK, id)
      })
  },
  fetchTasks: ({ state, commit }, payload) => {
    return tools_get_tasks({ ...payload })
      .then(response => {
        const tasks = get(response, 'data', [])
        const addedTasks = []
        tasks.forEach(task => {
          const newTask = new Task(task)
          addedTasks.push(newTask)

          const { id } = task
          const found = state.tasks.find(matches({ id }))
          if (!found) {
            commit(Type.ADD_TASK, newTask)
          }
        })

        // return "has more tasks" flag
        return response.headers['x-has-more-tasks'] === '1'
      })
  }
}
