import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { hash } from '@/version'

const state = {
  profile: null,
  needsReAuth: false,
  needsReload: false, // should we reload the page. Used by "reboot" action
  outdatedVersion: false,
  restricted: false,
  scrollLocked: false, // should we have store common, to store this in it
  sslExpireSoon: false,
  currentVersion: hash,
  mediaQueryTheme: 'light',
  systemDashboard: {
    visible: false,
    instances: [],
    selected: null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
