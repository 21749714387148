import NegativeBar from './NegativeBar'
import { cloneDeep } from '@/utils/lodash'

function FixedNegativeBar () {
  const result = cloneDeep(NegativeBar())

  result.colors = ['#FFA94D', '#ff8400', '#7da7d9', '#5e80bf']
  result.plotOptions.series = { maxPointWidth: 30 }
  result.series = [
    { minPointLength: 5, dataLabels: { align: 'right' } },
    { pointPadding: 0.35, dataLabels: { align: 'right' } },
    { minPointLength: 5, dataLabels: { align: 'left' } },
    { pointPadding: 0.35, dataLabels: { align: 'left' } }
  ]

  return result
}

export default FixedNegativeBar
