import VueDevConfig from './dev.config'
import VueProdConfig from './prod.config'
import Vue from 'vue'

const initialised = {
  config () {
    if (process.env.NODE_ENV === 'development') {
      Object.assign(Vue.config, VueDevConfig)
    } else {
      Object.assign(Vue.config, VueProdConfig)
    }
    return Vue.config
  }
}

export default initialised.config()
