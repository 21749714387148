import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: widgets_create
 * url: /dashboard/{idDashboard}/widgets
 * @param idDashboard { String }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/dashboard/{idDashboard}/widgets'
  let body
  let queryParameters = {}
  let form = {}

  if (parameters.idDashboard === undefined) {
    return Promise.reject(new Error('Missing required String parameter: idDashboard'))
  }
  path = path.replace('{idDashboard}', `${parameters.idDashboard}`)

  body = parameters.body || parameters.requestBody

  if (body === undefined) {
    return Promise.reject(new Error('Missing required parameter: body or requestBody'))
  }

  (config.headers !== undefined) ? (config.headers['Content-Type'] = 'application/json') : (config.headers = { 'Content-Type': 'application/json' })

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('post', domain + path, body, queryParameters, form, config)
}
