const MaintenanceWindow = () => import(/* webpackChunkName: "MaintenanceWindow" */ '@/views/MaintenanceWindow/MaintenanceWindow')

export default function () {
  return {
    path: '/maintenancewindow',
    name: 'maintenancewindow',
    component: MaintenanceWindow,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-maintenance2',
      menu: true,
      crumbName: 'Maintenance Window',
      crumbMenu: true
    }
  }
}
