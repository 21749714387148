import schemaService from '@/services/schema'
import { globalParametersMapping } from '../data/parameterMappings'
import { pick, result } from '@/utils/lodash'
import { isParameterDisplayed } from './showWhen'
import { typesHandler } from '@/components/common/wizard/states/helpers'

function getProperties (section) {
  const schema = schemaService.get()
  let path = 'properties.' + section + '.properties.parameters.properties'
  if (result(schema.properties, section)?.type === 'array') {
    path = 'properties.' + section + '.items.properties.parameters.properties'
  }
  return result(schema, path)
}

function getRequired (section) {
  const schema = schemaService.get()
  let path = 'properties.' + section + '.properties.parameters.required'
  if (result(schema.properties, section)?.type === 'array') {
    path = 'properties.' + section + '.items.properties.parameters.required'
  }
  return result(schema, path)
}

export const sortByOrder = (a, b) => {
  if (a.gmiOrder === b.gmiOrder) {
    return 0
  }
  return a.gmiOrder > b.gmiOrder ? 1 : -1
}

export function createDefaultValue (parameter) {
  let value
  if (typeof parameter.default !== 'undefined') {
    value = parameter.default
  } else if (typesHandler(parameter.type) === 'array') {
    value = []
  } else if (typesHandler(parameter.type) === 'integer' || typesHandler(parameter.type) === 'number') {
    const maximum = parameter.maximum || (parameter.anyOf && parameter.anyOf.length ? parameter.anyOf[0].maximum : null)
    // if somehow you got here, good to know that in case your minimum is 0, you will also get an empty string value in input
    const minimum = typeof parameter.minimum === 'number' ? parameter.minimum : (parameter.anyOf && parameter.anyOf.length ? parameter.anyOf[0].minimum : null)

    if (typeof minimum !== 'undefined' && typeof maximum !== 'undefined') {
      value = minimum
    } else {
      value = ''
    }
  } else if (typesHandler(parameter.type) === 'string') {
    value = ''
  }
  return value
}

export function fillDefaults (parameters, section) {
  const properties = getProperties(section)
  for (const i in parameters) {
    if (properties[i]) {
      parameters[i] = createDefaultValue(properties[i])
    }
  }
  return parameters
}

export function createEntity (section) {
  const properties = getProperties(section)
  const entity = {
    id: null,
    parameters: {}
  }

  for (const i in properties) {
    if (properties[i] && ((properties[i].gmiBlock && properties[i].gmiBlock !== 'unknown') || properties[i].gmiOrder === -1) && properties[i].default !== undefined) {
      entity.parameters[i] = createDefaultValue(properties[i])
    }
  }
  return entity
}

export function getSectionSchema (section) {
  const schema = schemaService.get()
  const properties = getProperties(section)
  const required = getRequired(section)

  const sectionSchema = {
    $id: schema.$id + '/' + section,
    $schema: schema.$schema,
    definitions: schema.definitions,
    properties
  }

  if (required) {
    sectionSchema.required = required
  }

  return sectionSchema
}

export function validateShowWhen (config, showWhen, instanceId) {
  return isParameterDisplayed(showWhen, config, instanceId)
}

export function getParametersByBlock (config, schema, block, instanceId, minOrder = -Infinity) {
  const keys = []
  Object.keys(schema.properties).forEach(parameterName => {
    const parameter = schema.properties[parameterName]
    const blockRule = !block || (parameter.gmiBlock && parameter.gmiBlock === block)
    if (blockRule) {
      const orderRule = parameter.gmiOrder >= minOrder
      const showWhenRule = !parameter.showWhen || validateShowWhen(config, parameter.showWhen, instanceId)
      if (orderRule && blockRule && showWhenRule) {
        keys.push(parameterName)
      }
    }
  })
  return pick(schema.properties, keys)
}

export function needsInitialSetup (config) {
  const checks = [
    () => config.Global.parameters[globalParametersMapping.infraIps].length === 0,
    () => !config.Global.parameters[globalParametersMapping.spanEnabled] && !config.Global.parameters[globalParametersMapping.flowEnabled],
    () => config.Global.parameters[globalParametersMapping.spanEnabled] && !config.Global.parameters[globalParametersMapping.spanInterfaces],
    () => config.Provider.length < 2,
    () => config.BgpPeer.length < 1
  ]
  return checks.some(check => check())
}

const looseCompare = (val1, val2) => {
  return JSON.stringify(val1) === JSON.stringify(val2)
}

export function getDiff (config, initialConfig) {
  const diff = {}
  for (const section in config) {
    const currentSection = config[section]
    if (section === 'Global') {
      for (const parameter in currentSection.parameters) {
        if (!looseCompare(currentSection.parameters[parameter], initialConfig[section].parameters[parameter])) {
          if (!diff[section]) {
            diff[section] = {
              parameters: {}
            }
          }
          diff[section].parameters[parameter] = currentSection.parameters[parameter]
        }
      }
    } else if (section === 'Exchange') {
      currentSection.forEach(exchange => {
        const initialExchange = initialConfig[section].find(e => e.provider === exchange.provider)
        exchange.rules.forEach(rule => {
          const initialRule = initialExchange ? initialExchange.rules.find(r => r.id === rule.id) : null
          if (!initialRule) {
            if (!diff[section]) {
              diff[section] = []
            }

            let exchangeIndex = diff[section].findIndex(ex => ex.provider === exchange.provider)
            if (exchangeIndex === -1) {
              exchangeIndex = diff[section].push({
                provider: exchange.provider,
                rules: []
              }) - 1
            }
            diff[section][exchangeIndex].rules.push(rule)
          } else {
            let diffRule = null
            for (const parameter in rule.parameters) {
              if (!looseCompare(rule.parameters[parameter], initialRule.parameters[parameter])) {
                if (!diffRule) {
                  diffRule = pick(rule, 'id')
                  diffRule.parameters = {}
                }
                diffRule.parameters[parameter] = rule.parameters[parameter]
              }
            }

            if (diffRule) {
              if (!diff[section]) {
                diff[section] = []
              }
              let exchangeIndex = diff[section].findIndex(ex => ex.provider === exchange.provider)
              if (exchangeIndex === -1) {
                exchangeIndex = diff[section].push({
                  provider: exchange.provider,
                  rules: []
                }) - 1
              }
              diff[section][exchangeIndex].rules.push(diffRule)
            }
          }
        })
      })
    } else if (Array.isArray(currentSection)) {
      currentSection.forEach(entity => {
        const initialEntity = initialConfig[section].find(e => e.id === entity.id)
        if (!initialEntity) {
          if (!diff[section]) {
            diff[section] = []
          }
          diff[section].push(entity)
        } else {
          let diffEntity = null
          for (const parameter in entity.parameters) {
            if (!looseCompare(entity.parameters[parameter], initialEntity.parameters[parameter])) {
              if (!diffEntity) {
                diffEntity = pick(entity, 'id')
                diffEntity.parameters = {}
              }
              diffEntity.parameters[parameter] = entity.parameters[parameter]
            }
          }
          if (diffEntity) {
            if (!diff[section]) {
              diff[section] = []
            }
            diff[section].push(diffEntity)
          }
        }
      })
    }
  }
  return diff
}
