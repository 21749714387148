import { getDomain } from './request'
export const userDirectories_update_RAW_URL = () => '/userDirectories/{id}'

export const userDirectories_update_URL = (parameters = {}) => {
  let path = userDirectories_update_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
