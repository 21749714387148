import Vue from 'vue'
import routes from './routes'
import Router from 'vue-router'
import store from '../store/index'
import setup from '@/router/guards/setup'
import Type from '../store/modules/auth/types'
import { isObject, isEmpty } from '@/utils/lodash'
import ReportsDefinitions from '@/constants/reports'
import graphs from '@/constants/graphs'
Vue.use(Router)

/**
 * Router instance containing all the routes for the application.
 */
const router = new Router({ mode: 'history', routes })

/**
 * Router custom checks
 */
router.beforeEach((to, from, next) => {
  const restricted = store.getters.isRestricted
  if (restricted === true && to.name !== 'AccessRestricted') {
    return next({ name: 'AccessRestricted' })
  } else if (restricted === true && to.name === 'AccessRestricted') {
    return next()
  }

  const user = store.state.auth.profile
  const isUserEmpty = isEmpty(user)

  if (to.path === '/login' && !isUserEmpty) {
    return next({ path: '/dashboards/default' })
  }
  // check if user is logged in and route requires to be authenticated
  if (isUserEmpty) {
    store.dispatch(Type.FETCH_PROFILE)
      .then(() => store.dispatch('fetchInstances'))
      .then(response => {
        const guardResult = setup(to, next, response)
        if (guardResult) {
          return guardResult
        }
        if (isObject(store.getters.profile) && isEmpty(store.getters.profile) === false && to.path === '/login') {
          return next({ path: '/dashboards/default' })
        }
        continueGuard(to, from, next)
      })
      .catch(({ response }) => {
        // let user access login page after failed profile
        // if setup is not completed then always redirect to setup
        if (to.path.includes('setup') && Boolean(response.headers?.['x-needs-setup'])) return next()
        if (from.path !== '/logout') {
          const guardResult = setup(to, next, response)
          if (guardResult) return guardResult
        }
        store.commit(Type.UPDATE_PROFILE, null)
        // let user access login, restricted or reset password except setup
        if (!to.meta.requiredAuth && !to.path.includes('setup')) return next()
        const redirect = to.path && ['/', '/logout'].indexOf(to.path) === -1 ? '?redirect=' + to.path : ''
        return next({ path: '/login' + redirect })
      })
  } else {
    if (to.path === '/logout') return next()
    const guardResult = setup(to, next)
    if (guardResult) {
      return guardResult
    }
    continueGuard(to, from, next)
  }
})

function continueGuard (to, from, next) {
  // Checking if the user has access to current route
  const user = store.getters.profile
  const routeRoles = to.meta.roles ? to.meta.roles : []
  if (routeRoles.length > 0) {
    const rolesIntersect = user.roles.filter(value => routeRoles.includes(value))
    if (rolesIntersect.length === 0) {
      return next({ path: '/' })
    }
  }

  if (to.name === 'Graph') {
    document.title = graphs.find(i => i.slug === to.params.name)?.name ?? 'Global Management Interface'
  } else if (to.name === 'Report') {
    document.title = ReportsDefinitions.find(report => report.slug === to.params.name)?.title ?? 'Global Management Interface'
  } else {
    document.title = to.meta.crumbName || 'Global Management Interface'
  }

  // prevent user from accessing setup pages if setup was already done
  const isLoggedIn = !isEmpty(user)
  const accessingSetup = ['/setup', '/setup/instances'].indexOf(to.path) !== -1
  const setupAlreadyDone = store.getters.instancesList.length > 0
  if (isLoggedIn && accessingSetup && setupAlreadyDone) {
    return next('/dashboards/default')
  }

  if (to.path === '/') return next('/dashboards/default')
  if (to.path === '/dashboards') return next('/dashboards/default')
  if (to.path === '/settings') return next('/settings/instances')
  if (to.path === '/global-commit') return next('/global-commit/summary')
  if (to.path === '/instances-events') return next('/instances-events/critical-issues')

  return next()
}

router.onError(error => {
  if (error.name === 'ChunkLoadError' && error.type === 'missing') {
    // assume server frontend version is updated. And we a running old version of frontend.
    store.commit(Type.UPDATE_OUTDATED_VERSION, true)
  }
  console.error('Router error', error)
})

export default router
