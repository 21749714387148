<template>
  <ui-dialog
    v-bind="$props"
    @close="$emit('close')"
  >
    <template #title>
      <slot name="title" />
    </template>
    <template #body>
      <slot name="body" />
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </ui-dialog>
</template>

<script>
import UiDialog from 'uilib/src/Dialog'

export default {
  name: 'DialogWrapper',
  components: { UiDialog },
  props: {
    test: {
      type: String,
      default: ''
    },
    modal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    width: {
      type: String,
      required: true
    },
    disableCloseBtn: {
      type: Boolean,
      default: false
    }
  },
  beforeMount () {
    this.$store.commit('SCROLL_LOCK_UPDATE', true)
  },
  beforeDestroy () {
    this.$store.commit('SCROLL_LOCK_UPDATE', false)
  }
}
</script>

<style scoped>

</style>
