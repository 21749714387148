<template>
  <div>
    <slide-x-left-transition mode="out-in">
      <router-view :key="$route.fullPath" />
    </slide-x-left-transition>
  </div>
</template>
<script>
import SlideXLeftTransition from 'vue2-transitions/src/Slide/SlideXLeftTransition'

export default {
  name: 'GraphsBase',
  components: {
    SlideXLeftTransition
  }
}
</script>
