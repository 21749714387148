/* eslint-disable */
export default class Task {
  static PENDING = 'pending'
  static FINISHED = 'finished'
  static FAILED = 'failed'

  constructor ({ id, favorite, destination, instanceId, isHost, options, tool, updatedAt, status, result, error }) {
    Object.defineProperties(this, {
      id: {
        value: id,
        enumerable: true,
        writable: true
      },
      updatedAt: {
        value: updatedAt,
        enumerable: true,
        writable: true
      },
      status: {
        configurable: true,
        value: status,
        enumerable: true,
        writable: true
      },
      tool: {
        value: tool,
        enumerable: true,
        writable: true
      },
      instanceId: {
        configurable: true,
        value: instanceId,
        enumerable: true,
        writable: true
      },
      destination: {
        configurable: true,
        value: destination,
        enumerable: true,
        writable: true
      },
      options: {
        value: options,
        enumerable: true,
        writable: true
      },
      favorite: {
        configurable: true,
        value: favorite,
        enumerable: true,
        writable: true
      },
      result: {
        configurable: true,
        value: result,
        enumerable: true,
        writable: true
      },
      error: {
        configurable: true,
        value: error,
        enumerable: true,
        writable: true
      },
      isHost: {
        configurable: true,
        value: isHost,
        enumerable: true,
        writable: true
      }
    })

    if (tool === 'prefixProbing') extendClass(this)
  }
}

function extendClass (context) {
  Object.defineProperties(context, {
    canBeRerouted: {
      configurable: true,
      value: false,
      enumerable: true,
      writable: true
    }
  })
}
