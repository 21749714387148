import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: irp_journal
 * url: /irp/journal
 * @param hosts { Array }
 * @param start { String } From date
 * @param end { String } To date
 * @param level { String } Type
 * @param state { String } State
 * @param component { String } Module
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/journal'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.start !== undefined) {
    queryParameters.start = parameters.start
  } if (parameters.end !== undefined) {
    queryParameters.end = parameters.end
  } if (parameters.level !== undefined) {
    queryParameters.level = parameters.level
  } if (parameters.state !== undefined) {
    queryParameters.state = parameters.state
  } if (parameters.component !== undefined) {
    queryParameters.component = parameters.component
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
