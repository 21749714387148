import { get } from '@/utils/lodash'

/**
 * Converts object for example:
 * instanceId: {
 *   lastN: {
 *     data: [['123', '2019-04-17T12:10:38Z', 'Irpapid']],
 *     filters: {},
 *     headers: ['id', 'ts', 'component'],
 *     unread: 20
 *   }
 *   results: [{ code: 'ok' }]
 * }
 * to:
 * {
 *   instanceId: 'instanceId',
 *   id: '123',
 *   ts: '2019-04-17T12:10:38Z',
 *   component: 'Irpapid',
 *   unread: 20
 * }
 * @param {Object} events
 * @param {String} instanceId
 * @returns {Array<Object>}
 */
function joinHeaderWithData(events, instanceId) {
  const header = get(events, 'lastN.headers', [])
  const unread = get(events, 'lastN.unread', 0)

  return get(events, 'lastN.data', [])
    .map(row => {
      let object = {instanceId, unread}
      row.forEach((element, index) => {
        object[header[index]] = element
      })
      return object
    })
}

/**
 *
 * @param {Object} events
 * @returns {Array<Object>}
 */
function parseEvents (events) {
  return Object
    .keys(events)
    .map(key => joinHeaderWithData(events[key], key))
    .flat()
}

export default parseEvents
