/** @module helpers */
import { get, result, set } from '@/utils/lodash'
import parseISO from 'date-fns/parseISO'
import isSameDay from 'date-fns/isSameDay'
import format from 'date-fns/format'
import subHours from 'date-fns/subHours'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import isValid from 'date-fns/isValid'
import isDate from 'date-fns/isDate'
import addMinutes from 'date-fns/addMinutes'
import colors from '@/constants/colors'
import { getDay, lastDayOfMonth } from 'date-fns'

const Format = {
  date: 'dd-MM-yy',
  time: 'hh:mm:ss a'
}

export const updateFormat = (date, time) => {
  Format.date = date
  Format.time = time
}

export const pipe = fns => x => fns.reduce((v, f) => f(v), x)

/**
 * Returns true if the given value is an instance of Date. The function works for dates transferred across iframes
 *
 * @see isDate
 *
 * @param date {*} The value to check
 * @returns {boolean} true if the given value is a date
 */
export const isDateInstance = date => {
  return isDate(date)
}

export function isTimeTransferDay (timestamp) {
  const date = new Date(timestamp)
  const month = date.getMonth()
  const dayOfWeek = date.getDay()

  if ((month === 2 || month === 9) && dayOfWeek === 0) {
    const nextWeek = new Date(date)
    nextWeek.setDate(date.getDate() + 7)

    if (nextWeek.getMonth() !== month) {
      return true
    }
  }

  return false
}

export function convertISOToCustomFormat (isoDateString) {
  const date = new Date(isoDateString)

  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')

  return `${day} ${month} ${formattedHours}:${formattedMinutes}`
}

/**
 * Convert to unix timestamp, string's date times from APID responses.
 *
 * @note
 * Keep in mind that if date is provided as string then it will be better to provide it as
 * toISOString() because toLocaleString() is not providing timezone offset. And unix timestamp,
 * can not be determined properly.
 *
 * @param date {(Date|string|number)} Date or String to convert as unix timestamp
 * @return {(string|boolean)}
 */
export const toUnix = date => {
  if (!date) return false
  const dt = isDate(date) ? date : new Date(date)
  if (isValid(dt)) {
    // drop any milliseconds, we don't care about them
    dt.setMilliseconds(0)
    return dt.valueOf()
  }
  return false
}

/**
 * Convert standard toISOString() date representation to APID acceptable. Because standard,
 * js date toISOString will return "2019-07-28T14:20:46.605Z", and APID accept only in this
 * format "2019-07-28T14:20:46Z", without milliseconds.
 *
 * @param date {Date} Date to convert, and format to standard string ISO8601
 * @return {string}
 */
export const toISO8601 = (date = null) => {
  if (date === null) {
    return result(new Date(), 'toISOString', '').replace(/\.\d{3}Z$/, 'Z')
  }
  return result(new Date(date), 'toISOString', '').replace(/\.\d{3}Z$/, 'Z')
}

/**
 * Return last date subtracted to N hours, by default return 24 hours back string formatted,
 * into acceptable format for APID.
 * @param n {number} How much hours to subtract from current date
 * @see toISO8601
 * @return {string}
 */
export const lastNHours = (n = 24) => {
  const amount = n !== undefined && n !== null ? Number(n) : Number(24)
  if (!Number.isInteger(amount)) return toISO8601(new Date())
  return toISO8601(subHours(new Date(), amount))
}

/**
 * Format provided string, to date format selected by the user.
 *
 * @param value {(string|Date)} string like "2019-5-14T12:32:22Z".
 * @see userDateTimeFormat
 * @return {(string|null)}
 */
export const userDateFormat = value => {
  if (!value) return null
  if (typeof value === 'string' && !isValid(parseISO(value))) return 'Invalid Date'
  if (typeof value === 'object' && !isValid(value)) return 'Invalid Date'

  return format(typeof value === 'string' ? parseISO(value) : value, Format.date)
}

/**
 * Format provided string, to time format selected by the user.
 *
 * @param {(string|Date)} value
 * @example
 *          value = "2019-5-14T12:32:22Z"
 * @see userDateTimeFormat, userDateFormat
 *
 * @return {(string|null)}
 */
export const userTimeFormat = value => {
  if (!value) return null
  if (typeof value === 'string' && !isValid(parseISO(value))) return 'Invalid Date'
  if (typeof value === 'object' && !isValid(value)) return 'Invalid Date'

  return format(value, Format.time)
}

export const numberFormat = value => isNaN(value) ? value : new Intl.NumberFormat().format(value)

/**
 * Format provided string, to full date and time format, selected by the user.
 * @param {(string|Date)} value
 * @see userDateFormat
 * @return {(string|null)}
 */
export const userDateTimeFormat = value => {
  if (!value) return 'Invalid Date'
  const dt = isDate(value) ? value : new Date(value)
  if (!isValid(dt)) return 'Invalid Date'

  return format(dt, `${Format.date} ${Format.time}`)
}

export function formatTimestamp (timestamp) {
  const date = new Date(timestamp)
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  const hours = date.getHours()
  const minutes = date.getMinutes()

  return `${day} ${month} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

/**
 *
 * @param {Date|String} value The value to be converted to a ISO Date
 * @returns {Date}
 */
export const convertToISODate = value => addMinutes(new Date(value), new Date().getTimezoneOffset())

export const toISOStringNoConversion = value => {
  const year = value.getFullYear()
  const month = value.getMonth() < 9 ? `0${value.getMonth() + 1}` : value.getMonth() + 1
  const day = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate()
  const hour = value.getHours() < 10 ? `0${value.getHours()}` : value.getHours()
  const minutes = value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes()
  const seconds = value.getSeconds() < 10 ? `0${value.getSeconds()}` : value.getSeconds()
  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}Z`
}

/**
 * Format date to provided format
 * @param value {(Date|string|number)} Date
 * @param toFormat {string} Format
 * @return {string}
 */
export const dateFormat = (value, toFormat) => {
  if (!value) return 'Invalid Date'
  if (!toFormat) return 'Required format'
  const dt = isDate(value) ? value : new Date(value)
  if (isValid(dt)) {
    return format(dt, toFormat)
  }
  return 'Invalid Date'
}

/**
 * Check if provided date is today
 *
 * @param date {(Date|string)} Date to check if it is today
 * @return {Boolean}
 */
export const isToday = date => {
  if (!date) return false
  const dt = isDate(date) ? date : new Date(date)
  if (isValid(dt)) {
    return isSameDay(dt, new Date())
  }
  return false
}

/**
 * Subtract from date, given amount of unit. If date is provided, then subtract from
 * provided date. But if date is not provided, then subtract from Date.now()
 * Subtract support Hours, Days, Months.
 *
 * @note  If unknown granularity will be provided, then console error, and current date will be returned
 *
 * @param amount {number} Amount to subtract, e.g. subtractDate(2, 'day')
 * @param granularity {string} Granularity, possible values [hours, days, months]
 * @param date {Date} Date to subtract, if need to subtract not today date
 *
 * @return {Date}
 */
export const subtractDate = (amount, granularity, date = null) => {
  date = date || new Date()

  if (!isDate(date)) return new Date()

  switch (granularity) {
    case 'hours':
      return subHours(date, Number(amount))
    case 'days':
      return subDays(date, Number(amount))
    case 'months':
      return subMonths(date, Number(amount))
    default:
      console.error(`Wrong granularity parameter provided, "${granularity}". Use hours, days or months`)
      return new Date()
  }
}

/**
 * Function for converting volume to higher unit taking into consideration 1024 convention
 * returning max 3 digits including .
 * values such as 900 measure unit will be converted to 0.9 next measure unit
 */
export function convertToThreeDigits (value, sizes, unit, unitShort) {
  let i = 0
  // 1Gb === 1073741824
  const biggerGb = value > 1073741824
  // Such convention is on api side for bytes and bits
  const conversionFactor = 1024

  while (value >= conversionFactor && i < sizes.length - 1) {
    value /= conversionFactor
    i++
  }

  if (i < sizes.length - 1 && value >= 900) {
    value /= conversionFactor
    i++
    return 0.9 + ' ' + sizes[i] + (sizes[i] !== unit ? unitShort : '')
  }

  if (biggerGb) {
    return value.toFixed(2) + ' ' + sizes[i] + (sizes[i] !== unit ? unitShort : '')
  } else if (value >= 10) {
    return value.toFixed(0) + ' ' + sizes[i] + (sizes[i] !== unit ? unitShort : '')
  } else if (value >= 1) {
    return value.toFixed(1) + ' ' + sizes[i] + (sizes[i] !== unit ? unitShort : '')
  } else if (value === 0 || value < 1) {
    return 0 + ' ' + sizes[i] + (sizes[i] !== unit ? unitShort : '')
  } else if (value === null) {
    return '-'
  } else {
    return value.toFixed(2) + ' ' + sizes[i] + (sizes[i] !== unit ? unitShort : '')
  }
}

/**
 * Function for converting volume to higher unit
 * @param volume {number} data volume
 * @param isBit {Boolean} specify whether it is a bit or byte value
 */
export const converter = (volume, isBit = false) => {
  let validVolume = volume
  if (!volume) validVolume = 0
  const unitShort = isBit ? 'b' : 'B'
  const unit = isBit ? 'bits' : 'Bytes'
  const value = Number(validVolume)
  const sizes = [unit, 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
  return numberFormat(convertToThreeDigits(value, sizes, unit, unitShort))
}

/**
 * Bandwidth formatter
 */
export const bandwidthFormatter = function (seconds = 60, perSecond = true, fractionDigits) {
  return function (value) {
    const current = (this?.value ?? value) || 0
    const ifPerSecond = perSecond ? current * 8 / seconds : current
    let speed = seconds && (current !== 0) ? ifPerSecond : 0
    const units = [' bps', ' Kbps', ' Mbps', ' Gbps', ' Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps']

    if (typeof fractionDigits !== 'undefined') {
      let unitNum = 0
      while (speed > 1024) {
        speed = speed / 1024
        unitNum++
      }

      return Math.max(speed, 0.1).toFixed(fractionDigits) + units[unitNum]
    }

    return numberFormat(convertToThreeDigits(speed, units, '', ''))
  }
}

/**
 * Convert very long numbers into more small representation, e.g. 10000 to 10k
 * @param value {number} Value to be converted
 * @param min   {number} Do nothing with number, if it smaller then min parameter
 *
 * @return {number}
 */
export const longNumber = (value, min) => {
  if (value < min && min) {
    return value
  }
  const digits = 2
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (value >= si[i].value) {
      break
    }
  }
  return (value / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

/**
 * Using this function, developer can mark function parameter as required.
 *
 * @param name {String} Name of parameter, for error message generation.
 *
 * @example
 *  function (index = isRequired('index')) {}
 *
 * @throws Error
 */
export const isRequired = (name = 'Parameter') => {
  throw new Error(`${name} is required`)
}

/**
 * Checks if given index is inside in the array range.
 *
 * @param array {Array<*>}
 * @param index {Number}
 */
export const inArrayRange = (array = isRequired('array'), index = isRequired('index')) => {
  return index >= 0 && (array.length - 1) >= index
}

/**
 * Execute function with try catch wrapper
 *
 * @param fn   {Function} Function method
 * @param args {*}        Function arguments
 *
 * @return {boolean|*}
 */
export const execute = (fn, args) => {
  try {
    return fn(...args)
  } catch (e) {
    console.error('failed to execute ', e)
    return true
  }
}

/**
 * Check two objects a equal, very simple implementation.
 *
 * @param left  {Object}
 * @param right {Object}
 * @return {Boolean}
 */
export const isObjectEqual = (left = isRequired('left'), right = isRequired('right')) => {
  return Object
    .keys(right)
    .every(key => left[key] === right[key])
}

/**
 * Return current browser timezone offset formatted, e.g. +2:00
 * @return {`${string}${string}:${string}`}
 */
export const getBrowserTimeZone = () => {
  const timezoneOffset = new Date().getTimezoneOffset()
  const offset = Math.abs(timezoneOffset)
  const offsetOperator = timezoneOffset < 0 ? '+' : '-'
  const offsetHours = (offset / 60).toString().padStart(2, '0')
  const offsetMinutes = (offset % 60).toString().padStart(2, '0')

  return `${offsetOperator}${offsetHours}:${offsetMinutes}`
}

const providersColors = JSON.parse(localStorage.getItem('providersColors') || '{}')

/**
 * Find color already used for the given provider, or assing new one color for provider.
 *
 * @param host     {string} Instance id from db
 * @param provider {string} Provider id from db
 *
 * @return {string}
 */
export const getProviderColor = (host, provider) => {
  let color = get(providersColors, `${host}.${provider}`, false)

  if (!color) {
    const usedColors = Object.values(providersColors[host] || {})
    color = colors.find((color, i) => i > 2 && !usedColors.includes(color) && color)
    localStorage.setItem('providersColors', JSON.stringify(set(providersColors, `${host}.${provider}`, color)))
  }

  return color
}

/**
 * Remove empty values from object
 * @param obj {Object} Object to be cleaned
 * @returns {Object} Cleaned object
 */
export function removeEmptyValues (obj) {
  const result = {}
  for (const key in obj) {
    if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
      result[key] = obj[key]
    }
  }
  return result
}

export function upperCaseFirstChar (string) {
  return typeof string === 'string' ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export default {
  isDateInstance,
  toUnix,
  userDateFormat,
  userTimeFormat,
  numberFormat,
  userDateTimeFormat,
  subtractDate,
  isValid,
  isToday,
  toISO8601,
  lastNHours,
  converter,
  longNumber,
  updateFormat,
  isRequired,
  inArrayRange,
  execute,
  isObjectEqual,
  getProviderColor,
  upperCaseFirstChar
}
