export default {
  section: {
    name: 'section',
    in: 'path',
    required: true,
    schema: { type: 'string', minLength: 1, maxLength: 255 },
    camelCaseName: 'section'
  },
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  }
}
