<template>
  <div class="graphs">
    <h2
      class="dark:text-white"
      v-text="title"
    />
    <slide-y-down-transition
      v-if="list.length"
      group
      tag="ul"
      :duration="{ leave: 200 , enter: 200 }"
      data-test="graphsList"
      mode="in-out"
    >
      <li
        v-for="item in list"
        :key="item.slug"
        class="bg-white dark:bg-darkgray-800 dark:text-white"
        @click="navigate(item)"
      >
        <ui-icon
          v-if="item.icon"
          class="icon"
          :color="iconColor"
          :icon="item.icon"
          test="icon-graph"
        />
        <span
          class="title"
          v-text="item.name"
        />
        <ui-icon
          class="right-sign end"
          icon="c_right"
          test="icon-right=sign"
        />
      </li>
    </slide-y-down-transition>
    <span
      v-else
      class="no-graph-item text-darkgray-300 dark:text-darkgray-200"
      v-text="`No ${title.toLowerCase()}`"
    />
  </div>
</template>

<script>
import SlideYDownTransition from 'vue2-transitions/src/Slide/SlideYDownTransition'
import UiIcon from 'uilib/src/Icon'

export default {
  name: 'GraphsListSections',
  components: { SlideYDownTransition, UiIcon },
  props: {
    title: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      required: true
    },
    path: {
      type: String,
      default: 'graphs'
    }
  },
  methods: {
    navigate (item) {
      if (this.navigate.called) return

      this.navigate.called = true
      this.$router.push({ path: `/${this.path}/${item.slug}` })
    }
  }
}
</script>

<style lang="scss" scoped>
  .graphs {
    margin-bottom: 30px;

    h2 {
      margin: 0 0 20px 20px;
      font-size: 24px;
      text-transform: capitalize;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: grid;
        grid-template:
          [row-start] "graphIcon title map more" [row-end]
          / 22px 1fr auto auto;
        grid-column-gap: 15px;
        align-items: center;
        height: 60px;
        padding: 0 20px;
        margin-bottom: 10px;
        cursor: pointer;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
        will-change: transform;

        .icon {
          grid-area: graphIcon;
          font-size: 22px;
        }

        .title {
          grid-area: title;
          max-width: 100%;
          overflow-x: hidden;
          font-size: 16px;
          font-weight: 700;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .map {
          grid-area: map;
          padding-right: 30px;
          font-size: 13px;
          font-weight: 700;
          color: #ff8400;
          text-decoration: underline;
        }

        .right-sign {
          grid-area: more;
          transition: transform 0.3s ease-in-out;
          transform: translateX(0);
        }

        &:hover {
          .right-sign {
            transition: transform 0.3s ease-in-out;
            transform: translateX(5px);
          }
        }
      }
    }
  }

  .no-graph-item {
    padding-left: 20px;
  }
</style>
