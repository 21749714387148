import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: getInboundPerformanceActiveFeedHistory
 * url: /irp/inboundperformance/rules/status/active/history
 * @param hosts { Array }
 * @param start { String } Date and time start in the form (YYYY-MM-DD HH:MM:SS)
 * @param end { String } Date and time start in the form (YYYY-MM-DD HH:MM:SS)
 * @param page {  }
 * @param pageSize {  } Number of records
 * @param text { String }
 * @param idrule { String }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/inboundperformance/rules/status/active/history'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.start !== undefined) {
    queryParameters.start = parameters.start
  } if (parameters.end !== undefined) {
    queryParameters.end = parameters.end
  } if (parameters.page !== undefined) {
    queryParameters.page = parameters.page
  } else if (parameters.page === undefined) {
    queryParameters.page = 1
  }
  if (parameters.pageSize !== undefined) {
    queryParameters.pageSize = parameters.pageSize
  } else if (parameters.pageSize === undefined) {
    queryParameters.pageSize = 20
  }
  if (parameters.text !== undefined) {
    queryParameters.text = parameters.text
  } if (parameters.idrule !== undefined) {
    queryParameters.idrule = parameters.idrule
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
