<template>
  <notifications
    :max="maxNotifications"
    :speed="350"
    :duration="duration"
    :position="'bottom right'"
    :height="90"
    :width="420"
    :animation-name="'v-fade-right'"
    data-test="notificationsTest"
  >
    <template #body="props">
      <div
        class="custom-template dark:bg-darkgray-700"
        :style="{ 'z-index': zIndex }"
      >
        <ui-icon
          :class="{ 'error': props.item.type === 'error', 'warn': props.item.type === 'warn' }"
          :icon="detectIcon(props.item.type)"
          class="custom-template-icon"
        />
        <div class="custom-template-content text-darkgray-400 dark:text-white">
          <div
            v-if="detectTitle(props.item.title, props.item.type)"
            :class="{ 'error': props.item.type === 'error', 'warn': props.item.type === 'warn' }"
            class="custom-template-title text-darkgray-400 dark:text-white"
          >
            {{ detectTitle(props.item.title, props.item.type) }}
            <instance-shortname
              v-if="props.item.data && props.item.data.id"
              :instance="getInstanceById(props.item.data.id)"
              type="color"
            />
          </div>
          <div class="custom-template-text text-darkgray-400 dark:text-white">
            <span v-text="props.item.text" />
          </div>
          <div
            v-if="get(props.item, 'data.buttons', false)"
            class="custom-template-actions"
          >
            <div
              v-for="button in props.item.data.buttons"
              :key="button.name"
            >
              <ui-button
                v-if="!button.route || button.route.path !== $route.path"
                v-bind="button.props"
                @click.native="buttonClicked(button, props)"
              >
                {{ button.name }}
              </ui-button>
            </div>
          </div>
        </div>
        <div
          class="custom-template-close"
          @click="removeFromQueue(props.item.data); props.close()"
        >
          <ui-icon icon="plus_2" />
        </div>
      </div>
    </template>
  </notifications>
</template>
<script>
import { get } from '@/utils/lodash'
import UiIcon from 'uilib/src/Icon'
import UiButton from 'uilib/src/Button'
import { mapGetters, mapState } from 'vuex'
import InstanceShortname from '@/components/common/InstanceShortname'
import types from '@/store/modules/notifications/types'

const timings = {
  error: 12 * 1000,
  warn: 6 * 1000
}

export default {
  name: 'AppNotifications',
  components: {
    InstanceShortname,
    UiIcon,
    UiButton
  },
  data () {
    return {
      queue: [],
      duration: 2000,
      maxNotifications: 3,
      debugCounter: 1,
      timeoutId: undefined,
      zIndex: '5000'
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.all,
      tabIsActive: state => state.notifications.tabIsActive
    }),
    ...mapGetters(['getInstanceById', 'profile']),
    lastNotification () {
      return this.notifications.length > 0 ? this.notifications[this.notifications.length - 1] : null
    }
  },
  watch: {
    lastNotification (to) {
      if (to) {
        this.addToQueue(to)
      }
    }
  },
  mounted () {
    window.addEventListener('focus', () => { this.$store.commit('TAB_IS_ACTIVE', true) })
    window.addEventListener('blur', () => { this.$store.commit('TAB_IS_ACTIVE', false) })
    this.requestNotificationPermission()
  },
  beforeDestroy () { clearTimeout(this.timeoutId) },
  methods: {
    get () { return get(...arguments) },
    buttonClicked (button, notification) {
      if (button.route) {
        this.$router.push(button.route)
      } else if (button.fn) {
        button.fn()
      }
      notification.close()
    },
    granted () {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          localStorage.setItem('BrowserNotifications', 'true')
          this.browserNotify({ title: 'Example', text: 'Hello, from Global Management Interface.' })
        }
      }).catch(err => {
        console.error(err)
      })
    },
    blocked () {
      localStorage.setItem('BrowserNotifications', 'true')
      this.showNotification({
        type: 'warn',
        title: 'If you change your mind you can turn on browser notifications in Profile.'
      })
    },
    requestNotificationPermission () {
      if (Notification.permission === 'default') {
        this.timeoutId = setTimeout(() => {
          if (this.profile && !JSON.parse(localStorage.getItem('BrowserNotifications'))) {
            this.showNotification({
              type: 'warn',
              title: 'Show browser notifications?',
              duration: 600000,
              buttons: [{ name: 'Block', fn: this.blocked }, { name: 'Allow', fn: this.granted, props: { color: 'accent' } }]
            })
          }
        }, 120000)
      }
    },
    addToQueue (notification) {
      if (!this.isUnique(notification)) return
      // We allow maximum of 3 notifications
      if (this.queue.length < this.maxNotifications) {
        this.queue.push(notification)
        this.showNotification(notification)
        // Make sure we remove this items from queue once the notification's duration time has expired
        setTimeout(() => {
          this.removeFromQueue(notification)
        }, timings[notification.type] || this.duration)
      } else {
        // Try again in half a second, to see if the queue has empty slots
        setTimeout(() => {
          this.addToQueue(notification)
        }, 500)
      }
    },
    removeFromQueue (notification) {
      this.queue.splice(this.queue.indexOf(notification), 1)
      this.$store.commit('DELETE_NOTIFICATION', notification)
    },
    showNotification (notification) {
      if (notification.zIndex) this.zIndex = notification.zIndex
      if (this.tabIsActive) {
        this.$notify({
          ...notification,
          data: notification,
          text: notification.text,
          id: [notification.id, notification.text].join('-'),
          duration: notification.duration || timings[notification.type] || this.duration
        })
      } else if (notification.event !== 'xhr') {
        this.browserNotify(notification)
      }

      if (notification.buttons) {
        notification.buttons.forEach(button => {
          if (button.route && this.$route.path !== button.route.path) {
            this.$store.commit(types.ADD_ROUTE_NEEDING_ATTENTION, button.route)
          }
        })
      }
    },
    browserNotify (notification) {
      const notificationTitle = notification.title ? notification.title : ''
      const notificationIcon = require('../../assets/images/favicon.png')
      const notificationBody = notification.text
      const browserNotification = new Notification(notificationTitle, { icon: notificationIcon, body: notificationBody })
      browserNotification.onclick = event => {
        event.preventDefault()
        window.focus()
        if (notification.buttons && notification.buttons[0]) {
          const primaryButton = notification.buttons[0]
          if (primaryButton.route) {
            this.$router.push(primaryButton.route)
          } else if (primaryButton.fn) {
            primaryButton.fn()
          }
        }
      }
    },
    detectTitle (title, notificationType) {
      if (title) return title
      if (notificationType === 'error') return 'Error'
      if (notificationType === 'warn') return 'Warning'
    },
    detectIcon (notificationType) {
      if (notificationType === 'error') return 'warning'
      if (notificationType === 'warn') return 'st_warn'
      return 'st_ok'
    },
    isUnique (notification) {
      return !this.queue.find(i => {
        return i.id === notification.id && i.text === notification.text
      })
    }
  }
}
</script>
<style lang="scss">
  div.vue-notification-group {
    z-index: 9999;

    @media print { display: none; }
  }

  .custom-template {
    display: flex;
    padding: 14px 16px;
    flex: {
      direction: row;
      wrap: nowrap;
    }

    margin: 0 15px 15px 15px;
    background: #fff;
    box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.2);

    .custom-template-icon {
      flex: 0 1;
      font-size: 18px;
      color: rgb(71, 177, 73);

      &.error {
        color: rgb(227, 88, 88);
      }

      &.warn {
        color: rgb(101, 101, 102);
      }
    }

    .custom-template-close {
      flex: 0 1 18px;
      font-size: 18px;
      line-height: 0;
      color: rgba(178, 178, 178, 0.7);
      cursor: pointer;
      transition: color 0.1s ease-in;

      > * {
        margin: 0;
        transform: rotate(45deg);
      }

      &:hover {
        color: rgb(178, 178, 178);
        transition: color 0.1s ease-in;
      }
    }

    .custom-template-content {
      flex: 1 0;
      max-width: calc(100% - 20px - 18px);
      padding: 0 10px;
      text-align: left;

      .custom-template-title {
        font: {
          size: 15px;
          weight: bold;
        }

        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        line-height: 1.2;
        color: rgb(71, 177, 73);

        &.error {
          color: rgb(227, 88, 88);
        }

        &.warn {
          color: rgb(101, 101, 102);
        }
      }

      .custom-template-text {
        margin: 0;
        font: {
          size: 13px;
          weight: normal;
        }

        line-height: 1.38;
        letter-spacing: -0.1px;
      }

      .custom-template-actions {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 15px;
        justify-content: end;
        margin-top: 15px;
      }
    }
  }

  .v-fade-right-enter-active,
  .v-fade-right-leave-active,
  .v-fade-right-move {
    transition: all 0.5s;
  }

  .v-fade-right-enter,
  .v-fade-right-leave-to {
    opacity: 0;
    transform: translateX(500px) scale(0.2);
  }
</style>
