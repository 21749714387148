import { theme } from '@/components/graphs/types/Theme'
import { merge } from 'highcharts'

export default () => merge(true, theme(), {
  chart: {
    type: 'bar',
    height: '350px'
  },
  boost: { seriesThreshold: 5000 },
  xAxis: {
    visible: false
  },
  yAxis: {
    visible: false
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    symbolRadius: 0,
    padding: 3,
    itemMarginTop: 5,
    itemMarginBottom: 5
  },
  colors: ['#f85560', '#a8a8a8', '#ff8726', '#6381c2', '#dcdcdc'],
  plotOptions: {
    bar: {
      minPointLength: 10
    },
    series: {
      animation: 300,
      stacking: 'percent',
      borderWidth: 2,
      pointPadding: 0.3,
      dataLabels: {
        enabled: true,
        align: 'left',
        allowOverlap: false,
        style: {
          'lineHeight': '16.4px'
        },
        formatter: function () {
          if (this.percentage?.toFixed(2) > 0.01) {
            return `
             <span style="font-weight: 700; font-size: 11px">${this.y} (${this.percentage.toFixed(2)}%)</span><br/>
             <span style="font-weight: 400; font-size: 10px">${this.series.name}</span>
            `.trim()
          }
        }
      },
      events: {
        legendItemClick: () => false
      }
    }
  },
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    }]
  },
  series: []
})
