import store from '@/store'

const isParameterValid = (schema, config, instanceId) => {
  const { parameter, scope, value } = schema
  const configValue = scope === 'Global' ? store.getters.getParameterValue(instanceId, parameter) : config[parameter]

  return !(typeof value === 'object' ? value.indexOf(configValue) === -1 : value !== configValue)
}

const isParameter = object => {
  return object.parameter !== undefined && object.value !== undefined
}

const isSimpleLeaf = array => {
  return Array.isArray(array) && array.every(isParameter)
}

const checkShowWhenTree = (schema, config, instanceId) => {
  if (isParameter(schema)) {
    return isParameterValid(schema, config, instanceId)
  } else if (isSimpleLeaf(schema)) {
    return schema.every(sc => isParameterValid(sc, config, instanceId))
  } else if (Array.isArray(schema.anyOf)) {
    return schema.anyOf.some(sc => checkShowWhenTree(sc, config, instanceId))
  } else if (Array.isArray(schema.allOf)) {
    return schema.allOf.every(sc => checkShowWhenTree(sc, config, instanceId))
  } else if (Array.isArray(schema)) {
    return schema.every(sc => checkShowWhenTree(sc, config, instanceId))
  }

  // because in any other situations we don't know what to do, better show it, then no.
  return true
}

/**
 * Check if json schema like annotation is valid against given configuration.
 *
 * @param schema     {Object} Json schema like custom annotation
 * @param config     {Object} Json object, instance full configuration
 * @param instanceId {String} Instance id
 *
 * @return {*|boolean}
 */
export function isParameterDisplayed (schema, config, instanceId) {
  return checkShowWhenTree(schema, config, instanceId)
}
