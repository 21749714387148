import { get } from '@/utils/lodash'
import store from '@/store'
import type from '@/store/modules/notifications/types'

export default function setupGuard (to, next, response) {
  const needsSetup = Boolean(get(response, 'headers.x-needs-setup', false))
  const setupStep = get(response, 'headers.x-needs-setup-step', null)
  if (needsSetup && setupStep === 'user') {
    next({ path: '/setup' })
    return true
  }
  if (needsSetup && setupStep === 'instances') {
    next({ path: '/setup/instances' })
    return true
  }
  if (to?.path !== '/settings/instances' &&
    to?.path.indexOf('/setup') === -1 &&
    !store.getters.profile?.roles?.includes('user') &&
    (store.state.instances.all.length > 0 && store.state.instances.all.every(i => i.status !== 'active' && i.needSetup === undefined))) {
    next({ path: '/settings/instances' })
    store.commit(type.ADD_NOTIFICATION, {
      type: 'error',
      title: 'Please configure at least one working instance.'
    })
    return true
  }
  return false
}
