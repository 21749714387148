export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  page: {
    name: 'page',
    in: 'query',
    schema: { type: 'number', default: 1 },
    camelCaseName: 'page'
  },
  pageSize: {
    name: 'pageSize',
    in: 'query',
    description: 'Number of records',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Results per page' } },
    schema: { type: 'number', default: 20, allOf: [{ type: 'number', minimum: 1, errorMessage: 'must be > 0' }, { type: 'number', maximum: 300, errorMessage: 'must be < 300' }] },
    camelCaseName: 'pageSize'
  },
  id: {
    required: false,
    schema: { type: 'number' },
    in: 'query',
    name: 'id',
    camelCaseName: 'id'
  },
  providerId: {
    name: 'provider_id',
    in: 'query',
    schema: { type: 'number' },
    camelCaseName: 'providerId'
  },
  text: {
    name: 'text',
    in: 'query',
    schema: { type: 'string' },
    camelCaseName: 'text'
  },
  status: {
    name: 'status',
    in: 'query',
    schema: { enum: ['activate_automatically', 'activate_manually', 'activate_moderated', 'inactivate', 'off', 'proposed'] },
    camelCaseName: 'status'
  }
}
