import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import FloatingVue, { VClosePopper } from 'floating-vue'
import 'floating-vue/dist/style.css'
import notifications from 'vue-notification'
import vueContentPlaceholders from 'vue-content-placeholders'
import vueHighcharts from 'vue-highcharts'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting'
import treemap from 'highcharts/modules/treemap'
import heatmap from 'highcharts/modules/heatmap'
import boost from 'highcharts/modules/boost'
import PortalVue from 'portal-vue'
import hub from '@/utils/hub'
import Scroll from 'vuescroll/dist/vuescroll-native'
import VueObserveVisibility from 'vue-observe-visibility'
import VCalendar from 'v-calendar'
import ajv from '@/utils/ajvExtentions'
import Type from './store/modules/auth/types'
import '@/filters'
import '@/services/socket'
import '@/services/requestInterceptor'
import notificationMixin from '@/mixins/notifications'

import '@/assets/tailwind.scss'
import 'vuescroll/dist/vuescroll.css'

import ConfirmationDialog from '@/components/common/ConfirmationDialog'
import loadMap from 'highcharts/modules/map.js'
import markerClusters from 'highcharts/modules/marker-clusters'

Vue.component('confirmation-dialog', ConfirmationDialog)
Vue.use(notifications)
Vue.use(vueContentPlaceholders)
Vue.use(PortalVue)
Vue.use(vueHighcharts, { Highcharts })
Vue.use(Scroll)
Vue.use(VueObserveVisibility)
Vue.use(VCalendar)

Vue.directive('close-popper', VClosePopper)
Vue.use(FloatingVue, {
  themes: {
    customTooltip: {
      $extend: 'tooltip'
    }
  }
})

boost(Highcharts)
treemap(Highcharts)
heatmap(Highcharts)
exporting(Highcharts)
loadMap(Highcharts)
markerClusters(Highcharts)
// this line fixes problems with referensing of the Highcharts object in the highcharts library itself
// for example when highcharts wants to draw an empty pie
window.Highcharts = Highcharts

// Vue.directive('vue-tooltip', vTooltip)

notificationMixin(Vue)

// Events must be split by ":"
// for example, icon:clicked
Vue.prototype.$hub = hub
Vue.prototype.$ajv = ajv

/* eslint-disable no-new */
new Vue({
  el: '#app',
  components: { App },
  router,
  store,
  render: h => h(App),
  created () {
    // setup mutation subscribers
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      // catch profile removal
      if (mutation.type === Type.REMOVE_PROFILE) {
        const noop = () => {} // does nothing
        const reloadPage = () => this.$router.go(0) // reloads current page
        const onRouteChanged = state.auth.needsReload ? reloadPage : noop // route change handler

        // go to login with optional page reload
        this.$router.push('/login').then(onRouteChanged)
      }
    })
  },
  beforeDestroy () {
    // clean up
    this.unsubscribe()
  }
})
