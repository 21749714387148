import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: getImprovementsByProvider
 * url: /irp/reports/improvementsByProvider
 * @param hosts { Array }
 * @param ipVer {  } IPv4/IPv6
 * @param providerFrom {  } From provider
 * @param providerTo {  } To provider
 * @param start { String } Date and time start in the form (YYYY-MM-DD HH:MM:SS)
 * @param end { String } Date and time start in the form (YYYY-MM-DD HH:MM:SS)
 * @param type { Array } Improvement type
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/reports/improvementsByProvider'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.ipVer !== undefined) {
    queryParameters.ipVer = parameters.ipVer
  } if (parameters.providerFrom !== undefined) {
    queryParameters.providerFrom = parameters.providerFrom
  } if (parameters.providerTo !== undefined) {
    queryParameters.providerTo = parameters.providerTo
  } if (parameters.start !== undefined) {
    queryParameters.start = parameters.start
  } if (parameters.end !== undefined) {
    queryParameters.end = parameters.end
  } if (parameters.type !== undefined) {
    queryParameters.type = parameters.type
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
