import { get } from '@/utils/lodash'

const getSeriesAvg = array => {
  const sum = array.reduce((acc, item) => {
    const value = get(item, [1], 0)
    return acc + value
  }, 0)

  const avg = sum / array.length
  return avg.toFixed()
}

const getSeriesMax = array => {
  const mbpsArray = array.map(item => get(item, [1], 0))
  return Math.max(...mbpsArray).toFixed()
}

const getSeriesMin = array => {
  const mbpsArray = array.map(item => get(item, [1]))
  return Math.min(...mbpsArray).toFixed()
}

export const getStats = series => {
  const avg = getSeriesAvg(series.data)
  const max = getSeriesMax(series.data)
  const min = getSeriesMin(series.data)
  return {
    avg,
    max,
    min
  }
}
