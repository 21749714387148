import { getDomain } from './request'
export const instances_delete_RAW_URL = () => '/instances/{id}'

export const instances_delete_URL = (parameters = {}) => {
  let path = instances_delete_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
