import GraphsBase from '@/views/Graphs/GraphsBase'
import GraphsTrialList from '@/views/GraphsTrial/GraphsTrialList'
import GraphsTrialPage from '@/views/GraphsTrial/GraphsTrialPage'

export default function () {
  return {
    path: '/graphsTrial',
    component: GraphsBase,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-commit',
      menu: true,
      trial: true,
      crumbMenu: true,
      crumbName: 'Network Performance Overview'
    },
    children: [
      {
        path: '',
        name: 'GraphTrialList',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Network performance overview' },
        component: GraphsTrialList
      },
      {
        path: ':name',
        name: 'GraphsTrialPage',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Chart' },
        component: GraphsTrialPage
      }
    ]
  }
}
