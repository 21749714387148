import Vue from 'vue'

const debug = !process.env.NODE_ENV.match(/(production|test)\b/gi)
const hub = new Vue()

if (debug) {
  const originalEmit = hub.$emit.bind(hub)
  hub.$emit = function () {
    // eslint-disable-next-line
    console.info('%c bus event', 'color: #9E9E9E; font-weight: bold', arguments[0], '@', (new Date().toISOString()))
    // eslint-disable-next-line
    originalEmit(...arguments)
  }
}

export default hub
