import { theme } from '@/components/graphs/types/Theme'
import { merge } from 'highcharts'

export default () => merge(true, theme(), {
  chart: {
    type: 'column'
  },
  boost: { seriesThreshold: 5000 },
  title: {
    text: ''
  },
  xAxis: {
    categories: []
  },
  yAxis: {
    min: 0,
    minRange: 0.05,
    title: {
      text: ''
    }
  },
  tooltip: {
    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
    shared: true
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      minPointLength: 5,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return Math.round(this.y) + '%'
        }
      }
    }
  },
  series: []
})
