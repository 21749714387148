import Ajv from 'ajv'
import Vue from 'vue'

/**
 * Cache mechanism for configuration section.
 *
 * @type {{makeKey(*, *): string, set(*=, *=, *=): void, get(*=, *=): *, __schemas: Map<any, any>, has(*=, *=): *}}
 */
const schema = {
  __schemas: new Map(),
  currentAjv: null,
  current: null,
  set (schema, instanceId, version) {
    this.__schemas.set(this.makeKey(instanceId, version), Object.freeze(schema))
  },
  get (instanceId = false, version = false) {
    return !instanceId || !version
      ? this.__schemas.get(this.current)
      : this.__schemas.get(this.makeKey(instanceId, version))
  },
  get schema () {
    const schema = this.get()
    return Vue.observable(schema)
  },
  makeKey (instanceId, version) {
    return `${instanceId}-${version}`
  },
  has (instanceId, version) {
    return this.__schemas.has(this.makeKey(instanceId, version))
  },
  setCurrent (instanceId, version) {
    delete this.currentAjv
    this.current = this.makeKey(instanceId, version)
    this.currentAjv = new Ajv({ allErrors: true, schemaId: 'auto', removeAdditional: true, strict: false })
  }
}

export default schema
