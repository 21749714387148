import { getDomain } from './request'
export const subscriptions_delete_RAW_URL = () => '/subscriptions/{id}'

export const subscriptions_delete_URL = (parameters = {}) => {
  let path = subscriptions_delete_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
