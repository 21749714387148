<template>
  <div class="graph-placeholder">
    <div class="graph-base">
      <div
        class="pie"
        :style="{ 'width': `${radius * 2}px`, 'height': `${radius * 2}px` }"
      >
        <svg
          v-if="show"
          :width="radius * 4"
          :height="radius * 4"
        >
          <template v-for="(animationData, ix) in animationsData">
            <polygon
              :key="ix"
              :style="{ 'stroke': strokeColor, 'stroke-width': strokeWidth, 'fill': animationData.fillColor }"
            >
              <animate
                fill="freeze"
                attributeName="points"
                :dur="`${animationData.start.dur}s`"
                :from="animationData.start.from"
                :to="animationData.start.to"
                :begin="`${animationData.start.begin}s`"
              />
              <animate
                fill="freeze"
                attributeName="points"
                :dur="`${animationData.end.dur}s`"
                :from="animationData.end.from"
                :to="animationData.end.to"
                :begin="`${animationData.end.begin}s`"
                @endEvent="resetAnimation(ix === animationsData.length - 1)"
              />
            </polygon>
          </template>
        </svg>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>

const calculateDuration = percentage => percentage * 0.0156
const getDelays = arrayOfDurations => arrayOfDurations.reduce((acc, curr, ix, thisArr) => {
  if (ix === 0) return [0]
  if (ix === 1) return [...acc, thisArr[ix - 1]]

  return [...acc, acc[ix - 1] + thisArr[ix - 1]]
}, [])
const range = (start = 0, end = 5) => {
  const ran = []
  for (let i = start; i < end; i += 1) ran.push(i)
  return ran
}

export default {
  name: 'Pie',
  data () {
    const coordinates = [
      { start: { from: '350,0 350,350 350,0 700,0', to: '350,0 350,350 700,560 700,0' }, end: { from: '350,0 350,350 700,560 700,0', to: '700,560 350,350 700,560 700,0' }, percentage: 31 },
      { start: { from: '350,350 700,560 700,700 700,560', to: '350,350 700,560 700,700 0,700' }, end: { from: '350,350 700,560 700,700 0,700', to: '350,350 0,700 700,700 0,700' }, percentage: 29 },
      { start: { from: '350,350 0,700 0,700', to: '350,350 0,700 0,200' }, end: { from: '350,350 0,700 0,200', to: '350,350 0,200 0,200' }, percentage: 25 },
      { start: { from: '350,350 0,200 0,200', to: '350,350 0,200 160,0' }, end: { from: '350,350 0,200 160,0', to: '350,350 160,0 160,0' }, percentage: 15 },
      { start: { from: '350,350 160,0 160,0', to: '350,350 160,0 350,0' }, end: { from: '350,350 160,0 350,0', to: '350,350 350,0 350,0' }, percentage: 10 }
    ]
    let durations = coordinates.map(coords => calculateDuration(coords.percentage))
    durations = [...durations, ...durations]
    const delays = getDelays(durations)
    const bgColor = this.$store.getters.getCurrentTheme === 'dark' ? '255, 255, 255' : '64, 64, 64'
    return {
      animationsData: range(0, coordinates.length).map(ix => {
        return {
          start: { ...coordinates[ix].start, dur: durations[ix], begin: delays[ix] },
          end: { ...coordinates[ix].end, dur: durations[ix + coordinates.length], begin: delays[ix + coordinates.length] },
          fillColor: `rgba(${bgColor}, ${0.6 - (ix % coordinates.length) / 10})`
        }
      }),
      show: true,
      radius: 175,
      strokeColor: this.$store.getters.getCurrentTheme === 'dark' ? '#313131' : '#ffffff',
      strokeWidth: '2px'
    }
  },
  methods: {
    resetAnimation (isLast) {
      if (isLast) {
        this.show = false
        this.$nextTick()
          .then(() => { this.show = true })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.graph-base {
  position: relative;
  display: grid;
  place-items: center center;
  width: 100%;
  height: 400px;

  .pie {
    position: relative;
    box-sizing: content-box;
    overflow: hidden;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0.01);
    animation: appear 1000ms ease forwards;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.01);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

</style>
