import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: irp_post
 * url: /irp/{section}
 * @param section { String }
 * @param hosts { Array }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/{section}'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.section === undefined) {
    return Promise.reject(new Error('Missing required String parameter: section'))
  }
  path = path.replace('{section}', `${parameters.section}`)
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts

  body = parameters.body || parameters.requestBody

  if (body === undefined) {
    return Promise.reject(new Error('Missing required parameter: body or requestBody'))
  }

  (config.headers !== undefined) ? (config.headers['Content-Type'] = 'application/json') : (config.headers = { 'Content-Type': 'application/json' })

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('post', domain + path, body, queryParameters, form, config)
}
