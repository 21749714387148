import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: policies_create
 * url: /irp/policies/{type}
 * @param type { String }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/policies/{type}'
  let body
  let queryParameters = {}
  let form = {}

  if (parameters.type === undefined) {
    return Promise.reject(new Error('Missing required String parameter: type'))
  }
  path = path.replace('{type}', `${parameters.type}`)

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('post', domain + path, body, queryParameters, form, config)
}
