export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  ipVer: {
    name: 'ipVer',
    in: 'query',
    description: 'IPv4/IPv6',
    'x-ui': { dictionary: 'ipVersions', component: 'ui-filter-select', props: { placeholder: 'Any IP version' } },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'ipVer'
  },
  providerFrom: {
    name: 'providerFrom',
    in: 'query',
    description: 'From provider',
    'x-ui': { dictionary: 'providers', component: 'ui-filter-select', props: { placeholder: 'From any provider' } },
    schema: { type: 'number' },
    camelCaseName: 'providerFrom'
  },
  providerTo: {
    name: 'providerTo',
    in: 'query',
    description: 'To provider',
    'x-ui': { dictionary: 'providers', component: 'ui-filter-select', props: { placeholder: 'From any provider' } },
    schema: { type: 'number' },
    camelCaseName: 'providerTo'
  },
  start: {
    name: 'start',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'start'
  },
  end: {
    name: 'end',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'end'
  },
  type: {
    name: 'type',
    in: 'query',
    description: 'Improvement type',
    schema: { type: 'array', minLength: 1, items: { anyOf: [{ type: 'string' }, { type: 'number' }] } },
    camelCaseName: 'type'
  }
}
