import actions from './actions'
import mutations from './mutations'

const state = {
  list: []
}

const getters = {
  busyReport: state => state.list.find(r => r.loading) // report that is currently exported/downloaded
}

export default {
  state,
  getters,
  mutations,
  actions
}
