import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: tools_remove_task
 * url: /tools/tasks/{id}
 * @param id { String }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/tools/tasks/{id}'
  let body
  let queryParameters = {}
  let form = {}

  if (parameters.id === undefined) {
    return Promise.reject(new Error('Missing required String parameter: id'))
  }
  path = path.replace('{id}', `${parameters.id}`)

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('delete', domain + path, body, queryParameters, form, config)
}
