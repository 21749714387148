import colors from '@/constants/colors'
import { theme } from '@/components/graphs/types/Theme'
import { merge } from 'highcharts'
import { bandwidthFormatter } from '@/utils/helpers'

function sync (vm, event, type) {
  vm.$refs.highcharts.forEach(({ chart }) => {
    if (chart === this.series.chart) return
    chart.series.forEach(series => {
      const rule = (this.series.name.includes('average') && series.name.includes('Total')) || (this.series.name.includes('Total') && series.name.includes('average'))
      if (this.series.name === series.name || rule) {
        series.setState('hover')
        series.data.forEach(point => {
          if (point.x === this.x) {
            if (type === 'over') {
              point.setState('hover')
              chart.tooltip.refresh(point)
              chart.xAxis[0].drawCrosshair(event, point)
            } else {
              point.setState()
              chart.tooltip.hide()
              chart.xAxis[0].hideCrosshair()
            }
          }
        })
      } else if (type === 'over') series.setState('inactive')
      else series.setState()
    })
  })
}

export default (vm, name, format) => merge(true, theme(), {
  chart: {
    spacingBottom: 20,
    zoomType: 'x',
    resetZoomButton: {
      theme: { style: { display: 'none' } }
    },
    events: {}
  },
  title: {
    text: name,
    align: 'left'
  },
  credits: {
    enabled: false
  },
  legend: {
    align: 'left'
  },
  colors,
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    }]
  },
  xAxis: {
    crosshair: true,
    type: 'datetime',
    labels: {
      format: '{value:%e %b %k:%M}'
    },
    events: {
      afterSetExtremes: function (event) {
        vm.zoomButton = true

        vm.$refs.highcharts.forEach(({ chart }) => {
          if (event.min === chart.xAxis[0].getExtremes().userMin && event.max === chart.xAxis[0].getExtremes().userMax) return
          chart.xAxis[0].setExtremes(event.min, event.max)
        })
      }
    }
  },
  time: {
    useUTC: false
  },
  yAxis: {
    title: {
      text: null
    },
    labels: {
      formatter: function () {
        return this.value.toString()
      }
    }
  },
  tooltip: {
    positioner: function () {
      return {
        // right aligned
        x: this.chart.chartWidth - this.label.width,
        y: 10 // align to title
      }
    },
    borderWidth: 0,
    backgroundColor: 'none',
    pointFormatter: function () {
      if (vm._props.callback === 'providersBandwidthUsage') {
        return this.series.name + ': ' + bandwidthFormatter(60, false)(this.y * 10e5)
      }
      return this.series.name + ': ' + Math.round(((this.y) + Number.EPSILON) * 100) / 100 + ' ' + format
    },
    headerFormat: '',
    shadow: false,
    style: {
      fontSize: '18px'
    }
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 3
      },
      point: {
        events: {
          mouseOver: function (event) {
            sync.call(this, vm, event, 'over')
          },
          mouseOut: function (event) {
            sync.call(this, vm, event, 'out')
          }
        }
      },
      events: {
        legendItemClick: function (event) {
          event.preventDefault()
          if (this.visible) {
            vm.$refs.highcharts.forEach(chart => {
              return chart.chart.series[this.index].hide()
            })
          } else {
            vm.$refs.highcharts.forEach(chart => {
              return chart.chart.series[this.index].show()
            })
          }
        }
      }
    }
  },
  exporting: {
    enabled: false
  },
  series: [{ data: [] }]
})
