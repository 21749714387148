import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: senders_list
 * url: /senders
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/senders'
  let body
  let queryParameters = {}
  let form = {}

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
