import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: getCountryStatistics
 * url: /irp/reports/countryStatistics
 * @param page {  }
 * @param order { String }
 * @param hosts { Array }
 * @param month { String } Month for current year
 * @param year { String }
 * @param sortBy { String }
 * @param pageSize {  } Number of records
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/reports/countryStatistics'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.page !== undefined) {
    queryParameters.page = parameters.page
  } else if (parameters.page === undefined) {
    queryParameters.page = 1
  }
  if (parameters.order !== undefined) {
    queryParameters.order = parameters.order
  } else if (parameters.order === undefined) {
    queryParameters.order = 'DESC'
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.month !== undefined) {
    queryParameters.month = parameters.month
  } if (parameters.year !== undefined) {
    queryParameters.year = parameters.year
  } if (parameters.sortBy !== undefined) {
    queryParameters.sortBy = parameters.sortBy
  } else if (parameters.sortBy === undefined) {
    queryParameters.sortBy = 'cntrerouted'
  }
  if (parameters.pageSize !== undefined) {
    queryParameters.pageSize = parameters.pageSize
  } else if (parameters.pageSize === undefined) {
    queryParameters.pageSize = 20
  }

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
