<template>
  <div :class="classes">
    <ui-icon
      :icon="!down ? 'chevron' : 'c_up'"
      class="text-darkgray-300 dark:text-white"
      :style="{ 'font-size': size }"
    />
  </div>
</template>

<script>
import UiIcon from 'uilib/src/Icon'

export default {
  name: 'DropdownToggle',
  components: {
    UiIcon
  },
  props: {
    down: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'var(--secondary-color, #a2a2a2)'
    },
    size: {
      type: String,
      default: '10px'
    }
  },
  computed: {
    style () {
      return { 'background-color': this.color }
    },
    classes () {
      const { down } = this
      return { arrow: true, down }
    }
  }
}
</script>

<style scoped lang="scss">
  .arrow {
    display: inline-flex;
    align-items: center;

    .noction-ui-icon {
      color: #a2a2a2;
    }
  }
</style>
