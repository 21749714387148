import colors from '@/constants/colors'
import { merge } from 'highcharts'
import { theme } from '@/components/graphs/types/Theme'

export default () => merge(true, theme(), {
  chart: {
    type: 'area',
    zoomType: 'x'
  },
  boost: { seriesThreshold: 5000 },
  title: {
    text: ''
  },
  tooltip: {
    xDateFormat: '%e %b %k:%M'
  },
  credits: {
    enabled: false
  },
  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%e %b %k:%M}'
    }
  },
  time: {
    useUTC: false
  },
  yAxis: [{ // Primary yAxis
    title: {
      text: ''
    }
  }, { // Secondary yAxis
    title: {
      text: ''
    },
    opposite: true
  }],
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'bottom'
  },
  colors: colors,
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    }]
  },
  series: [{
  }],
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 3
      }
    }
  }
})
