const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboards/Dashboard')
const DashboardBase = () => import(/* webpackChunkName: "DashboardBase" */ '@/views/Dashboards/DashboardBase')
const DashboardList = () => import(/* webpackChunkName: "DashboardList" */ '@/views/Dashboards/DashboardList')

export default function () {
  return {
    path: '/dashboards',
    name: 'dashboards',
    component: DashboardBase,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-dash',
      menu: true
    },
    children: [
      {
        path: 'all',
        name: 'dashboards_all',
        component: DashboardList,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'All Dashboards'
        }
      },
      {
        path: ':id',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Dashboard'
        }
      }
    ]
  }
}
