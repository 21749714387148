import axios from 'axios'
import { getAxiosInstance } from '@/api/methods/request'
import router from 'vue-router'
import store from '@/store'
import types from '@/store/modules/auth/types'
import instancesTypes from '@/store/modules/instances/types'
import { hash } from '@/version'

const onFullFill = response => {
  if (response.headers['x-gmi-version'] !== hash) {
    store.commit(types.UPDATE_OUTDATED_VERSION, true)
  } else if (response.headers['x-ssl-expire'] !== undefined) {
    store.commit(types.SSL_CERTIFICATE_EXPIRE, response.headers['x-ssl-expire'])
  }
  return response
}

const onRejected = error => {
  const status = error.response ? error.response.status : null
  if ([401].includes(status)) {
    store.commit(types.NEEDS_RE_AUTH, true)
  } else if ([423].includes(status)) {
    store.commit(types.ACCESS_RESTRICTED, true)
    router.push({ name: 'AccessRestricted', replace: true })
  } else if ([403].includes(status) && error.response.data.message === 'Unauthorized instance access') {
    store.dispatch('fetchInstances')
    store.commit(instancesTypes.SELECTED_INSTANCE, undefined)
  }
  return Promise.reject(error)
}

getAxiosInstance().interceptors.response.use(onFullFill, onRejected)
axios.interceptors.response.use(onFullFill, onRejected)

// email token is used to authorize subscription's requests
const checkEmailToken = config => {
  const emailToken = new URLSearchParams(window.location.search).get('emailToken')
  if (emailToken && emailToken.length) {
    config.headers['x-email-token'] = emailToken
  }
  return config
}

getAxiosInstance().interceptors.request.use(checkEmailToken)
axios.interceptors.request.use(checkEmailToken)
