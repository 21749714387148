import { getDomain } from './request'
export const getAsStatistics_RAW_URL = () => '/irp/reports/asStatistics'

export const getAsStatistics_URL = (parameters = {}) => {
  let path = getAsStatistics_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.sortBy !== undefined) {
    queryParameters.sortBy = parameters.sortBy
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
