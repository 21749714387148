import { getDomain } from './request'
export const instances_check_duplicate_RAW_URL = () => '/instances/check-duplicate'

export const instances_check_duplicate_URL = (parameters = {}) => {
  let path = instances_check_duplicate_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.host !== undefined) {
    queryParameters.host = parameters.host
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
