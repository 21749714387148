import { getDomain } from './request'
export const deleteSubscription_RAW_URL = () => '/irp/notifications/subscriptions/{id}'

export const deleteSubscription_URL = (parameters = {}) => {
  let path = deleteSubscription_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
