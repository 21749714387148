const cameToSnake = str => {
  return str.split('').map((letter, i) => {
    if (i === 0) {
      return letter.toLowerCase()
    } else if (letter === letter.toUpperCase()) {
      return `_${letter.toLowerCase()}`
    }
    return letter
  }).join('')
}

const pluralize = string => string + 's'

export function createMutationName (entityType, type) {
  entityType = cameToSnake(entityType)
  return (type === 'set' ? pluralize(entityType) : `${entityType}_${type}`).toUpperCase()
}

const createMutation = {
  set (entityType) {
    return (state, entities) => {
      state[pluralize(entityType)] = entities
    }
  },

  add (entityType) {
    return (state, entity) => {
      state[pluralize(entityType)].unshift(entity)
    }
  },

  update (entityType) {
    return (state, entity) => {
      const idx = state[pluralize(entityType)].findIndex(e => e.id === entity.id)
      state[pluralize(entityType)].splice(idx, 1, entity)
    }
  },

  patch (entityType) {
    return (state, { id, payload }) => {
      const idx = state[pluralize(entityType)].findIndex(e => e.id === id)
      Object.assign(state[pluralize(entityType)][idx], payload)
    }
  },

  remove (entityType) {
    return (state, id) => {
      const idx = state[pluralize(entityType)].findIndex(u => u.id === id)
      if (idx !== -1) state[pluralize(entityType)].splice(idx, 1)
    }
  }
}

export function entityMutations (entityType, types = ['set', 'add', 'remove', 'update', 'patch']) {
  const mutations = {}
  types.forEach(type => {
    mutations[createMutationName(entityType, type)] = createMutation[type](entityType)
  })
  return mutations
}
