import colors from '@/constants/colors'

export default () => ({
  chart: {
    type: 'area',
    zoomType: 'x',
    marginTop: 20,
    marginBottom: 5,
    backgroundColor: 'rgba(246, 142, 86, 0.05)'
  },
  boost: { seriesThreshold: 1000 },
  title: {
    text: 'Click and drag in the plot area to zoom in or zoom out',
    style: {
      fontSize: '12px',
      color: 'var(--secondary-color)'
    }
  },
  tooltip: {
    formatter () {
      return false
    }
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    series: {
      enableMouseTracking: false,
      dataLabels: { formatter () { return '' } }
    },
    area: {
      marker: {
        enabled: false
      }
    }
  },
  xAxis: {
    type: 'datetime',
    ticks: {
      enabled: false
    },
    tickWidth: 0,
    labels: {
      enabled: false
    }
  },
  time: {
    useUTC: false
  },
  yAxis: {
    gridLineWidth: 0,
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    min: 0.6,
    showFirstLabel: false
  },
  legend: {
    enabled: false
  },
  colors: colors,
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    }]
  },
  series: {
    area: {
      borderWidth: 1
    }
  }
})
