const InboundPerformancePage = () => import(/* webpackChunkName: "InboundPerformancePage" */ '@/views/Inbound/Page/Performance/InboundPerformancePage')
const InboundPerformanceFeed = () => import(/* webpackChunkName: "InboundPerformanceFeed" */ '@/views/Inbound/Page/Performance/InboundPerformanceFeed')
const InboundPerformanceLog = () => import(/* webpackChunkName: "InboundPerformanceFeed" */ '@/views/Inbound/Page/Performance/InboundPerformanceLog')
const InboundPerformanceRules = () => import(/* webpackChunkName: "InboundPerformanceRules" */ '@/views/Inbound/Page/Performance/InboundPerformanceRules')

export default function () {
  return {
    path: '/inbound/performance',
    component: InboundPerformancePage,
    meta: {
      menu: false,
      requiredAuth: true,
      crumbMenu: false,
      layout: 'LoggedLayout'
    },
    children: [
      {
        path: '/inbound/performance/feed',
        name: 'InboundPerformanceFeed',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Chart' },
        component: InboundPerformanceFeed
      },
      {
        path: '/inbound/performance/log',
        name: 'InboundPerformanceLog',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Chart' },
        component: InboundPerformanceLog
      },
      {
        path: '/inbound/performance/rules',
        name: 'InboundPerformanceRules',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Chart' },
        component: InboundPerformanceRules
      }
    ]
  }
}
