// All colors should be upper-case hex

export default [
  '#FF8400',
  '#7DA7D9',
  '#BD8CBF',
  '#A3D39C',
  '#6DCFF6',
  '#F4D959',
  '#6BD1CB',
  '#B7B7B7',
  '#F58F8A',
  '#C7B299',
  '#7BB0A6',
  '#92F22A',
  '#64DDBB',
  '#523D1F',
  '#D33257',
  '#F04903',
  '#FF7416',
  '#71BA51',
  '#3D8EB9',
  '#FEC606',
  '#E3000E',
  '#3E4651',
  '#00B5B5',
  '#E7DF86',
  '#9E8E5A',
  '#1D2247',
  '#462446',
  '#16A086',
  '#BF4ACC',
  '#4A6B4E',
  '#02135C',
  '#804829'
]
