import inboundPerformance from './inboundPerformance'

export default function () {
  return {
    path: '/inbound',
    component: () => import(/* webpackChunkName: "InboundBase" */ '@/views/Inbound/InboundBase'),
    meta: {
      menu: true,
      icon: 'icon-inbound',
      requiredAuth: true,
      crumbName: 'Inbound',
      hotkey: 'SHIFT + I',
      layout: 'LoggedLayout'
    },
    children: [
      {
        path: '',
        name: 'InboundList',
        component: () => import(/* webpackChunkName: "InboundList" */ '@/views/Inbound/InboundList'),
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'All Reports' }
      },
      {
        path: '/inbound/improvements/:name',
        name: 'InboundReports',
        component: () => import(/* webpackChunkName: "Report" */ '@/views/Reports/Report.vue'),
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Report' }
      },
      {
        path: '/inbound/traffic/distribution',
        name: 'inboundTrafficDistribution',
        component: () => import(/* webpackChunkName: "InboundGraphsPage" */ '@/views/Inbound/Page/Graphs/InboundMixedReport'),
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Charts' }
      },
      {
        path: '/inbound/performance/report',
        name: 'InboundPerformanceReport',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Chart' },
        component: () => import(/* webpackChunkName: "InboundReportPage" */ '@/views/Inbound/Page/Graphs/InboundReportPage')
      },
      {
        path: '/inbound/historicalInbound/report',
        name: 'historicalInbound',
        component: () => import(/* webpackChunkName: "Report" */ '@/views/Reports/Report.vue'),
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Report' }
      },
      {
        path: '/inbound/historicalInbound/graph',
        name: 'historicalInboundGraph',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Chart' },
        component: () => import(/* webpackChunkName: "GraphsPage" */ '@/views/Graphs/GraphsPage')
      },
      inboundPerformance()
    ]
  }
}
