const ProfileBase = () => import(/* webpackChunkName: "Settings" */ '@/views/Profile/ProfileBase')
const Profile = () => import(/* webpackChunkName: "SettingsInstances" */ '@/views/Profile/Profile')
const Notifications = () => import(/* webpackChunkName: "Notifications" */ '@/views/Profile/Notifications')
const Subscriptions = () => import(/* webpackChunkName: "Subscriptions" */ '@/views/Profile/Subscriptions')

export default function () {
  return {
    path: '/profile',
    component: ProfileBase,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      crumbName: 'Profile',
      crumbMenu: true
    },
    children: [
      {
        path: '/',
        name: 'user',
        component: Profile,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          icon: 'user_2',
          crumbName: 'User Profile',
          crumbMenu: false
        }
      },
      {
        path: '/subscriptions',
        name: 'subscriptions',
        component: Subscriptions,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          icon: 'notific_2',
          crumbName: 'Subscriptions',
          crumbMenu: false
        }
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
          icon: 'notific_2',
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Notifications',
          crumbMenu: false
        }
      }
    ]
  }
}
