<template>
  <div class="min-h-[300px] print:none flex items-center justify-center select-none">
    <div class="w-full h-full flex flex-col items-center justify-center no-select text-darkgray-100">
      <template v-if="isEmpty(resultErr)">
        <ui-icon
          class="icon mb-4 text-error-500"
          icon="st_error"
          style="font-size: 64px"
        />
        <span
          v-for="(message, index) in messages"
          :key="index"
          class="mb-1 text-xl tracking-tight font-bold"
          v-text="message"
        />
      </template>
      <template v-else>
        <img
          class="mb-2"
          :src="resultErr.icon"
          alt="error"
        >
        <div
          class="flex flex-col items-center"
        >
          <span
            v-for="(message, i) in resultErr.message"
            :key="i"
            class="text-xl tracking-tight font-bold block"
          >
            {{ message }}
          </span>
          <div
            v-if="resultErr.description"
            class="text-base tracking-tight font-normal block flex gap-1"
          >
            <span v-text="resultErr.description" />
            <span
              v-if="resultErr.config && isAdminOrManager"
              class="text-primary-500 font-bold underline cursor-pointer"
              @click="toConfigInstance"
              v-text="'Instances'"
            />
          </div>
        </div>
      </template>
      <span
        v-if="messages.length === 0"
        class="text-error-500 text-xl font-bold"
        v-text="'Invalid parameters specified'"
      />
    </div>
  </div>
</template>
<script>
import UiIcon from 'uilib/src/Icon'
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'CommonErrors',
  components: {
    UiIcon
  },
  props: {
    messages: {
      type: Array,
      required: true
    },
    instances: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      resultErr: {}
    }
  },
  computed: {
    ...mapGetters(['getInstanceById', 'isAdminOrManager', 'currentInstance']),
    selectedInstance () {
      return this.instances[0] ? this.instances[0] : this.currentInstance
    }
  },
  created () {
    this.handleSystemErrors()
  },
  methods: {
    isEmpty,
    generateErrorPriority () {
      const errorGroups = {}
      for (const error of this.messages) {
        const [shortName, message] = error.split(': ')
        if (errorGroups.hasOwnProperty(message)) {
          errorGroups[message] += `, ${shortName}`
        } else {
          errorGroups[message] = shortName
        }
      }
      const priority = ['Connection issue', 'Authentication issue', 'No data found', 'badToken', 'tokenExpired']
      const orderedErrors = {}
      priority.forEach(key => {
        if (errorGroups.hasOwnProperty(key)) {
          orderedErrors[key] = errorGroups[key]
        }
      })

      return Object.entries(orderedErrors).map(([message, shortName]) => `${shortName}: ${message}`)
    },
    handleSystemErrors () {
      const iconPath = name => require(`@/assets/images/${name}.svg`)
      const textMessage = this.generateErrorPriority()
      if (!textMessage) return this.resultErr
      for (const item of textMessage) {
        const includes = word => item.toLowerCase().includes(word)

        if (includes('connection issue')) {
          this.resultErr.icon = iconPath('connectionError')
          this.resultErr.message = ['Connection issue']
          this.resultErr.description = 'Troubleshoot network connectivity'
          break
        } else if (includes('token') || includes('authentication issue')) {
          this.resultErr.icon = iconPath('authError')
          this.resultErr.message = includes('token') ? [`${this.getInstanceById(this.selectedInstance).shortName}: Authentication issue`] : textMessage
          this.resultErr.description = 'Authorize the instance under Management >'
          this.resultErr.config = true
          break
        } else if (includes('no data')) {
          this.resultErr.icon = iconPath('dataError')
          this.resultErr.message = textMessage
          this.resultErr.description = 'Check your filters'
          break
        }
      }
    },
    toConfigInstance () {
      this.$router.push('/settings/instances')
      localStorage.setItem('openedWizard', JSON.stringify(this.getInstanceById(this.selectedInstance)))
    }
  }
}
</script>
