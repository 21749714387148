import { getDomain } from './request'
export const access_rule_delete_RAW_URL = () => '/access/{id}'

export const access_rule_delete_URL = (parameters = {}) => {
  let path = access_rule_delete_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
