import { upperFirst } from '@/utils/lodash'
import colors from '@/constants/colors'
import {
  bandwidthFormatter,
  converter, convertISOToCustomFormat,
  formatTimestamp,
  isTimeTransferDay,
  toUnix,
  userDateTimeFormat,
  userTimeFormat
} from '@/utils/helpers'
import network from '@/mixins/network'
import SyncGraph from '@/components/graphs/types/SyncGraph'

const usedColors = new Set()
const storage = localStorage.getItem('providersColors')
const providersColors = storage === null ? {} : JSON.parse(storage)

function getProviderColor (host, provider) {
  if (!providersColors.hasOwnProperty(host)) providersColors[host] = {}
  if (!providersColors[host].hasOwnProperty(provider)) {
    providersColors[host][provider] = colors.find((color, i) => i > 2 && !usedColors.has(color) && usedColors.add(color))
    localStorage.setItem('providersColors', JSON.stringify(providersColors))
  }

  return providersColors[host][provider]
}

const SeriesDefinition = {
  ts: { legend: 'Timestamp' },
  improvedprefixes: { legend: 'The total number of improved prefixes', unit: 'prefixes' },
  improvedprefixesipv4: { legend: 'The number of improved prefixes by IPv4', unit: 'prefixes' },
  improvedprefixesipv6: { legend: 'The number of improved prefixes by IPv6', unit: 'prefixes' },
  performance: { legend: 'Performance', unit: 'prefixes' },
  cost: { legend: 'Cost', unit: 'prefixes' },
  commit: { legend: 'Commit Control', unit: 'prefixes' },
  total: { legend: 'The total number of improved prefixes', unit: 'prefixes' },
  outage: { legend: 'Outage Detection', unit: 'prefixes' },
  in: { legend: 'Inbound', unit: 'Mbps' },
  out: { legend: 'Outbound', unit: 'Mbps' },
  current95thin: { legend: 'Current 95th In', unit: 'Mbps' },
  current95thout: { legend: 'Current 95th Out', unit: 'Mbps' },
  improvedtraffic: { legend: 'Improved Traffic', unit: 'Mbps' },
  totaltraffic: { legend: 'Total Traffic', unit: 'Mbps' },
  probedprefixes: { legend: 'The total number of probed prefixes', unit: 'prefixes' },
  losscnt: { legend: 'The number of improved prefixes by Loss', unit: 'prefixes' },
  latencycnt: { legend: 'The number of improved prefixes by Latency', unit: 'prefixes' },
  cntloss: { legend: 'Probes Loss', unit: 'ms' },
  cntlatency: { legend: 'Probes Latency', unit: 'ms' },
  commitcontrolcnt: { legend: 'The number of improved prefixes by Commit Control', unit: 'prefixes' },
  othercnt: { legend: 'The number of improved prefixes by Other', unit: 'prefixes' },
  improved: { legend: 'Unique Improved', unit: 'prefixes' },
  probed: { legend: 'Unique Probed', unit: 'prefixes' },
  newimprovements: { legend: 'The number of new improved prefixes', unit: 'prefixes' },
  retryimprovements: { legend: 'The number of improved prefixes after retry probing', unit: 'prefixes' },
  improvementsin: { legend: 'Improvements In', unit: 'prefixes' },
  improvementsout: { legend: 'Improvements Out', unit: 'prefixes' },
  commit95thin: { legend: 'Commit 95th In', unit: 'Mbps' },
  commit95thout: { legend: 'Commit 95th Out', unit: 'Mbps' },
  loss: { legend: 'Packet Loss', unit: '%' },
  latency: { legend: 'Packet Latency', unit: 'ms' },
  prefixesimproved: { legend: 'Prefixes Improved', unit: '%' },
  trafficimproved: { legend: 'Traffic Improved', unit: '%' }
}

const biggestAreaOtherLine = series => {
  const arr = {}
  // calculate series sum
  series.map((i, k) => {
    if (i.data.every(e => e[1] === i.data[0][1])) return // Skip straight lines
    const sum = i.data.reduce((total, item) => total + item[1], 0) / i.data.length
    arr[k] = arr[k] ? arr[k] + sum : sum
  })
  // get biggest key
  const biggestKey = Object.keys(arr).find(i => arr[i] === Math.max(...Object.keys(arr).map(i => arr[i])))
  // adjust style
  series.map((s, i) => {
    i.toString() === biggestKey
      ? series[i] = {
        ...series[i],
        lineWidth: 2,
        zIndex: 0,
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, `${colors[i]}80`],
            [1, `${colors[i]}00`]
          ]
        }
      }
      : series[i] = {
        ...series[i],
        lineWidth: 2,
        zIndex: i + 1,
        fillOpacity: 0
      }
  })
}

function parseHeader (header = []) {
  const series = []

  for (let i = 1; i < header.length; i++) {
    const legend = SeriesDefinition[header[i].toLowerCase()] ? SeriesDefinition[header[i].toLowerCase()].legend : header[i]
    const unit = SeriesDefinition[header[i].toLowerCase()] ? SeriesDefinition[header[i].toLowerCase()].unit : null

    series[i - 1] = ({
      name: legend || header[i],
      data: [],
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      tooltip: {
        pointFormat: tooltipFormatter(legend, unit)
      }
    })
  }

  return series
}

function parseHeaderUnit (header = []) {
  const series = []

  for (let i = 1; i < header.length; i++) {
    const legend = SeriesDefinition[header[i].toLowerCase()] ? SeriesDefinition[header[i].toLowerCase()].legend : header[i]
    const unit = SeriesDefinition[header[i].toLowerCase()] ? SeriesDefinition[header[i].toLowerCase()].unit : null

    series[i - 1] = ({
      name: legendFormatter(legend, unit) || legendFormatter(header[i], unit),
      data: [],
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      tooltip: {
        pointFormat: tooltipFormatter(legend, unit)
      },
      tooltipData: {
        unit
      }
    })
  }

  return series
}

function tooltipFormatter (legend, unit) {
  return `<span style="color:{point.color}">\u25CF</span>${legend}: <b>{point.y} ${unit ? `${unit}` : ''}</b><br/>`
}

function legendFormatter (legend, unit) {
  if (unit === 'Mbps') return `${legend}`
  return `${legend} <span style="font-weight: normal">(${unit ? `${unit}` : ''})</span><br/>`
}

function dispenseData (data = [], series, type, avoidTimeStamps = false) {
  data.forEach((entry, index) => {
    for (let i = 0; i < series.length; i++) {
      if (series[i].name.includes('95th')) {
        if (index === 0 || index === data.length - 1) {
          series[i].data.push([toUnix(entry[0]), Math.round(entry[i + 1])])
          if (!series[i].dataLabels) series[i].dataLabels = { enabled: true }
        }
      } else {
        if (avoidTimeStamps) {
          series[i].data.push([convertISOToCustomFormat(entry[0]), entry[i + 1]])
        } else {
          series[i].data.push([toUnix(entry[0]), entry[i + 1]])
        }
      }
    }
  })

  // adjust style
  biggestAreaOtherLine(series)
}

export function defaultArea (allData, type) {
  const { header, data } = allData || {}
  const series = parseHeader(header)
  const transferDay = data.some(entry => isTimeTransferDay(toUnix(entry[0])))

  dispenseData(data, series, type, transferDay)

  if (allData.title === 'TotalBandwidthUsage') {
    return {
      series,
      tooltip: {
        formatter: function () {
          return `
            <span style="font-size: 10px">${formatTimestamp(this.x)}</span><br />
            <span style="color: ${this.color}">\u25CF</span>
            ${this.series.name}: <span style="font-weight: bold">${bandwidthFormatter(60, false)(this.point.y * 10e5)}</span>
            `
        }
      }
    }
  }

  let graph = { series }

  if (transferDay) {
    graph = {
      series,
      xAxis: {
        type: 'category',
        uniqueNames: false,
        tickInterval: 35
      }
    }
  }

  return graph
}

export function defaultBar (allData) {
  let series
  let stackOne
  let stackTwo = []
  let improved = {}

  const { data, header } = allData
  const labelIndex = header.indexOf('label')
  const totalOldValueIndex = header.indexOf('totalold')

  const newResponse = data.reduce((acc, el) => {
    acc[el[labelIndex]] = el[totalOldValueIndex]
    return acc
  }, {})

  newResponse['Not problem destination'] = newResponse['Sampled destinations']
  delete newResponse['Sampled destinations']

  stackOne = ['Problem destinations', 'Not problem destination'].map(el => {
    return {
      name: el,
      data: [newResponse[el]],
      stack: 0,
      dataLabels: { y: -45, verticalAlign: 'top' }
    }
  })

  series = [...stackOne]

  if (newResponse['50% or more'] || newResponse['20% or more']) {
    stackTwo = ['50% or more', '20% or more'].map(el => {
      return {
        name: el,
        data: [newResponse[el]],
        stack: 1,
        dataLabels: { y: 45, verticalAlign: 'bottom' }
      }
    })

    improved = {
      name: 'Less of 20%',
      data: [(newResponse['Problem destinations'] - newResponse['50% or more'] - newResponse['20% or more'])],
      stack: 1,
      dataLabels: { y: 45, verticalAlign: 'bottom' }
    }

    series = [...series, ...stackTwo, improved]
  }

  return { series }
}

export function stackedBar (allData) {
  const { data, header } = allData
  const categories = []
  const providers = header.slice(1)
  providers.pop()
  const series = []
  providers.forEach(p => series.push({ name: upperFirst(p), data: [] }))
  data.forEach(row => {
    categories.push(row[0])
    series.forEach((serie, index) => serie.data.push(row[index + 1]))
  })

  series.sort((a, b) => b.data[0] - a.data[0])

  return {
    series,
    xAxis: { categories },
    yAxis: {
      labels: { formatter: function () { return converter(this.value) } },
      title: ''
    },
    tooltip: {
      formatter: function () {
        return `${this.series.name}: ${converter(this.point.y)} <br> Total: ${converter(getTotalStackedBar(data, this.key))}`
      }
    }
  }
}

function getTotalStackedBar (data, key) {
  const foundItem = data.find(item => item[0] === key)
  return foundItem[foundItem.length - 1]
}

export function inboundTrafficDistribution (allData) {
  const chartOptions = stackedBar(allData)
  if (chartOptions.xAxis?.categories?.length > 18) chartOptions.chart = { height: 23 * chartOptions.xAxis.categories.length }
  return chartOptions
}

export const maxNegativeBarYAxisValue = (series = []) => Math.max(...series.reduce((acc, { data }) => [...acc, ...data.map(Math.abs)], []))

const negativeBarSeriesParser = (rawData = [], header = [], keys = [], definitions = SeriesDefinition) => {
  return keys.map((key, id) => {
    const index = header.indexOf(key)
    const name = definitions[key]?.legend ?? key
    const unit = definitions[key]?.unit ?? key
    // WAS SET 0.0001 VALUE JUST FOR SHOWING DATALABELS ON CHART IF BOTH DIRECTIONS HAVE ZERO VALUES
    const data = rawData.map(a => (id % 2 === 0 ? -parseFloat(a[index] || 0.0001) : parseFloat(a[index] || 0.0001)) || 0)
    return { id: key, name, data, unit }
  })
}

const providersEfficiencySeriesParser = (data = [], header = [], defenition) => {
  const keyMapping = {
    sumrttavg: 'avgRttMs',
    sumloss: 'avgLossPct',
    cntprobes: 'probesCnt',
    cntprobesfailed: 'failedProbesCnt'
  }

  return Object.entries(keyMapping).map(([key, headerKey]) => {
    const index = header.indexOf(headerKey)
    let transformData

    if (key === 'sumloss' || key === 'cntprobesfailed') {
      // WAS SET 0.0001 VALUE JUST FOR SHOWING DATALABELS ON CHART IF BOTH DIRECTIONS HAVE ZERO VALUES
      transformData = val => (val === 0 ? -0.0001 : -val)
    } else {
      transformData = val => (val === 0 ? 0.0001 : val)
    }

    return {
      data: data.map(row => transformData(row[index])),
      id: key,
      name: defenition[key].legend,
      unit: defenition[key].unit,
      calculationsHeader: defenition[key].calculationsHeader || '',
      graph: defenition[key].graph || ''
    }
  })
}

const negativeBarResult = (series = [], categories = []) => {
  // WAS SET 0.01 VALUE JUST FOR SHOWING DATALABELS ON CHART AND LITTLE DRAWN CHART IF BOTH DIRECTIONS HAVE ZERO VALUES
  const maxValue = maxNegativeBarYAxisValue(series) === 0.0001 ? 0.01 : (maxNegativeBarYAxisValue(series) || 0.001) * 1.3
  return {
    chart: { height: (categories.length + 1) * 70 },
    yAxis: {
      min: series[0].unit === '%' ? -130 : -maxValue,
      max: series[1].unit === '%' ? 130 : maxValue
    },
    xAxis: { categories },
    series
  }
}

export function providersPerformance (allData, type, keys) {
  const { data, header } = allData
  const descriptionIndex = header.indexOf('provider')
  const idProviderIndex = header.indexOf('idprovider')
  const dataProvidersIds = data.map(a => a[idProviderIndex])
  const categories = data.reverse().map(a => a[descriptionIndex].toUpperCase())
  const series = negativeBarSeriesParser(data, header, keys, SeriesDefinition)
  return { ...negativeBarResult(series, categories), dataProvidersIds }
}

export function providersEfficiency (allData) {
  const { data, header } = allData
  const descriptionIndex = header.indexOf('provider')
  const categories = data.sort().reverse().map(a => a[descriptionIndex].toUpperCase())
  const series = providersEfficiencySeriesParser(data, header, {
    // Total
    sumloss: { legend: 'Average Loss', unit: '%', graph: 'perf' },
    sumrttavg: { legend: 'Average RTT', unit: 'ms', graph: 'perf' },
    cntprobesfailed: { legend: 'Failed probes', unit: '', graph: 'probes' },
    cntprobes: { legend: 'Successful Probes', unit: '', graph: 'probes' }
  })

  const graph = {
    chart: { height: (categories.length + 1) * 70 },
    xAxis: { categories },
    series
  }
  graph.additionalFields = allData.additional_fields
  graph.customNames = ['Providers Performance', 'Probes per Provider']
  return graph
}

export function probesPerProvider (allData, type, keys) {
  const { data, header } = allData
  const descriptionIndex = header.indexOf('provider')
  const categories = data.map(a => a[descriptionIndex].toUpperCase())
  const series = negativeBarSeriesParser(data, header, keys, {
    cntloss: { legend: 'Probes Loss', unit: '' },
    cntlatency: { legend: 'Probes Latency', unit: '' }
  })

  return negativeBarResult(series, categories)
}

export function providersOverallPrefixes (allData, type, keys) {
  const { data, header } = allData
  const descriptionIndex = header.indexOf('provider')
  const categories = data.map(a => a[descriptionIndex].toUpperCase())
  const indexOfOld = header.indexOf('old')
  const indexOfNew = header.indexOf('new')

  const In = data.reduce((memo, num) => {
    const dataPoint = num[indexOfNew] * -1
    memo.push(dataPoint || -0.0001)
    return memo
  }, [])

  const Out = data.reduce((memo, num) => {
    const dataPoint = num[indexOfOld]
    memo.push(dataPoint || 0.0001)
    return memo
  }, [])

  const excessIn = data.reduce((memo, num) => {
    const dataPoint = num[indexOfOld] < num[indexOfNew] ? ((num[indexOfNew] - num[indexOfOld]) * -1) : ''
    memo.push(dataPoint)
    return memo
  }, [])

  const excessOut = data.reduce((memo, num) => {
    const dataPoint = num[indexOfOld] < num[indexOfNew] ? '' : num[indexOfOld] - num[indexOfNew]
    memo.push(dataPoint === 0 ? '' : dataPoint)
    return memo
  }, [])

  const series = [
    { id: 'in', name: 'In', unit: 'prefixes', data: In },
    { id: 'excessIn', name: 'Excess In', unit: 'prefixes', data: excessIn },
    { id: 'out', name: 'Out', unit: 'prefixes', data: Out },
    { id: 'excessOut', name: 'Excess Out', unit: 'prefixes', data: excessOut }
  ]

  return negativeBarResult(series, categories)
}

export function providersOverallImprovements (allData, type, keys) {
  const CustomDefinition = {
    perf: { legend: 'Performance', unit: 'prefixes' },
    custom: { legend: 'Other types', unit: 'prefixes' }
  }
  const { data, header } = allData
  const descriptionIndex = header.indexOf('provider')
  const categories = data.map(a => a[descriptionIndex].toUpperCase())
  const series = negativeBarSeriesParser(data, header, keys, CustomDefinition)

  return negativeBarResult(series, categories)
}

export function asnTrafficHeatmap (response) {
  const data = response?.data ?? []
  const header = response?.header ?? []
  const total = (response?.sum ?? [])[2] || 0

  // check if data has no zeros otherwise show no data
  const nonZero = data.reduce((acc, row) => acc + row[header.indexOf('volume')], 0)

  if (nonZero === 0) throw Object.assign(new Error('No data found'), { type: 'warning' })

  const series = data.map(row => {
    const asn = row[header.indexOf('asnumber')]
    const name = row[header.indexOf('asname')] === 'NULL'
      ? `ASN name unknown ${asn}`
      : `${row[header.indexOf('asname')]} ${asn}`

    const value = row[header.indexOf('volume')]
    const percent = ((value / total) * 100).toFixed()

    return {
      id: asn,
      name,
      traffic: converter(value),
      value: Number(value),
      colorValue: Number(value),
      percent: Number(percent)
    }
  })

  return {
    chart: {
      type: 'treemap'
    },
    legend: {
      symbolWidth: 350,
      x: 10
    },
    colorAxis: {
      minColor: '#FFFFFF',
      maxColor: 'green',
      labels: {
        formatter: function (data) {
          return converter(data.value)
        },
        overflow: 'allow'
      }
    },
    tooltip: {
      formatter: function () {
        const name = this?.point?.options?.name
        const traffic = this?.point?.options?.traffic ?? 0
        const percent = this?.point?.options?.percent ?? 0
        return `<b>ASN name</b>: ${name}<br><b>Traffic amount:</b> ${traffic}<br><b>Percentage:</b> ${percent}%`
      }
    },
    series: [{
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      data: series
    }],
    plotOptions: {
      series: {
        dataLabels: {
          style: {
            color: 'var(--secondary-accent-color)'
          }
        }
      }
    }
  }
}

/**
 *
 * @param allData
 * @returns {{series: [{data: [], colorByPoint: boolean}]}}
 */
export function defaultPie (allData) {
  const { data = [] } = allData || {}

  const pie = {
    colorByPoint: true,
    data: []
  }

  data.forEach(row => {
    pie.data.push({
      name: row[1],
      y: row[2]
    })
  })

  return {
    series: [pie]
  }
}

export function probedAndImprovedVolumes (allData) {
  const { data = [] } = allData || {}
  const pie = {
    colorByPoint: true,
    data: []
  }
  let total = 0
  let improved = 0
  data.forEach(i => {
    if (i[2] === 'Total traffic volume') {
      total = i[3]
    } else if (i[2] === 'Improved traffic volume') {
      improved = i[3]
    }
  })

  data.forEach(i => {
    if (i[2] === 'Total traffic volume' || i[2] === 'Improved traffic volume') {
      if (i[2] === 'Total traffic volume') {
        i[2] = 'Non improved traffic volume'
        i[3] = total - improved
      }
      pie.data.push({
        name: i[2],
        y: i[3]
      })
    }
  })

  return {
    series: [pie]
  }
}

export function latencyValuesMsPercent (allData) {
  const { data = [] } = allData || {}

  return {
    xAxis: {
      categories: data.map(i => i[0])
    },
    series: [{
      name: 'Latency old',
      data: data.map(i => Math.round((i[2]) * 100) / 100)
    }, {
      name: 'Remaining latency',
      data: data.map(i => Math.round(i[3] * 100) / 100)
    }]
  }
}

export function lossRelativeAbsoluteRates (allData) {
  const { data = [] } = allData || {}

  return {
    xAxis: {
      categories: data.map(i => i[0])
    },
    series: [{
      name: 'Loss Old',
      data: data.map(i => Math.round(i[2]))
    }, {
      name: 'Remaining loss',
      data: data.map(i => Math.round(i[3]))
    }]
  }
}

function improvementByProvider (allData, host, query, legend, preposition, avoidTimestamps) {
  const { data = [], header = [] } = allData || {}

  return header.filter(i => new RegExp('^' + query, 'i').test(i)).map(i => {
    const itemKey = Object.keys(header).find(key => header[key] === i)
    const provider = i.split(/[_]/)[1]

    return {
      name: i === 'reroutedprefixes' ? legend : preposition + upperFirst(provider),
      data: data.map(i => [avoidTimestamps ? convertISOToCustomFormat(i[0]) : toUnix(i[0]), i[itemKey]]),
      color: provider ? getProviderColor(host, provider) : undefined,
      tooltip: {
        pointFormat: tooltipFormatter(i === 'reroutedprefixes' ? legend : preposition + upperFirst(provider), 'prefixes')
      }
    }
  })
}

export function prefixesReroutedFromProvider (allData, type, keys, host) {
  const transferDay = allData.data.some(entry => isTimeTransferDay(toUnix(entry[0])))
  const series = improvementByProvider(
    allData,
    host,
    'reroutedprefixes|reroutedfrom_',
    'The total number of rerouted prefixes from providers',
    'Rerouted from ',
    transferDay
  )

  biggestAreaOtherLine(series)

  let graph = { series }
  if (transferDay) {
    graph = {
      series,
      xAxis: {
        type: 'category',
        uniqueNames: false,
        tickInterval: 35
      }
    }
  }

  return graph
}

export function prefixesReroutedToProvider (allData, type, keys, host) {
  const transferDay = allData.data.some(entry => isTimeTransferDay(toUnix(entry[0])))
  const series = improvementByProvider(
    allData,
    host,
    'reroutedprefixes|reroutedto_',
    'The total number of improved prefixes',
    'Rerouted to ',
    transferDay
  )

  biggestAreaOtherLine(series)

  let graph = { series }
  if (transferDay) {
    graph = {
      series,
      xAxis: {
        type: 'category',
        uniqueNames: false,
        tickInterval: 35
      }
    }
  }

  return graph
}

export function commitImprovementsByProvider (allData, type, keys, host) {
  const series = improvementByProvider(
    allData,
    host,
    'reroutedprefixes|reroutedto_',
    'The total number of improved prefixes by Commit Control',
    'Improvements by '
  )

  biggestAreaOtherLine(series)

  return { series }
}

export function latencyImprovementsByProvider (allData, type, keys, host) {
  return {
    series: improvementByProvider(
      allData,
      host,
      'reroutedprefixes|reroutedto_',
      'The number of improved prefixes by Latency',
      'Improvements by '
    )
  }
}

export function lossImprovementsByProvider (allData, type, keys, host) {
  return {
    series: improvementByProvider(
      allData,
      host,
      'reroutedprefixes|reroutedto_',
      'The number of improved prefixes by Loss',
      'Improvements by '
    )
  }
}

export async function totalAndImprovedTraffic (allData, type, keys, host, parameters) {
  const { header, data } = allData || {}
  const series = parseHeader(header)

  const transferDay = data.map(entry => isTimeTransferDay(toUnix(entry[0]))).includes(true)
  dispenseData(data, series, false, transferDay)

  const unit = 'Mbps'
  series.forEach(i => {
    i.name.includes('Total Traffic') ? i.name = 'Inbound total' : i.name = 'Inbound improved'
    i.name.includes('Inbound total') ? i.tooltip.pointFormat = tooltipFormatter('Inbound total', unit) : i.tooltip.pointFormat = tooltipFormatter('Inbound improved', unit)
  })

  const outSeries = await network.methods.requestResource('getTotalAndImprovedTraffic', { hosts: [host], ...parameters, inbound: 0 })
    .then(res => {
      const allDat = res.data[host].reports[0]
      const { header, data } = allDat || {}
      const outSeries = parseHeader(header)
      dispenseData(data, outSeries, false, transferDay)

      return outSeries
    })

  outSeries.forEach(i => {
    i.name.includes('Total Traffic') ? i.name = 'Outbound total' : i.name = 'Outbound improved'
    i.name.includes('Outbound total') ? i.tooltip.pointFormat = tooltipFormatter('Outbound total', unit) : i.tooltip.pointFormat = tooltipFormatter('Outbound improved', unit)
  })
  Array.prototype.push.apply(series, outSeries)

  let graph = {
    series
  }

  if (transferDay) {
    graph = {
      series,
      xAxis: {
        type: 'category',
        uniqueNames: false,
        tickInterval: 35
      }
    }
  }

  return graph
}

export function bandwidthUsageAndImprovements (allData) {
  const { header, data } = allData || {}
  const series = parseHeaderUnit(header)
  dispenseData(data, series)
  series.forEach(i => {
    if (i.name.includes('Improvements')) {
      i.yAxis = 1
    }
  })
  return {
    series,
    tooltip: {
      formatter: function () {
        if (series[this.series.index].tooltipData.unit === 'prefixes') {
          return `
          <span style="font-size: 10px">${formatTimestamp(this.x)}</span><br />
          <span style="color: ${this.color}">\u25CF</span>
          ${this.series.name}: <span style="font-weight: bold">${this.point.y}</span>
          <span style="font-weight: bold">${series[this.series.index].tooltipData.unit}</span>
          `
        }

        return `
        <span style="font-size: 10px">${formatTimestamp(this.x)}</span><br />
        <span style="color: ${this.color}">\u25CF</span>
        ${this.series.name}: <span style="font-weight: bold">${bandwidthFormatter(60, false)(this.point.y * 10e5)}</span>
        `
      }
    }
  }
}

export function performanceImprovements (allData) {
  const { data = [], header = [] } = allData || {}

  // prepare loss & latency
  const unit = 'prefixes'
  const legendLoss = 'The number of improved prefixes by Loss'
  const legendLatency = 'The number of improved prefixes by Latency'
  const query = 'losscnt|latencycnt'
  const perfImprov = header.filter(i => new RegExp('^' + query, 'i').test(i)).map(i => {
    const itemKey = Object.keys(header).find(key => header[key] === i)
    return {
      name: i === 'losscnt' ? legendLoss : legendLatency,
      data: data.map(i => {
        return [
          toUnix(i[0]),
          i[itemKey]
        ]
      }),
      tooltip: {
        pointFormat: tooltipFormatter(i === 'losscnt' ? legendLoss : legendLatency, unit)
      }
    }
  })

  const legend = 'The total number of improved prefixes'
  // prepare Improved pref
  const improvedPref = {
    name: legend,
    data: [],
    tooltip: {
      pointFormat: tooltipFormatter(legend, unit)
    }
  }
  perfImprov.map(arrItem => {
    arrItem.data.map((dataItem, dataKey) => {
      if (dataKey in improvedPref.data) {
        improvedPref.data[dataKey][1] += dataItem[1]
      } else {
        improvedPref.data[dataKey] = Array.from(dataItem)
      }
    })
  })
  perfImprov.unshift(improvedPref)

  // adjust style
  biggestAreaOtherLine(perfImprov)

  return {
    series: perfImprov
  }
}

export function getLossImprovements (allData) {
  const pie = {
    data: []
  }

  allData.data.map(i => {
    if (i[0] === 'Loss eliminated' || i[0] === 'Loss reduced') {
      pie.data.push({
        name: i[0],
        y: i[2]
      })
    }
  })

  return {
    series: [pie]
  }
}

export function inboundPerformanceRuleAverageRttAndLoss (allData) {
  const header = ['ts', 'loss', 'latency']
  const data = allData.rttLossSeries.map(s => [s.ts, s.loss, s.rtt])
  const area = defaultArea({ header, data })
  const isOneRecord = allData.activeStatusSeries.length <= 1
  const plotBands = allData.activeStatusSeries.map((s, i) => ({
    from: toUnix(s.from),
    to: toUnix(s.to),
    color: 'rgba(255, 132, 0, 16%)',
    label: {
      text: 'Rule enabled',
      y: i % 2 === 0 ? 15 : 30,
      style: {
        color: '#606060',
        fontWeight: 'bold'
      }
    },
    id: 'average-rtt-and-loss-plotband-' + i,
    zIndex: 5
  }))
  area.series.forEach((s, i) => {
    s.data.sort((a, b) => {
      const [aTs] = a
      const [bTs] = b

      if (aTs < bTs) {
        return -1
      }
      if (aTs > bTs) {
        return 1
      }

      return 0
    })
    s.color = colors[i]
    s.yAxis = i
  })

  return {
    ...area,
    chart: {
      type: 'line'
    },
    legend: {
      align: 'center'
    },
    tooltip: {
      formatter: function () {
        const unit = SeriesDefinition[header[this.series.index + 1]]?.unit

        return `
          <b>${userDateTimeFormat(this.x)}</b><br />
          <span style="color:${this.color};padding-right: 2px;">●</span>\t
          ${this.series.name}: ${this.y} ${unit}
        `
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: isOneRecord,
          radius: 4
        }
      }
    },
    xAxis: {
      plotBands,
      labels: {
        formatter () {
          return userTimeFormat(new Date(this.value)).replace(':00', '')
        }
      }
    },
    yAxis: [
      { // Primary yAxis
        labels: {
          format: '{value}%',
          style: {
            color: colors[0]
          }
        },
        title: {
          text: 'PACKET LOSS',
          style: {
            color: colors[0]
          }
        }
      },
      { // Secondary yAxis
        title: {
          text: 'LATENCY',
          style: {
            color: colors[1]
          }
        },
        labels: {
          format: '{value} ms',
          style: {
            color: colors[1]
          }
        },
        opposite: true
      }
    ]
  }
}

export function inboundPerformanceRuleAverageBandwidth (allData) {
  const formatBandwidth = bandwidthFormatter()
  const header = ['ts', 'bandwidth']
  const data = allData.bandwidthSeries.map(entry => [entry.ts, entry.bandwidth])
  const area = defaultArea({ header, data })
  const plotBands = allData.activeStatusSeries.map((s, i) => ({
    from: toUnix(s.from),
    to: toUnix(s.to),
    color: 'rgba(255, 132, 0, 15%)',
    label: {
      text: 'Rule enabled',
      y: i % 2 === 0 ? 15 : 30,
      style: {
        color: '#606060',
        fontWeight: 'bold'
      }
    },
    id: 'bandwidth-plotband-' + i,
    zIndex: 5
  }))

  area.series.forEach(s => {
    s.data.sort((a, b) => {
      const [aTs] = a
      const [bTs] = b

      if (aTs < bTs) {
        return -1
      }
      if (aTs > bTs) {
        return 1
      }

      return 0
    })
    s.color = '#64C571'
  })

  return {
    ...area,
    chart: {
      type: 'line',
      spacingLeft: 20,
      spacingRight: 90
    },
    legend: {
      align: 'center'
    },
    tooltip: {
      formatter: function () {
        return `
          <b>${userDateTimeFormat(this.x)}</b><br />
          <span style="color:${this.color};padding-right: 2px;">●</span>\t
          Bandwidth: ${formatBandwidth(this.y)}
        `
      }
    },
    xAxis: {
      plotBands,
      labels: {
        formatter () {
          return userTimeFormat(new Date(this.value)).replace(':00', '')
        }
      }
    },
    yAxis: {
      title: {
        text: false
      },
      labels: {
        formatter: formatBandwidth
      }
    }
  }
}

export async function inboundHistoricalGraph (allData, type, keys, host, parameters) {
  const instanceId = arguments[3]
  const store = arguments[5]
  if (!store.getters.hasProviders(instanceId)) await store.dispatch('fetchProviders', instanceId)
  const providers = (store.getters.getProvidersByInstanceId(instanceId))

  const { header, data } = allData || {}

  const seriesCol = {
    v_th: 'Gb',
    v: 'GB'
  }

  const newHeaders = Object.keys(seriesCol).map(item => {
    const h = new Map()
    h.set('ts', 'ts')
    h.set('All Providers', item)
    providers.forEach(p => {
      h.set(p.label, `${item}${p.value}`)
    })
    return h
  })
  const headKeys = Array.from(newHeaders[0].keys())
  const newData = [[], []]
  data.forEach(row => {
    Object.entries(seriesCol).forEach((entry, idx) => {
      const newSeries = []
      const newRow = []
      Array.from(newHeaders[idx].values()).forEach(item => {
        newRow.push(row[header.indexOf(item)])
      })
      newSeries.push([...newRow])
      newData[idx].push(...newSeries)
    })
  })

  const { series: seriesVth } = defaultArea({ header: headKeys, data: newData[0] }, type)
  const { series: seriesV } = defaultArea({ header: headKeys, data: newData[1] }, type)

  return {
    seriesvth: seriesVth,
    seriesv: seriesV,
    yAxis: {
      minRange: 0.001,
      title: {
        text: undefined
      },
      labelsvth: {
        formatter: function () {
          const formatter = this.value > 1024 ** 3
            ? bandwidthFormatter(60, false, 1)
            : bandwidthFormatter(60, false, 0)
          return formatter(this.value)
        }
      },
      labelsv: {
        formatter: function () {
          return converter(this.value)
        }
      }
    },
    tooltipvth: {
      formatter: function () {
        return `
            <b>${userDateTimeFormat(this.x)}</b><br />
            <span style="color:{point.color}">\u25CF</span> ${this.series.name}: <b>${bandwidthFormatter(60, false)(this.point.y)}</b><br/>
            `
      }
    },
    tooltipv: {
      formatter: function () {
        return `
            <b>${userDateTimeFormat(this.x)}</b><br />
            <span style="color:{point.color}">\u25CF</span> ${this.series.name}: <b>${converter(this.point.y)}</b><br/>
            `
      }
    }
  }
}

export function problemDestination (allData) {
  const pie = {
    data: []
  }

  allData.data.map(i => {
    if (i[0] === 'Loss eliminated' || i[0] === 'Loss reduced') {
      pie.data.push({
        name: i[0],
        y: i[1]
      })
    }
  })

  return {
    series: [pie]
  }
}

export function probedPrefixesAndImprovements (allData, type) {
  const { header, data } = allData || {}
  const tsIndex = header.indexOf('ts')
  const losscntIndex = header.indexOf('losscnt')
  const othercntIndex = header.indexOf('othercnt')
  const latencycntIndex = header.indexOf('latencycnt')
  const probedPrefixesIndex = header.indexOf('probedprefixes')
  const commitControlCntIndex = header.indexOf('commitcontrolcnt')
  const newHeader = ['ts', 'prefixesimproved']
  const newData = []
  data.forEach(row => {
    const addition = row[losscntIndex] + row[latencycntIndex] + row[commitControlCntIndex] + row[othercntIndex]
    // Prevent the NaN for times when 0 / number | e.g. 0 / 8 => NaN
    const improvedPrefixes = addition === 0 ? 0 : (addition / row[probedPrefixesIndex]) * 100
    newData.push([row[tsIndex], Math.round(improvedPrefixes)])
  })

  return { ...defaultArea({ header: newHeader, data: newData }, type), yAxis: { max: 100 } }
}

export function npoProbedPrefixesAndImprovements (allData, type) {
  const { header, data } = allData || {}
  const probedprefixesIndex = header.indexOf('probedprefixes')
  const dictionary = {
    losscnt: 'Loss',
    latencycnt: 'Latency',
    commitcontrolcnt: 'Commit Control',
    othercnt: 'Other'
  }
  header.splice(probedprefixesIndex, 1)

  data.map(row => row.splice(probedprefixesIndex, 1))

  const series = []

  for (let i = 1; i < header.length; i++) {
    series[i - 1] = ({
      name: dictionary[header[i]],
      data: [],
      states: {
        hover: {
          lineWidthPlus: 0
        }
      }
    })
  }

  data.forEach(entry => {
    for (let i = 0; i < series.length; i++) {
      series[i].data.push([toUnix(entry[0]), entry[i + 1]])
    }
  })

  return { series }
}

export function npoTotalAndImprovedTraffic (allData, type) {
  const { header, data } = allData || {}
  const tsIndex = header.indexOf('ts')
  const improvedTrafficIndex = header.indexOf('improvedtraffic')
  const totalTrafficIndex = header.indexOf('totaltraffic')
  const newHeader = ['ts', 'trafficimproved']
  const newData = []
  data.forEach(row => {
    // Prevent the NaN for times when 0 / number | e.g. 0 / 8 => NaN
    const improvedTraffic = row[improvedTrafficIndex] === 0
      ? 0
      : (row[improvedTrafficIndex] / row[totalTrafficIndex]) * 100

    newData.push([row[tsIndex], Math.round(improvedTraffic)])
  })
  return { ...defaultArea({ header: newHeader, data: newData }, type), yAxis: { max: 100 } }
}

export function npoProbedPrefixesAndImprovementsPie (allData, type) {
  const { header, data = [] } = allData || {}

  const losscntIndex = header.indexOf('losscnt')
  const othercntIndex = header.indexOf('othercnt')
  const latencycntIndex = header.indexOf('latencycnt')
  const commitControlCntIndex = header.indexOf('commitcontrolcnt')

  const reduced = data.reduce((accumulator, currentValue) => {
    return {
      loss: accumulator.loss + currentValue[losscntIndex],
      latency: accumulator.latency + currentValue[latencycntIndex],
      commitControl: accumulator.commitControl + currentValue[commitControlCntIndex],
      other: accumulator.other + currentValue[othercntIndex]
    }
  }, { loss: 0, other: 0, latency: 0, commitControl: 0 })

  return {
    series: [{
      colorByPoint: true,
      data: [
        { name: 'Loss', y: reduced.loss },
        { name: 'Latency', y: reduced.latency },
        { name: 'Commit control', y: reduced.commitControl },
        { name: 'Other', y: reduced.other }
      ]
    }]
  }
}

export async function probesToday (allData) {
  const vm = arguments[6]
  const instanceId = arguments[3]
  const store = arguments[5]
  if (!store.getters.hasProviders(instanceId)) await store.dispatch('fetchProviders', instanceId)
  const providersLabels = (store.getters.getProvidersByInstanceId(instanceId).map(p => p.label) || [])

  // skip loss with value null and bigger than 100 and null latency
  // const rule = (type, value) => type === 'loss' ? value !== null && value < 100 : value !== null
  const genName = type => type === 'Probes' ? `Total ${type}` : 'General average'
  const genSeries = (providers, type) => ([{ name: genName(type), data: [] }, ...providers.map(provider => ({ name: provider, data: [] }))])
  const syncCharts = {
    loss: { ...SyncGraph(vm, 'Average Loss', '%'), series: genSeries(providersLabels, 'Loss') },
    latency: { ...SyncGraph(vm, 'Average Latency', 'ms'), series: genSeries(providersLabels, 'Latency') },
    probes: { ...SyncGraph(vm, 'Count of success probes', 'probes'), series: genSeries(providersLabels, 'Probes') }
  }

  const graphsNames = ['loss', 'latency', 'probes']
  const addToSyncCharts = (graphIndex, seriesIndex, payload) => {
    const { ts, value } = payload
    const graphName = graphsNames[graphIndex]
    const seriesValue = (value !== null && value < 100) ? value : 0

    syncCharts[graphName].series[seriesIndex].data.push([ts, seriesValue])
  }

  const timestampIndex = 0
  const valueIndex = 1
  allData.forEach((row, graphIndex) => {
    Object
      .keys(row)
      .forEach(key => {
        // Check if current key corresponds to a provider label
        let seriesIndex = providersLabels.indexOf(key)

        // If the label was not found in the store providers labels, it means key is not a providerLabel
        const isProvider = !!~seriesIndex

        // On the index 0 there should be added only oneOf('generalProbes', 'totalProbes')
        seriesIndex = isProvider ? seriesIndex + 1 : 0

        row[key]
          .forEach(data => {
            const payload = {
              ts: toUnix(data[timestampIndex]),
              value: data[valueIndex]
            }

            addToSyncCharts(graphIndex, seriesIndex, payload)
          })
      })
  })

  return { syncCharts }
}

export async function providersBandwidthUsage (allData) {
  const vm = arguments[6]
  const instanceId = arguments[3]
  const store = arguments[5]
  if (!store.getters.hasProviders(instanceId)) await store.dispatch('fetchProviders', instanceId)
  const providers = (store.getters.getProvidersByInstanceId(instanceId) || [])

  const genSeries = providers => providers.map(({ label }) => ({ name: label, data: [] }))

  const syncCharts = {
    in: { ...SyncGraph(vm, 'Inbound', 'Mbps'), series: genSeries(providers) },
    out: { ...SyncGraph(vm, 'Outbound', 'Mbps'), series: genSeries(providers) },
    total: { ...SyncGraph(vm, 'Total bandwidth', 'Mbps'), series: genSeries(providers) }
  }

  allData.forEach(graph => {
    const idxTs = graph.header.indexOf('ts')
    const idxIn = graph.header.indexOf('in')
    const idxOut = graph.header.indexOf('out')

    const providerIdx = providers.findIndex(provider => provider.value === Number(graph.filters.provider))

    graph.data.forEach(row => {
      const unixTimeStamp = toUnix(row[idxTs])
      const [inProvider, outProvider] = [syncCharts.in.series[providerIdx], syncCharts.out.series[providerIdx]]

      inProvider.data.push([unixTimeStamp, row[idxIn]])
      inProvider.showInLegend = false
      outProvider.data.push([unixTimeStamp, row[idxOut]])
      outProvider.showInLegend = false

      syncCharts.total.series[providerIdx].data.push([unixTimeStamp, row[idxIn] + row[idxOut]])
    })
  })
  return { syncCharts }
}

export async function bandwidthUsagePerProvider (allData) {
  const instanceId = arguments[3]
  const store = arguments[5]
  const vm = arguments[6]

  const dictionary = {
    bandwidthusage: 'Bandwidth usage',
    in: 'Inbound',
    out: 'Outbound',
    current95thIn: 'Current 95th Inbound',
    current95thOut: 'Current 95th Outbound',
    commit95thIn: 'Commit 95th Inbound',
    commit95thOut: 'Commit 95th Outbound'
  }

  let newDictionary = {}
  if (!vm.showInbound) {
    const asArray = Object.entries(dictionary)
    const filtered = asArray.filter(([key, value]) => !value.includes('Inbound'))
    newDictionary = Object.fromEntries(filtered)
  } else {
    newDictionary = dictionary
  }

  const { header, data, filters } = allData

  // Get Provider Name
  if (!store.getters.hasProviders(instanceId)) await store.dispatch('fetchProviders', instanceId)
  const providerName = (store.getters.getProvidersByInstanceId(instanceId) || []).find(p => p.value.toString() === filters.provider).label

  // Get the index of the row element in the header
  const headerIdx = el => header.indexOf(el)

  const series = []

  Object.keys(newDictionary).forEach(d => {
    const lineDate = lineName => data.map(item => [toUnix(item[headerIdx('ts')]), item[headerIdx(lineName)]])

    if (d === 'bandwidthusage') {
      const inboundRow = lineDate('in')
      const outboundRow = lineDate('out')
      const bandwidthRow = inboundRow.map((val, idx) => [val[0], Number((val[1] + outboundRow[idx][1]).toFixed(5))])
      series.push({ name: newDictionary.bandwidthusage, data: [...bandwidthRow] })
    } else series.push({ name: newDictionary[d], data: [...lineDate(d)] })
  })

  biggestAreaOtherLine(series)

  const calcCommit = point => `${point} Mbps`

  return {
    title: {
      text: `Bandwidth usage for ${providerName}`,
      style: {
        display: 'none'
      }
    },
    series,
    tooltip: {
      formatter: function () {
        return `
            <span style="font-size: 10px">${formatTimestamp(this.x)}</span><br />
            <span style="color: ${this.color}">\u25CF</span>
            ${this.series.name}: <span style="font-weight: bold">
                                    ${!this.series.name.toLowerCase().includes('commit')
    ? bandwidthFormatter(60, false)(this.point.y * 10e5)
    : calcCommit(this.point.y)}
                                    </span>
            `
      }
    },
    yAxis: {
      title: {
        text: 'Mbps'
      }
    }
  }
}
