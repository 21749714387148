import types from './types'
import { updateFormat } from '@/utils/helpers'
import { setItem } from '@/utils/localStorage'

export default {
  [types.UPDATE_PROFILE] (state, profile) {
    state.profile = profile

    setItem('theme', profile?.theme ?? 'light')

    if (profile) {
      const date = profile?.dateFormat ?? 'dd-MM-yy'
      const time = profile?.timeFormat ?? 'hh:mm:ss a'
      updateFormat(date, time)
      state.needsReAuth = false
    }
  },
  [types.REMOVE_PROFILE] (state, needsReload) {
    updateFormat('dd-MM-yy', 'hh:mm:ss a')
    state.profile = null
    state.needsReload = !!needsReload
  },
  [types.NEEDS_RE_AUTH] (state, value) {
    state.needsReAuth = value
  },
  [types.ACCESS_RESTRICTED] (state, value) {
    state.restricted = value
  },
  [types.ADD_INSTANCE_TO_SYSTEM_DASHBOARD] (state, instance) {
    state.systemDashboard.instances.push(String(instance))
    state.systemDashboard.selected = instance
  },
  [types.TOGGLE_SYSTEM_DASHBOARD] (state, value) {
    state.systemDashboard.visible = Boolean(value)
  },
  [types.UPDATE_CURRENT_SYSTEM_DASHBOARD] (state, value) {
    if (String(value).length) {
      state.systemDashboard.selected = value
      state.systemDashboard.visible = true
    }
  },
  [types.UPDATE_OUTDATED_VERSION] (state, value) {
    state.outdatedVersion = Boolean(value)
  },
  [types.SCROLL_LOCK_UPDATE] (state, lock) {
    state.scrollLocked = lock
  },
  [types.SSL_CERTIFICATE_EXPIRE] (state, expireIn) {
    state.sslExpireSoon = expireIn
  },
  [types.UPDATE_CURRENT_VERSION] (state, version) {
    state.currentVersion = version
  },
  [types.MEDIA_QUERY_THEME] (state, theme) {
    state.mediaQueryTheme = theme
  }
}
