import { capitalize, get } from '@/utils/lodash'
import Type from './types'
import { polling_get } from '@/api/methods'

const instanceId = instance => instance.id

export default {
  fetchEvents ({ dispatch }) {
    ['errors', 'warnings', 'info'].forEach(type => dispatch('getLastN', type))
  },
  getLastN ({ rootState, getters, commit }, type) {
    const hosts = rootState.instances.all.map(instanceId)

    const alias = `journal${capitalize(type)}`
    return polling_get({ alias, hosts })
      .then(response => {
        if (response !== false) {
          const data = get(response, 'data', false)

          commit(Type.EVENTS, { data, type })
        }
        return response
      })
  },
  fetchCriticalIssues ({ rootState, commit }) {
    const hosts = rootState.instances.all.map(instanceId)
    const alias = 'warnings'
    return polling_get({ alias, hosts })
      .then(response => {
        const data = get(response, 'data', false)
        if (response !== false) {
          commit(Type.WARNINGS, data)
        }
        return response
      })
  }
}
