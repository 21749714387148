import { getDomain } from './request'
export const updateSubscription_RAW_URL = () => '/irp/notifications/subscriptions/{id}'

export const updateSubscription_URL = (parameters = {}) => {
  let path = updateSubscription_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
