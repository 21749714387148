import { getDomain } from './request'
export const getCurrentImprovements_RAW_URL = () => '/irp/reports/currentImprovements'

export const getCurrentImprovements_URL = (parameters = {}) => {
  let path = getCurrentImprovements_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.geoLocation !== undefined) {
    queryParameters.geoLocation = parameters.geoLocation
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
