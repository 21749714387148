import { getDomain } from './request'
export const getInboundPerformanceRuleAverageRttAndLoss_RAW_URL = () => '/irp/inboundperformance/rules/{id}/reports/averagerttandloss'

export const getInboundPerformanceRuleAverageRttAndLoss_URL = (parameters = {}) => {
  let path = getInboundPerformanceRuleAverageRttAndLoss_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
