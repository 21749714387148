import mutations from './mutations'
import actions from './actions'

const state = {
  tasks: []
}

const getters = {
  tasks: state => state.tasks
}

export default {
  state,
  actions,
  getters,
  mutations
}
