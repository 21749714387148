import types from './types'

export default {
  [types.FILTERS] (state, filters) {
    state.filters = filters
  },
  [types.ADD_WIDGET] (state, widget) {
    return state.widgets.push(widget)
  },
  [types.UPDATE_WIDGET] (state, widget) {
    const foundIndex = state.widgets.findIndex(item => item.id === widget.id)
    if (foundIndex !== -1) {
      state.widgets.splice(foundIndex, 1, widget)
    }
  },
  [types.WIDGETS] (state, widgets) {
    state.widgets = widgets
  },
  [types.REMOVE_WIDGET] (state, id) {
    const foundIndex = state.widgets.indexOf(state.widgets.find(w => w.id === id))
    if (foundIndex !== -1) {
      state.widgets.splice(foundIndex, 1)
    }
  },
  [types.DASHBOARDS] (state, dashboards) {
    state.all = dashboards
  },
  [types.ADD_DASHBOARD] (state, dashboard) {
    const foundDashboard = state.all.find(iter => iter.id === dashboard.id)
    if (!foundDashboard) {
      if (!dashboard.widgets) dashboard.widgets = []
      state.all.push(dashboard)
    }
  },
  [types.UPDATE_DASHBOARD] (state, dashboard) {
    const foundIndex = state.all.indexOf(state.all.find(d => d.id === dashboard.id))
    if (foundIndex !== -1) {
      state.all.splice(foundIndex, 1, dashboard)
    }
  },
  [types.REMOVE_DASHBOARD] (state, dashboard) {
    const foundIndex = state.all.indexOf(state.all.find(iter => iter.id === dashboard.id))
    if (foundIndex !== -1) {
      state.all.splice(foundIndex, 1)
    }
  }
}
