<template>
  <div
    class="shortname-container outline-none"
    data-test="instance-shortname"
    @click="onClick"
  >
    <div
      v-if="type === 'all'"
      :title="title"
      class="shortname-container__wrapper overflow-hidden"
    >
      <div class="color">
        <div
          :style="{ background: instance.color }"
          class="color-rect flex items-center"
        >
          <span v-text="instance.shortName" />
          <slot />
        </div>
      </div>
      <div
        class="text truncate dark:text-white mr-2"
        v-if="instance.description"
        v-text="instance.description"
      />
    </div>
    <div
      v-else-if="type === 'color'"
      :title="title"
      class="color overflow-hidden"
    >
      <div
        :style="{ background: instance.color }"
        class="color-rect flex items-center"
      >
        <span v-text="instance.shortName" />
        <slot />
      </div>
    </div>
    <VDropdown
      v-else-if="type === 'popover'"
      :triggers="['hover', 'focus']"
      :disabled="false"
      :placement="'auto'"
    >
      <div class="shortname-container__wrapper outline-none overflow-hidden">
        <div class="color">
          <div
            :style="{ background: instance.color }"
            class="color-rect flex items-center"
          >
            <span v-text="instance.shortName" />
          </div>
        </div>
        <div
          v-if="withDescription"
          class="text truncate dark:text-white mr-2"
          v-text="instance.description"
        />
      </div>

      <template #popper>
        <div class="popover-content dark:text-white px-3 py-3">
          <div class="border-b border-b-darkgray-200 dark:border-darkgray-100 mb-3 pb-3 inline-flex flex-row gap-1 w-full">
            <span
              class="font-bold flex-grow truncate text-darkgray-400 dark:text-white"
              v-text="instance.host"
            />
            <span
              class="font-bold whitespace-no-wrap"
              :class="[instanceStatusColor(instance.status)]"
              v-text="`- ${upperCaseFirstChar(instance.status)}`"
            />
          </div>
          <div class="grid grid-cols-2 border-b border-b-darkgray-200 text-darkgray-500 dark:text-white pb-2">
            <span>Version:</span><span
              class="font-bold"
              v-text="instance.version"
            />
            <span>License:</span><span
              class="font-bold"
              v-text="getLicenseInfo(instance.license)"
            />
            <template v-if="instance.failover">
              <span>Failover:</span><span
                class="font-bold"
                style="color: var(--green-color);"
                v-text="getFailoverStatus(instance)"
              />
            </template>
          </div>
          <div class="hidden border-b border-b-darkgray-200">
            <span>System Monitoring:</span>
            <div class="system-monitoring-info">
              <span class="font-bold">17%</span><span> CPU</span>
              <span class="font-bold">34%</span><span> RAM</span>
              <span class="font-bold">59%</span><span> Storage</span>
            </div>
          </div>
          <span class="block font-normal text-sm text-darkgray-200 pt-2">Click on the tag for detailed info.</span>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import InstanceMixin from '@/mixins/instance'
import { userDateFormat, upperCaseFirstChar } from '@/utils/helpers'

export default {
  name: 'InstanceShortname',
  mixins: [InstanceMixin],
  props: {
    instance: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'all', // 3 types, all, color or popover
      validator: value => ['all', 'color', 'popover'].indexOf(value) !== -1
    },
    withTitle: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: true
    },
    withDescription: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return this.withTitle ? this.instance.shortName : null
    }
  },
  methods: {
    onClick (e) {
      if (!this.clickable) return

      // do not open anything, if no id provided
      if (!this.instance.id) return

      e.preventDefault()
      e.stopPropagation()
      this.$hub.$emit('instanceIcon:clicked', this.instance.id)
    },
    getLicenseInfo (license) {
      return `${license.bandwidth} ${license.unit}, ${license.status}, ${userDateFormat(license.expiration * 1000)}`
    },
    upperCaseFirstChar,
    getFailoverStatus (instance) { return instance?.failover.role === 0 ? 'Master' : 'Slave' }
  }
}
</script>

<style lang="scss">
div.tooltip.popover .popover-inner { padding: 15px 24px; }
</style>

<style scoped lang="scss">
  .shortname-popover {
    .popover-content {
      width: 242px;
      font-size: 13px;
      color: #515151;

      @apply font-normal;
    }
  }

  .shortname-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &__wrapper {
      display: flex;
      align-items: center;
    }
  }

  .color {
    font-size: 14px;
  }

  .text {
    margin-left: 10px;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .color-rect {
    height: 19px;
    padding: 0 7px;
    margin: 0;
    font-size: 13px;
    font-weight: bold;
    line-height: 19px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #f68e56;
    border-radius: 9px;
  }
</style>
