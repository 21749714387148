const Restricted = () => import(/* webpackChunkName: "Restricted" */ '@/views/Auth/Restricted')

export default function () {
  return {
    path: '/restricted',
    name: 'AccessRestricted',
    component: Restricted,
    meta: { requiredAuth: false, layout: 'AuthLayout' }
  }
}
