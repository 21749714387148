import { getDomain } from './request'
export const widgets_create_RAW_URL = () => '/dashboard/{idDashboard}/widgets'

export const widgets_create_URL = (parameters = {}) => {
  let path = widgets_create_RAW_URL()
  let query = ''

  path = path.replace('{idDashboard}', `${parameters.idDashboard}`)
  return getDomain() + path + query
}
