import get_providers from '@/api/methods/get_providers'
import types from '@/store/modules/providers/types'
import { get } from '@/utils/lodash'

const alreadyFetching = new Map()

export default {
  state: {
    all: new Map()
  },
  getters: {
    hasProviders: state => id => state.all.has(id),
    getProviders: state => state.all,
    getProvidersByInstanceId: state => id => state.all.get(id)?.providers ?? []
  },
  actions: {
    /**
     * Fetch instance providers from instance(ses)
     *
     * @param commit
     * @param getters
     * @param instanceId  {String} Id of exact instance.
     * @return {Promise<*>}
     */
    fetchProviders ({ commit, getters }, instanceId) {
      // check if empty instance id is provided
      if (!instanceId) return Promise.reject(new Error('Instance id is required'))

      // if we already requesting such request
      if (alreadyFetching.has(instanceId)) {
        return alreadyFetching.get(instanceId)
      }

      // check if providers is still actual
      const isActual = (getters.getProviders.get(instanceId)?.fetchedAt ?? undefined) !== undefined &&
        Math.abs(getters.getProviders.get(instanceId).fetchedAt - Date.now()) <= 30000

      // return cached version
      if (isActual) {
        return Promise.resolve(getters.getProvidersByInstanceId(instanceId))
      }

      const promise = get_providers({ hosts: [instanceId] })
        .then(({ data }) => {
          const providers = get(data, `${instanceId}.status.providers`, {})
          const formatted = Object.keys(providers)
            .map(id => {
              const exchange = providers[id].iXpeers !== undefined
              return {
                label: providers[id].title, // exchange ? `${providers[id].title} Exchange` : providers[id].title,
                value: Number(id),
                state: providers[id].state,
                exchange
              }
            })

          if (formatted.length) {
            commit(types.ADD_INSTANCE_PROVIDERS, { instanceId, providers: formatted })
          } else {
            commit(types.REMOVE_INSTANCE_PROVIDERS, instanceId)
          }

          alreadyFetching.delete(instanceId)
          return formatted
        })

      // store current request for some kinda debounce functionality
      alreadyFetching.set(instanceId, promise)

      return promise
    }
  },
  mutations: {
    [types.ADD_INSTANCE_PROVIDERS] (state, { instanceId, providers }) {
      state.all.set(instanceId, { fetchedAt: Date.now(), providers })
    },
    [types.REMOVE_INSTANCE_PROVIDERS] (state, instanceId) {
      state.all.delete(instanceId)
    }
  }
}
