<template>
  <div class="flex items-center" :style="{ width }">
    <div
      v-if="instancesNotSupportingFeature.length > 0"
      class="mt-5 mr-2"
    >
      <VDropdown
        :trigger="['hover', 'focus']"
        :placement="'top'"
      >
        <ui-icon
          :class="featureSatisfactionLevelData.colorClass"
          :icon="featureSatisfactionLevelData.icon"
        />
        <template #popper>
          <div class="popover-content">
            <p
              v-if="featureSatisfactionLevel === 'all_not_satisfied'"
              :class="featureSatisfactionLevelData.colorClass"
            >
              <span class="font-normal">None of the instances support {{ featureLabel }}</span>
            </p>
            <div v-else>
              <p
                v-for="instance in instancesNotSupportingFeature"
                :key="instance.id"
                class="my-2"
                :class="featureSatisfactionLevelData.colorClass"
              >
                <instance-shortname
                  :instance="instance"
                  :clickable="false"
                />
                <span class="font-normal">doesn't support {{ featureLabel }}</span>
              </p>
            </div>
          </div>
        </template>
      </VDropdown>
    </div>
    <ui-select
      class="instance-select"
      :style="{ width }"
      :value="value"
      :error="error"
      :theme="theme"
      :disabled="shouldDisableComponent"
      :data="instanceOptions"
      :selected-font-weight="selectedFontWeight"
      test="instance-select"
      placeholder="Choose an instance"
      rounded
      @input="select"
    >
      <template
        v-if="label"
        #label
      >
        <label
          class="text-darkgray-500 dark:text-white whitespace-nowrap font-bold label-text"
        >
          Instance
          <label v-if="required" style="color: var(--red-color); margin-left: 10px">*</label>
        </label>
      </template>
      <template v-if="value" #selected-text="items">
        <div class="instance-option selected">
          <span
            class="label"
            :style="{ 'background-color': generateSelectData(items, 'color') }"
            v-text="generateSelectData(items, 'label')"
          />
          <span
            v-if="generateSelectData(items, 'description')"
            class="font-normal truncate"
            v-text="` ${generateSelectData(items, 'description')}`"
          />
        </div>
      </template>
      <template #select-option="items">
        <div class="instance-option">
          <span
            class="label text-darkgray-500 dark:text-white"
            :style="{ 'background-color': generateSelectData(items, 'color') }"
            v-text="generateSelectData(items, 'label')"
          />
          <span
            v-if="generateSelectData(items, 'description')"
            class="font-normal truncate text-darkgray-500 dark:text-white"
            v-text="generateSelectData(items, 'description')"
          />
        </div>
      </template>
    </ui-select>
  </div>
</template>
<script>
import InstanceShortname from '@/components/common/InstanceShortname'
import { mapGetters, mapState } from 'vuex'
import { get } from '@/utils/lodash'
import types from '@/store/modules/instances/types'
import { features, featureSatisfactionLevels, prettyFeatureLabel, supported } from '@/utils/featuresSupport'
import UiIcon from 'uilib/src/Icon'
import UiSelect from 'uilib/src/Select'

export default {
  name: 'InstanceSelect',
  components: { InstanceShortname, UiIcon, UiSelect },
  props: {
    disabled: Boolean,
    value: {
      type: String,
      default: undefined
    },
    error: {
      type: String,
      default: undefined
    },
    eventKey: {
      type: String,
      default: 'selectInstance:default'
    },
    label: {
      type: Boolean,
      default: false
    },
    autoSelectInstance: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '200px'
    },
    selectedFontWeight: {
      type: String,
      default: undefined
    },
    feature: {
      type: String,
      required: false,
      validator: function (value) {
        return typeof features[value] !== 'undefined'
      },
      default: 'reports'
    },
    required: {
      type: Boolean,
      default: false
    },
    filterInstances: {
      type: Function,
      default: () => true
    }
  },
  computed: {
    ...mapGetters(['getActiveInstances', 'getCurrentTheme']),
    theme () {
      return this.getCurrentTheme ? this.getCurrentTheme : 'light'
    },
    ...mapState({ selectedInstance: state => state.instances.selectedInstance }),
    instancesNotSupportingFeature () {
      return this.getActiveInstances.filter(i => this.feature && !supported(this.feature, i.version))
    },
    instanceOptions () {
      return this.getActiveInstances
        .filter(activeInstance => !this.instancesNotSupportingFeature.find(
          instanceNotSupportingFeature => instanceNotSupportingFeature.id === activeInstance.id
        ))
        .filter(this.filterInstances)
        .map(i => {
          const description = (i.description || '').slice(0, 15)
          const pad = i.description.length > 15 ? 18 : 0
          return {
            value: i.id,
            label: i.shortName,
            description: description.padEnd(pad, '...'),
            color: i.color
          }
        })
    },
    shouldDisableComponent () {
      return this.disabled || (this.feature && this.instanceOptions.length === 0)
    },
    featureSatisfactionLevel () {
      return this.instanceOptions.length > 0 ? 'some_not_satisfied' : 'all_not_satisfied'
    },
    featureSatisfactionLevelData () {
      return featureSatisfactionLevels[this.featureSatisfactionLevel]
    },
    featureLabel () {
      return prettyFeatureLabel(this.feature)
    }
  },
  mounted () {
    const { instance } = this.$route.query
    if (instance) {
      setTimeout(() => this.$hub.$emit(this.eventKey, instance))
      this.$emit('input', instance)
    } else if (this.autoSelectInstance && !this.value) {
      this.select(this.selectedInstance || this.getActiveInstances[0].id)
    }
  },
  methods: {
    generateSelectData (item, type) {
      return get(item, ['selectedOption', [0], type], '') || get(item, ['item', type], '')
    },
    select (event) {
      setTimeout(() => this.$hub.$emit(this.eventKey, event))
      this.$store.commit(types.SELECTED_INSTANCE, event)
      this.$emit('input', event)
    }
  }
}
</script>
<style scoped lang="scss">
  .feature-icon {
    &.icon-st_warn {
      color: #ff8400;
    }

    &.icon-st_error {
      color: rgb(255, 60, 60);
    }
  }

  .instance-select {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      flex-grow: 1;
    }

    .ui-select {
      padding-left: 7px !important;

      .instance-option {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 10px;
        align-items: center;

        .label {
          width: fit-content;
          height: 20px;
          padding: 0 7px;
          margin: 0 0 0 10px;
          font-size: 13.5px;
          font-weight: bold;
          line-height: 20px;
          color: #fff;
          text-align: center;
          border-radius: 10px;
        }

        &.selected {
          line-height: 27px !important;

          .label {
            margin: 0 auto;
          }
        }
      }
    }
  }

  .label-text {
    display: flex !important;
    margin-bottom: 5px !important;
  }
</style>
