import { each } from '@/utils/lodash'
import { toISO8601, subtractDate } from '@/utils/helpers'

const utils = {
  start: function (dateTimeInterval = 24) {
    return toISO8601(subtractDate(dateTimeInterval, 'hours'))
  },
  end: function () {
    return toISO8601()
  }
}

export default class GraphDataRequest {
  constructor (ApiMethod) {
    this._apiMethod = ApiMethod
    this._options = null
  }

  prepare (instance, params) {
    const options = { hosts: [instance] }

    each(this.apiMethod.parameters, p => {
      if (p.required && utils[p.name]) {
        options[p.name] = utils[p.name]()
      }
    })

    this.options = { ...options, ...params }
  }

  get options () {
    return this._options
  }

  set options (value) {
    this._options = value
  }

  get apiMethod () {
    return this._apiMethod
  }

  set apiMethod (value) {
    this._apiMethod = value
  }
}
