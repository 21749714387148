import Type from './types'
import parseEvents from '@/utils/parseEvents'
import { set } from '@/utils/lodash'

const pollingTaskTypeHandlers = {
  events: (state, data) => {
    const { eventType, instanceId, ...rest } = data

    const events = parseEvents(rest)

    set(state, ['events', eventType, instanceId], events)
  },
  criticalIssues: (state, data) => {
    const { instanceId, ...rest } = data

    set(state, ['warnings', 'all', instanceId, 'warnings'], rest)
  }
}

export default {
  [Type.EVENTS] (state, { data, type }) {
    const events = parseEvents(data)

    state.events[type] = events
  },
  [Type.WARNINGS] (state, issue) {
    state.warnings.all = issue
  },
  [Type.UPDATE_POLLING_TASK] (state, { type, data }) {
    pollingTaskTypeHandlers[type] && pollingTaskTypeHandlers[type](state, data)
  }
}
