import { getDomain } from './request'
export const irp_delete_RAW_URL = () => '/irp/{section}'

export const irp_delete_URL = (parameters = {}) => {
  let path = irp_delete_RAW_URL()
  let query = ''

  path = path.replace('{section}', `${parameters.section}`)
  return getDomain() + path + query
}
