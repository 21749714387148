const InstancesEventsBase = () => import(/* webpackChunkName: "InstancesEventsBase" */ '@/views/InstancesEvents/InstancesEventsBase')
const CriticalIssues = () => import(/* webpackChunkName: "CriticalIssues" */ '@/views/InstancesEvents/CriticalIssues')
const SystemEvents = () => import(/* webpackChunkName: "SystemEvents" */ '@/views/InstancesEvents/SystemEvents')

export default function () {
  return {
    path: '/instances-events',
    name: 'InstancesEvents',
    component: InstancesEventsBase,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      menu: false,
      crumbName: 'System Events'
    },
    children: [
      {
        path: '/instances-events/critical-issues',
        name: 'Critical Issues',
        component: CriticalIssues,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Instances',
          crumbMenu: false
        }
      },
      {
        path: '/instances-events/system-events',
        name: 'System Events',
        component: SystemEvents,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          menu: false,
          crumbName: 'Instances',
          crumbMenu: false
        }
      }
    ]
  }
}
