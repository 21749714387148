import { satisfies, minVersion } from 'semver'
import { capitalize } from '@/utils/lodash'

const features = {
  reports: '>= 3.9.*',
  graphs: '>= 3.9.*',
  troubleshooting: '>= 3.9.*',
  globalGroups: '>= 3.10.*',
  inbound: '>= 3.11.5',
  blackholing: '> 3.9.0',
  settings: '> 3.11.0',
  threatmitigation: '> 4.0.1', // 4.0.2 change before release
  historicalinbound: '> 4.1.2'
}

const featureSatisfactionLevels = {
  // no feature is satisfied
  all_not_satisfied: {
    colorClass: 'text-error-500',
    icon: 'st_error'
  },
  // some features are not satisfied
  some_not_satisfied: {
    colorClass: 'text-primary-500',
    icon: 'st_warn'
  },
  // all features are satisfied
  all_satisfied: {
    colorClass: 'text-success-500',
    icon: 'st_ok'
  }
}
/**
 * Returns a pretty String to be displayed on UI
 *
 * @param {String} feature
 *
 * @returns {String}
 */
export const prettyFeatureLabel = feature => {
  return {
    globalGroups: 'Global Groups',
    threatmitigation: 'Threat Mitigation',
    inbound: 'Inbound Optimization',
    historicalinbound: 'Historical Inbound'
  }[feature] ?? capitalize(feature)
}

/**
 * Checks if the provided feature is supported
 *
 * @param {String} feature
 * @param {String} version
 *
 * @returns {Boolean}
 */
const supported = (feature, version) => (
  typeof features[feature] !== 'undefined' && satisfies(version, features[feature])
)

/**
 * Returns the lowest version supported by feature
 *
 * @param {String} feature
 *
 * @returns {SemVer}
 */
const lowestVersion = feature => {
  if (!features[feature]) {
    throw new Error(`Unknown feature "${feature}" provided`)
  }

  return minVersion(features[feature])
}

export { features, featureSatisfactionLevels, supported, lowestVersion }
