export default {
  page: {
    name: 'page',
    in: 'query',
    schema: { type: 'number', default: 1 },
    camelCaseName: 'page'
  },
  order: {
    name: 'order',
    in: 'query',
    schema: { type: 'string', enum: ['ASC', 'DESC'], default: 'DESC' },
    camelCaseName: 'order'
  },
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  asname: {
    in: 'query',
    description: 'AS name',
    name: 'asname',
    'x-ui': { dictionary: 'textSearch', component: 'ui-filter-autocomplete', props: { placeholder: 'Any AS name' } },
    schema: { type: 'string' },
    camelCaseName: 'asname'
  },
  asnumber: {
    in: 'query',
    name: 'asnumber',
    description: 'AS number',
    'x-ui': { dictionary: 'textSearch', component: 'ui-filter-autocomplete', props: { placeholder: 'Any AS number' } },
    schema: { type: 'number' },
    camelCaseName: 'asnumber'
  },
  prefix: {
    in: 'query',
    name: 'prefix',
    description: 'Prefix/IP',
    'x-ui': { component: 'ui-filter-autocomplete', dictionary: 'prefixSearch', props: { placeholder: 'Any Prefix/IP' } },
    schema: { type: 'string' },
    camelCaseName: 'prefix'
  },
  prefixSize: {
    name: 'prefixSize',
    in: 'query',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Prefix size' } },
    schema: { 'x-if-then': 'ipVer', type: 'number', allOf: [{ type: 'number', 'x-then': false, errorMessage: 'Out of range 8 - 24 32 - 48', oneOf: [{ minimum: 8, maximum: 24 }, { minimum: 32, maximum: 48 }] }, { type: 'number', minimum: 8, maximum: 24, errorMessage: 'Out of range 8 - 24', 'x-then': 4 }, { type: 'number', minimum: 32, maximum: 48, errorMessage: 'Out of range 32 - 48', 'x-then': 6 }] },
    description: 'Prefix size',
    camelCaseName: 'prefixSize'
  },
  ipVer: {
    name: 'ipVer',
    in: 'query',
    description: 'IPv4/IPv6',
    'x-ui': { dictionary: 'ipVersions', component: 'ui-filter-select', props: { placeholder: 'Any IP version' } },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'ipVer'
  },
  month: {
    in: 'query',
    name: 'month',
    description: 'Month for current year',
    'x-ui': { dictionary: 'monthsYear', component: 'ui-filter-select-month-year' },
    schema: { type: 'string' },
    camelCaseName: 'month'
  },
  year: {
    name: 'year',
    in: 'query',
    schema: { type: 'string' },
    camelCaseName: 'year'
  },
  sortBy: {
    name: 'sortBy',
    in: 'query',
    schema: { type: 'string', default: 'cntrerouted' },
    camelCaseName: 'sortBy'
  },
  country: {
    name: 'country',
    in: 'query',
    schema: { type: 'string', dictionary: 'countries' },
    description: 'Country',
    example: 'Any country',
    camelCaseName: 'country'
  },
  pageSize: {
    name: 'pageSize',
    in: 'query',
    description: 'Number of records',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Results per page' } },
    schema: { type: 'number', default: 20, allOf: [{ type: 'number', minimum: 1, errorMessage: 'must be > 0' }, { type: 'number', maximum: 300, errorMessage: 'must be < 300' }] },
    camelCaseName: 'pageSize'
  }
}
