import types from './types'

const maxNotifications = 1

const state = {
  all: [],
  routesNeedingAttention: [],
  tabIsActive: true
}

const mutations = {
  [types.ADD_NOTIFICATION] (state, notification) {
    state.all.push(notification)
    if (state.all.length > maxNotifications) {
      state.all.shift()
    }
  },
  [types.DELETE_NOTIFICATION] (state, notification) {
    state.all.forEach(n => {
      if (n.text === notification.text && n.title === notification.title) {
        state.all.splice(state.all.indexOf(n), 1)
      }
    })
  },
  [types.ADD_ROUTE_NEEDING_ATTENTION] (state, route) {
    if (!state.routesNeedingAttention.find(r => r.path === route.path)) {
      state.routesNeedingAttention.push(route)
    }
  },
  [types.REMOVE_ROUTE_NEEDING_ATTENTION] (state, { path }) {
    state.routesNeedingAttention.forEach((route, i) => {
      if (route.path.indexOf(path) === 0) {
        state.routesNeedingAttention.splice(i, 1)
      }
    })
  },
  [types.SOCKET_XHR_REQUEST_STATUS]: (state, data) => {
    state.all.push({
      _id: data._id,
      event: data.event,
      text: data.message,
      type: data.status > 200 ? 'error' : 'success'
    })
    if (state.all.length > maxNotifications) {
      state.all.shift()
    }
  },
  [types.TAB_IS_ACTIVE] (state, tabIsActive) {
    state.tabIsActive = tabIsActive
  }
}

export default {
  state,
  mutations
}
