import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: getTopVolumeAS
 * url: /irp/reports/topVolumeAS
 * @param page {  }
 * @param order { String }
 * @param hosts { Array }
 * @param asname { String } AS name
 * @param asnumber {  } AS number
 * @param start { String } Date and time start in the form (YYYY-MM-DD HH:MM:SS)
 * @param end { String } Date and time start in the form (YYYY-MM-DD HH:MM:SS)
 * @param sortBy { String }
 * @param pageSize {  } Number of records
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/reports/topVolumeAS'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.page !== undefined) {
    queryParameters.page = parameters.page
  } else if (parameters.page === undefined) {
    queryParameters.page = 1
  }
  if (parameters.order !== undefined) {
    queryParameters.order = parameters.order
  } else if (parameters.order === undefined) {
    queryParameters.order = 'DESC'
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.asname !== undefined) {
    queryParameters.asname = parameters.asname
  } if (parameters.asnumber !== undefined) {
    queryParameters.asnumber = parameters.asnumber
  } if (parameters.start !== undefined) {
    queryParameters.start = parameters.start
  } if (parameters.end !== undefined) {
    queryParameters.end = parameters.end
  } if (parameters.sortBy !== undefined) {
    queryParameters.sortBy = parameters.sortBy
  } else if (parameters.sortBy === undefined) {
    queryParameters.sortBy = 'sumvol'
  }
  if (parameters.pageSize !== undefined) {
    queryParameters.pageSize = parameters.pageSize
  } else if (parameters.pageSize === undefined) {
    queryParameters.pageSize = 20
  }

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
