<template>
  <div class="w-1/2">
    <graphs-list-sections
      title="Network performance overview"
      icon-color="#ff8400"
      path="graphsTrial"
      icon="quick"
      :list="charts"
    />
  </div>
</template>
<script>
import GraphsListSections from '@/views/Graphs/GraphsListSections'
import graphsTrial from '@/constants/graphsTrial.json'

export default {
  name: 'GraphsTrialList',
  components: { GraphsListSections },
  data () { return { keyword: '' } },
  computed: {
    charts () { return graphsTrial }
  }
}
</script>
