import AreaOverview from '@/components/graphs/types/AreaOverview'

export default () => ({
  ...AreaOverview(),
  yAxis: [{ // Primary yAxis
    title: {
      text: ''
    },
    labels: {
      enabled: false
    },
    gridLineWidth: 0
  }, { // Secondary yAxis
    title: {
      text: ''
    },
    labels: {
      enabled: false
    },
    gridLineWidth: 0,
    opposite: true
  }]
})
