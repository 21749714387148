import { getDomain } from './request'
export const saved_report_download_RAW_URL = () => '/saved-reports/{id}/download'

export const saved_report_download_URL = (parameters = {}) => {
  let path = saved_report_download_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
