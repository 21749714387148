const GraphsBase = () => import(/* webpackChunkName: "GraphsBase" */ '@/views/Graphs/GraphsBase')
const GraphsList = () => import(/* webpackChunkName: "GraphsList" */ '@/views/Graphs/GraphsList')
const GraphsPage = () => import(/* webpackChunkName: "GraphsPage" */ '@/views/Graphs/GraphsPage')

export default function () {
  return {
    path: '/graphs',
    meta: {
      menu: true,
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-graphs',
      crumbName: 'Graphs',
      hotkey: 'SHIFT + G'
    },
    component: GraphsBase,
    children: [
      {
        path: '',
        name: 'GraphList',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'All Graphs' },
        component: GraphsList
      },
      {
        path: ':name',
        name: 'Graph',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Chart' },
        component: GraphsPage
      }
    ]
  }
}
