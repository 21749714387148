export default {
  id: {
    in: 'path',
    name: 'id',
    schema: { type: 'string' },
    required: true,
    camelCaseName: 'id'
  },
  confirm: {
    in: 'query',
    name: 'confirm',
    schema: { type: 'boolean' },
    camelCaseName: 'confirm'
  }
}
