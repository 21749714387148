import { getDomain } from './request'
export const getCountryStatistics_RAW_URL = () => '/irp/reports/countryStatistics'

export const getCountryStatistics_URL = (parameters = {}) => {
  let path = getCountryStatistics_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.year !== undefined) {
    queryParameters.year = parameters.year
  }
  if (parameters.sortBy !== undefined) {
    queryParameters.sortBy = parameters.sortBy
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
