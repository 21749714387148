import { theme } from '@/components/graphs/types/Theme'
import { merge } from 'highcharts'

export default () => merge(true, theme(), {
  chart: {
    type: 'column'
  },
  boost: { seriesThreshold: 5000 },
  xAxis: {
    categories: []
  },
  yAxis: {
    min: 0,
    minRange: 0.05,
    title: {
      text: ''
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color: 'gray'
      }
    }
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y} ms'
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      minPointLength: 5,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.y + ' ms'
        }
      }
    }
  },
  series: []
})
