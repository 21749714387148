import actions from './actions'
import mutations from './mutations'

const state = {
  beams: []
}

const getters = {
  allBeams: state => state.beams
}

export default {
  state,
  getters,
  mutations,
  actions
}
