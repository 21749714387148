import { getDomain } from './request'
export const beams_add_RAW_URL = () => '/beams/{namespace}'

export const beams_add_URL = (parameters = {}) => {
  let path = beams_add_RAW_URL()
  let query = ''

  path = path.replace('{namespace}', `${parameters.namespace}`)
  return getDomain() + path + query
}
