import { get, result, set } from '@/utils/lodash'
import axios, { CancelToken } from 'axios'
import * as API from '@/api/methods'

export default class ApiMethod {
  constructor (method) {
    this._loading = false
    this._method = method
    this._source = null
    if (this._method) {
      this._methodFn = get(API, this._method, false)
    }

    if (this._methodFn === false) {
      throw new Error(`Method "${method}" doesn't exist in api, or is not provided in constructor!`)
    }
  }

  /**
   * @param parameters
   * @returns Promise
   */
  fetch (parameters) {
    if (this._methodFn && (typeof this._methodFn !== 'function')) {
      return Promise.reject(new Error(`Method "${this._method}" doesn't exist in api, or is not provided in constructor!`))
    }

    this.loading = true
    this._source = CancelToken.source()
    set(parameters, '$config.cancelToken', this._source.token)
    return this._methodFn(parameters)
      .then(response => {
        this._source = null
        if (response && response.status !== 200) {
          throw new Error('Something goes wrong!')
        } else {
          this.loading = false
          return response
        }
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          error.message = 'Request canceled...'
        }
        this._source = null
        this.loading = false
        throw error
      })
  }

  get parameters () {
    return get(API, this.method + '_PARAMETERS', {})
  }

  get meta () {
    return get(API, this.method + '_META')
  }

  get loading () {
    return this._loading
  }

  set loading (loading) {
    this._loading = loading
  }

  get method () {
    return this._method
  }

  set method (value) {
    this._method = value
  }

  cancel () {
    result(this, '_source.cancel')
  }
}
