export default {
  methods: {
    instanceStatusColor (status) {
      switch (status) {
        case 'active': return 'text-success-500'
        case 'unauthorized': return 'text-primary-500'
        case 'unreachable': return 'text-error-500'
        default: return 'text-darkgray-300'
      }
    }
  }
}
