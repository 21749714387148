import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: widgets_delete
 * url: /dashboard/{idDashboard}/widgets/{idWidget}
 * @param idDashboard { String }
 * @param idWidget { String }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/dashboard/{idDashboard}/widgets/{idWidget}'
  let body
  let queryParameters = {}
  let form = {}

  if (parameters.idDashboard === undefined) {
    return Promise.reject(new Error('Missing required String parameter: idDashboard'))
  }
  path = path.replace('{idDashboard}', `${parameters.idDashboard}`)
  if (parameters.idWidget === undefined) {
    return Promise.reject(new Error('Missing required String parameter: idWidget'))
  }
  path = path.replace('{idWidget}', `${parameters.idWidget}`)

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('delete', domain + path, body, queryParameters, form, config)
}
