import colors from '@/constants/colors'

export const theme = () => ({
  chart: {
    animation: { duration: 250 },
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: null,
    resetZoomButton: {
      relativeTo: 'plotBox',
      theme: {
        fill: 'var(--chart-reset-button-background-color, #e3e3e3)',
        r: 3,
        states: {
          hover: {
            fill: 'var(--chart-reset-button-background-color, #e3e3e3)',
            style: {
              opacity: 0.8
            }
          }
        },
        stroke: 'rgba(255, 255, 255, 0)',
        style: {
          color: 'var(--chart-reset-button-text-color, #666565)',
          fontSize: '11px',
          fontWeight: 600,
          opacity: 1
        }
      }
    },
    style: {
      fontFamily: 'Open Sans'
    }
  },
  colors,
  credits: { enabled: false },
  plotOptions: {
    series: {
      dataLabels: {
        style: {
          color: 'var(--secondary-color)',
          textOutline: 'transparent'
        }
      }
    },
    column: {
      dataLabels: {
        style: {
          color: 'var(--secondary-color)',
          fontSize: '12px',
          fontFamily: 'Open Sans',
          fontWeight: '600'
        }
      }
    }
  },
  legend: {
    itemStyle: {
      color: 'rgba(var(--secondary-color-1), var(--secondary-color-opacity, .5))'
    },
    itemHoverStyle: {
      color: 'var(--secondary-color)'
    },
    navigation: {
      activeColor: 'rgba(var(--secondary-color-1), .5)',
      inactiveColor: 'rgba(var(--secondary-color-1), .1)',
      style: {
        color: 'var(--secondary-color)'
      }
    },
    useHTML: true
  },
  subtitle: { text: '' },
  title: {
    text: '',
    style: {
      color: 'var(--chart-axis-labels-color, #666666)'
    }
  },
  tooltip: {
    backgroundColor: 'var(--primary-accent-color, rgba(0, 0, 0, 0.85))',
    shadow: false,
    style: { color: 'var(--secondary-color, #F0F0F0)' }
  },
  xAxis: {
    labels: {
      style: {
        color: 'var(--chart-axis-labels-color, #666666)'
      }
    },
    lineColor: 'var(--chart-axis-color, #ccd6eb)',
    tickColor: 'var(--chart-axis-color, #ccd6eb)'
  },
  yAxis: {
    gridLineColor: 'var(--chart-grid-color, #e6e6e6)',
    labels: {
      style: {
        color: 'var(--chart-axis-labels-color, #666666)'
      }
    },
    title: {
      style: {
        color: 'var(--chart-axis-labels-color, #666666)',
        fontWeight: 700
      }
    }
  }
})
