import types from './types'
import { users_list, users_create, users_update, users_delete } from '@/api/methods'
import { get } from '@/utils/lodash'

export default {
  fetchUsers ({ commit }) {
    return users_list()
      .then(response => {
        commit(types.USERS, get(response, 'data', []))
        return get(response, 'data', [])
      })
  },

  createUser ({ commit, getters, dispatch }, body) {
    return users_create({ body })
      .then(response => {
        const user = get(response, 'data', [])
        commit(types.ADD_USER, user)
        return user
      })
  },

  updateUser ({ commit, getters, dispatch }, body) {
    return users_update({ body, id: body.id }, { commit, getters, dispatch })
      .then(response => {
        const user = get(response, 'data', [])
        commit(types.UPDATE_USER, user)
        return user
      })
  },

  deleteUser ({ commit, getters, dispatch }, user) {
    return users_delete(user, { commit, getters, dispatch })
      .then(() => commit(types.REMOVE_USER, user))
  },

  fetchUserValidationSchema (_, model) {
    const parameters = model.id
      ? { body: {}, id: model.id, $queryParameters: { schema: 'body' } }
      : { body: {}, $queryParameters: { schema: 'body' } }

    return model.id ? users_update(parameters) : users_create(parameters)
  }
}
