import * as callbacks from '../utils/graphsCallbacks'
import Area from '../types/Area'
import DualArea from '../types/DualArea'
import AreaOverview from '../types/AreaOverview'
import DualAreaOverview from '../types/DualAreaOverview'
import Bar from '../types/Bar'
import FixedNegativeBar from '@/components/graphs/types/FixedNegativeBar'
import NegativeBar from '@/components/graphs/types/NegativeBar'
import Pie from '../types/Pie'
import Stacked from '../types/Stacked'
import StackedPercentage from '../types/StackedPercentage'
import SyncGraph from '../types/SyncGraph'
import StackedBar from '@/components/graphs/types/StackedBar'
import AreaPercentage from '@/components/graphs/types/AreaPercentage'
import { merge } from '@/utils/lodash'
import {
  drawBandwidthUsagePerProvider,
  drawTotalAndImprovedTrafficStats,
  drawTotalBandwidthStats
} from '@/components/graphs/utils/statsDrawers'

export default class GraphEngine {
  constructor (props) {
    this._callback = props.callback
    this._type = props.type.toLowerCase()
    this._error = ''
    this._overviewGraph = null
    this._otherDetails = props

    this._graph = this.graphConfiguration(this._type)
  }

  graphConfiguration (type = 'area') {
    let template

    switch (type) {
      case 'area':
        template = Object.assign({}, Area())
        break
      case 'dualarea':
        template = Object.assign({}, DualArea())
        break
      case 'bar':
        template = Object.assign({}, Bar())
        break
      case 'pie':
        template = Object.assign({}, Pie())
        break
      case 'stacked':
        template = Object.assign({}, Stacked())
        break
      case 'stackedbar':
        template = Object.assign({}, StackedBar())
        break
      case 'stackedpercentage':
        template = Object.assign({}, StackedPercentage())
        break
      case 'negativebar':
        template = Object.assign({}, NegativeBar())
        break
      case 'customnegativebar':
        template = Object.assign({}, NegativeBar())
        break
      case 'fixednegativebar':
        template = Object.assign({}, FixedNegativeBar())
        break
      case 'syncgraph':
        template = Object.assign({}, SyncGraph())
        break
      case 'areapercentage':
        template = Object.assign({}, AreaPercentage())
        break
      default:
        template = Object.assign({}, Area())
        break
    }

    return template
  }

  async parse (data, host, parameters, store, vm) {
    this.error = ''
    if (!data) return new Error('Provide data for parsing')
    let processed = {}

    if (this._callback) {
      // process graph data based on callback option
      processed = await callbacks[this._callback](data, this._type, this._otherDetails.keys, host, parameters, store, vm)

      // overwrite default with processed data
      this.graph = merge(this.graphConfiguration(this._type), processed)
    }

    // optional actions
    this.optionalActions(this._type, processed)

    // draw stats
    if (this._otherDetails.hasOwnProperty('api')) {
      // Temporary solution for Global Commit Graph
      this.drawStatsController(this._otherDetails.api._method, this.graph.series, vm)
    }

    if (this.error) return new Error(this.error)
  }

  optionalActions (type, processed = {}) {
    if (type === 'bar') {
      if (this.graph.series.every(series => series.data[0] === 0)) return (this.error = 'No sufficient data')
      if (this.graph.series.length > 2) this.drawAdditionalLine(this.graph.series) // TODO: needs refactoring
    } else if (type === 'area' || type === 'areapercentage' || type === 'customlistgraphsarea') {
      this.overviewGraph = { ...Object.assign({}, AreaOverview()), ...processed }
    } else if (type === 'dualarea') {
      this.overviewGraph = { ...Object.assign({}, DualAreaOverview()), ...processed }
    }
  }

  drawAdditionalLine (series) {
    if (series[1].data.length && series[1].data[0]) {
      this.graph.chart.events = {
        render: function (e) {
          if (e.target.series.length) {
            const plotTop = e.target.plotTop
            const plotLeft = e.target.plotLeft
            const pointXOffset = e.target.series[0].pointXOffset
            const borderWidth = e.target.series[0].borderWidth
            const strokeAttributes = { 'stroke-width': 2, 'stroke-dasharray': 7, stroke: '#cccccc', class: 'guide-lines' }
            const oldLines = document.querySelectorAll('.guide-lines')
            const drawingLine = () => {
              if (oldLines.length) oldLines.forEach(el => el.remove())
              // Vertical Line
              this.renderer && this.renderer.path(['M',
                e.target.chartWidth - e.target.marginRight - (borderWidth / 2).toFixed(0),
                e.target.plotHeight / 2 - pointXOffset + plotTop + borderWidth,
                'V',
                e.target.plotHeight / 2 + pointXOffset + plotTop])
                .attr(strokeAttributes)
                .add()

              // Curved line
              this.renderer && this.renderer.path(['M',
                e.target.chartWidth - e.target.marginRight - e.target.series[0].points[0].shapeArgs.height + borderWidth,
                e.target.plotHeight / 2 - pointXOffset + plotTop + borderWidth,

                e.target.chartWidth - e.target.marginRight - e.target.series[0].points[0].shapeArgs.height + borderWidth,
                e.target.plotHeight / 2 + pointXOffset / 2,

                plotLeft + borderWidth,
                e.target.plotHeight / 2 + pointXOffset / 2,

                plotLeft + borderWidth,
                e.target.plotHeight / 2 + pointXOffset + plotTop])
                .attr(strokeAttributes)
                .add()
            }
            setTimeout(drawingLine, 1000)
          }
        }
      }
    }
  }

  drawStatsController (apiMethod, series, vm) {
    const needToDrawStats = {
      getTotalBandwidthUsage: drawTotalBandwidthStats,
      getTotalAndImprovedTraffic: drawTotalAndImprovedTrafficStats,
      bandwidthUsagePerProvider: drawBandwidthUsagePerProvider
    }

    if (!needToDrawStats.hasOwnProperty(apiMethod) && vm.$route.params.name !== 'bandwidthUsagePerProvider') return

    if (apiMethod !== 'getBandwidthUsageTowardsImprovements') {
      const drawStatsFunction = needToDrawStats[apiMethod]
      drawStatsFunction.call(this, series)
    } else {
      const drawStatsFunction = needToDrawStats[vm.$route.params.name]
      drawStatsFunction.call(this, series)
    }
  }

  get graph () {
    return this._graph
  }

  set graph (value) {
    this._graph = value
  }

  get overviewGraph () {
    return this._overviewGraph
  }

  set overviewGraph (value) {
    this._overviewGraph = value
  }

  get error () {
    return this._error
  }

  set error (value) {
    this._error = value
  }
}
