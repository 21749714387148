import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: events_list
 * url: /events
 * @param instance { String } Instance
 * @param user { String } User
 * @param type { String } Event type
 * @param scope { String } Scope
 * @param start { String } From date
 * @param end { String } To date
 * @param page {  } Page
 * @param pageSize {  } Page size
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/events'
  let body
  let queryParameters = {}
  let form = {}

  if (parameters.instance !== undefined) {
    queryParameters.instance = parameters.instance
  } if (parameters.user !== undefined) {
    queryParameters.user = parameters.user
  } if (parameters.type !== undefined) {
    queryParameters.type = parameters.type
  } if (parameters.scope !== undefined) {
    queryParameters.scope = parameters.scope
  } else if (parameters.scope === undefined) {
    queryParameters.scope = 'config'
  }
  if (parameters.start !== undefined) {
    queryParameters.start = parameters.start
  } if (parameters.end !== undefined) {
    queryParameters.end = parameters.end
  } if (parameters.page !== undefined) {
    queryParameters.page = parameters.page
  } if (parameters.pageSize !== undefined) {
    queryParameters.pageSize = parameters.pageSize
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
