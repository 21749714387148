import schema from '@/services/schema'
import { groupsParametersMapping as gpm, providerParameterMappings as ppm } from '@/components/config/data/parameterMappings'
import { each, omit } from '@/utils/lodash'
import { getSectionSchema } from '@/components/config/utils/configuration'

export default {
  groupsDiff (oldGroups, newGroups) {
    const operations = []
    newGroups.forEach(newGroup => {
      const oldGroup = oldGroups.find(g => g.id === newGroup.id && g.instanceId === newGroup.instanceId)
      if (!oldGroup) {
        operations.push({ type: 'create', priority: 100, entity: 'GlobalGroup', id: newGroup.id, data: { GlobalGroup: [{ id: newGroup.id, parameters: omit(newGroup, ['id', 'instanceId', 'instances']) }] }, instanceId: newGroup.instanceId })
      }
    })
    oldGroups.forEach(oldGroup => {
      const newGroup = newGroups.find(g => g.id === oldGroup.id && g.instanceId === oldGroup.instanceId)
      if (newGroup && !this.groupUnchanged(newGroup, oldGroup)) {
        operations.push({ type: 'update', priority: 90, entity: 'GlobalGroup', id: oldGroup.id, data: { GlobalGroup: [{ id: newGroup.id, parameters: omit(newGroup, ['id', 'instanceId', 'instances']) }] }, instanceId: newGroup.instanceId })
      }
    })
    oldGroups.forEach(oldGroup => {
      const newGroup = newGroups.find(g => g.id === oldGroup.id && g.instanceId === oldGroup.instanceId)
      if (!newGroup) {
        operations.push({ type: 'delete', priority: 30, entity: 'GlobalGroup', id: oldGroup.id, instanceId: oldGroup.instanceId })
      }
    })
    return operations
  },
  spliceGroups (mergedGroups) {
    const groups = []
    mergedGroups.forEach(mergedGroup => {
      each(mergedGroup.instances, (group, instanceId) => {
        groups.push({
          id: mergedGroup.id,
          instanceId,
          [gpm.shortName]: mergedGroup[gpm.shortName],
          [gpm.members]: mergedGroup[gpm.members],
          [gpm.outboundRateLow]: mergedGroup[gpm.outboundRateLow],
          [gpm.outboundRateHigh]: mergedGroup[gpm.outboundRateHigh],
          [gpm.outbound95th]: group[gpm.outbound95th],
          [gpm.outbound95thReserve]: group[gpm.outbound95thReserve]
        })
      })
    })
    return groups
  },
  validate (group) {
    const currentSchema = getSectionSchema('GlobalGroup')
    let valid = true
    for (const parameter in group) {
      if (currentSchema.properties[parameter]) {
        const validate = schema.currentAjv.compile(currentSchema.properties[parameter])
        valid = valid && validate(group[parameter])

        if (!valid) {
          console.log('Invalid ', validate.errors)
        }
      }
    }
    return valid
  },
  providersDiff (oldProviders, newProviders) {
    const operations = []
    const createOperation = (priority, providerId, instanceId, groupId) => ({
      priority,
      instanceId,
      type: 'update',
      entity: 'Provider',
      id: providerId,
      data: {
        Provider: [{
          id: providerId,
          parameters: {
            [ppm.globalGroup]: groupId
          }
        }]
      }
    })

    oldProviders.forEach(oldProvider => {
      const newProvider = newProviders.find(p => p.id === oldProvider.id && p.instanceId === oldProvider.instanceId)
      if (newProvider && oldProvider && (newProvider[ppm.globalGroup] !== oldProvider[ppm.globalGroup])) {
        const priority = newProvider[ppm.globalGroup] === null ? 20 : 110
        if (oldProvider[ppm.globalGroup] !== null) {
          const operation = createOperation(priority - 10, newProvider.id, newProvider.instanceId, null)
          operations.push(operation)
        }

        const operation = createOperation(priority, newProvider.id, newProvider.instanceId, newProvider[ppm.globalGroup])
        operations.push(operation)
      }
    })
    return operations
  },
  sum95th (group, mergedGroups) {
    let sum = 0
    const mergedGroup = mergedGroups.find(g => g.id === group.id)
    for (const i in mergedGroup.instances) {
      sum += parseInt(mergedGroup.instances[i][gpm.outbound95th] || 0)
    }
    return sum
  },
  mergeGroups (groups) {
    const mergedGroups = []
    groups.forEach(group => {
      const matchingGroup = this.getMatchingGroup(group, mergedGroups)
      if (!matchingGroup) {
        mergedGroups.push({
          id: group.id,
          [gpm.shortName]: group[gpm.shortName],
          [gpm.members]: group[gpm.members],
          [gpm.outboundRateLow]: group[gpm.outboundRateLow],
          [gpm.outboundRateHigh]: group[gpm.outboundRateHigh],
          instances: {
            [group.instanceId]: {
              [gpm.outbound95th]: group[gpm.outbound95th],
              [gpm.outbound95thReserve]: group[gpm.outbound95thReserve]
            }
          }
        })
      } else {
        matchingGroup.instances[group.instanceId] = {
          [gpm.outbound95th]: group[gpm.outbound95th],
          [gpm.outbound95thReserve]: group[gpm.outbound95thReserve]
        }
      }
    })
    mergedGroups.sort((a, b) => a.id - b.id)
    return mergedGroups
  },
  getMatchingGroup (group, groups) {
    return groups.find(g => this.isEqual(group, g))
  },
  isEqual (groupA, groupB) {
    return groupA[gpm.shortName] === groupB[gpm.shortName] &&
      groupA.id === groupB.id &&
      groupA[gpm.outboundRateLow] === groupB[gpm.outboundRateLow] &&
      groupA[gpm.outboundRateHigh] === groupB[gpm.outboundRateHigh] &&
      this.arraysEqual(groupA[gpm.members], groupB[gpm.members])
  },
  groupUnchanged (groupA, groupB) {
    return groupA[gpm.shortName] === groupB[gpm.shortName] &&
      groupA.id === groupB.id &&
      groupA[gpm.outbound95th] === groupB[gpm.outbound95th] &&
      groupA[gpm.outbound95thReserve] === groupB[gpm.outbound95thReserve] &&
      groupA[gpm.outboundRateLow] === groupB[gpm.outboundRateLow] &&
      groupA[gpm.outboundRateHigh] === groupB[gpm.outboundRateHigh] &&
      this.arraysEqual(groupA[gpm.members], groupB[gpm.members])
  },
  arraysEqual (arr1, arr2) {
    if (arr1 === arr2) {
      return true
    }
    let result = arr1.length === arr2.length
    for (const i in arr1) {
      if (arr1[i] !== arr2[i]) {
        result = false
      }
    }
    return result
  }
}
