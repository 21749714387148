<template>
  <div class="afterTrial">
    <div class="flex mb-5 justify-between">
      <breadcrumbs
        :menu-items="charts"
        :custom-last-breadcrumb="graph.name"
        has-menu
      >
        <template #menu>
          <breadcrumbs-menu :reports="charts" />
        </template>
      </breadcrumbs>
      <instance-select
        v-model="instanceId"
        class="float-right"
        auto-select-instance
      />
    </div>

    <ui-card>
      <template #header>
        <div class="noction-card-heading">
          <div class="title">
            <span
              class="pr-3"
              v-text="graph.name"
            />
            <instance-shortname
              :instance="currentInstance"
              type="color"
            />
          </div>
        </div>
      </template>
      <template #content>
        <div :key="instanceId">
          <graph
            ref="graph"
            :instance-id="instanceId"
            :api="api"
            :callback="graph.callback"
            :show-overview="true"
            :type="graph.graphType"
            :units="graph.units"
            :keys="graphKeys"
          />
        </div>
      </template>
    </ui-card>
  </div>
</template>

<script>
import InstanceShortname from '@/components/common/InstanceShortname'
import InstanceSelect from '@/components/common/InstanceSelect'
import Graph from '@/components/graphs/Graph'
import ApiMethod from '@/api/ApiMethod'
import graphs from '@/constants/graphsTrial.json'
import UiCard from 'uilib/src/Card'
import { mapGetters } from 'vuex'
import Breadcrumbs from '@/components/common/Breadcrumbs'
import BreadcrumbsMenu from '@/components/common/BreadcrumbsMenu'

export default {
  name: 'GraphsTrialPage',
  components: { Graph, UiCard, InstanceSelect, InstanceShortname, Breadcrumbs, BreadcrumbsMenu },
  data () {
    return {
      instanceId: ''
    }
  },
  computed: {
    ...mapGetters(['getInstanceById']),
    graph () { return graphs.find(i => i.slug === this.$route.params.name) },
    currentInstance () { return this.getInstanceById(this.instanceId) ?? {} },
    graphKeys () { return this.graph?.filters?.usedColumns ?? [] },
    api () { return new ApiMethod(this.graph.operationId) },
    charts () {
      return graphs.slice().sort((a, b) => a.name < b.name ? -1 : 1).map(c => ({ ...c, path: '/graphsTrial/' + c.slug }))
    }
  },
  watch: {
    instanceId () {
      setTimeout(() => {
        this.$refs.graph && this.$refs.graph.load()
      })
    }
  }
}
</script>

<style scoped>

</style>
