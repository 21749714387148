export default {
  instance: {
    in: 'query',
    name: 'instance',
    description: 'Instance',
    schema: { type: 'string' },
    camelCaseName: 'instance'
  },
  user: {
    in: 'query',
    name: 'user',
    schema: { type: 'string' },
    description: 'User',
    camelCaseName: 'user'
  },
  type: {
    in: 'query',
    name: 'type',
    schema: { type: 'string', enum: [{ label: 'Info', value: 'info' }] },
    description: 'Event type',
    camelCaseName: 'type'
  },
  scope: {
    in: 'query',
    name: 'scope',
    schema: { type: 'string', default: 'config', enum: [{ label: 'Config', value: 'config' }] },
    description: 'Scope',
    camelCaseName: 'scope'
  },
  start: {
    in: 'query',
    name: 'start',
    schema: { type: 'string', format: 'date-time' },
    description: 'From date',
    camelCaseName: 'start'
  },
  end: {
    in: 'query',
    name: 'end',
    schema: { type: 'string', format: 'date-time' },
    description: 'To date',
    camelCaseName: 'end'
  },
  page: {
    in: 'query',
    name: 'page',
    schema: { type: 'number', minimum: 1 },
    description: 'Page',
    camelCaseName: 'page'
  },
  pageSize: {
    in: 'query',
    name: 'pageSize',
    schema: { type: 'number', minimum: 1, maximum: 1000 },
    description: 'Page size',
    camelCaseName: 'pageSize'
  }
}
