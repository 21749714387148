export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  start: {
    name: 'start',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'start'
  },
  end: {
    name: 'end',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'end'
  },
  asnumber: {
    in: 'query',
    name: 'asnumber',
    description: 'AS number',
    'x-ui': { dictionary: 'textSearch', component: 'ui-filter-autocomplete', props: { placeholder: 'Any AS number' } },
    schema: { type: 'number' },
    camelCaseName: 'asnumber'
  }
}
