import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: tools_get_tasks
 * url: /tools/tasks
 * @param tool { String }
 * @param isHost { String }
 * @param offset {  }
 * @param limit {  }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/tools/tasks'
  let body
  let queryParameters = {}
  let form = {}

  if (parameters.tool !== undefined) {
    queryParameters.tool = parameters.tool
  } if (parameters.isHost !== undefined) {
    queryParameters.isHost = parameters.isHost
  } if (parameters.offset !== undefined) {
    queryParameters.offset = parameters.offset
  } if (parameters.limit !== undefined) {
    queryParameters.limit = parameters.limit
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
