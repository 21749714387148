import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: instances_check_status
 * url: /instances/check-status
 * @param host { String }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/instances/check-status'
  let body
  let queryParameters = {}
  let form = {}

  if (parameters.host === undefined) {
    return Promise.reject(new Error('Missing required String parameter: host'))
  }
  if (parameters.host !== undefined) {
    queryParameters.host = parameters.host
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
