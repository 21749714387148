import { getDomain } from './request'
export const getInboundPerformanceRuleAverageBandwidth_RAW_URL = () => '/irp/inboundperformance/rules/{id}/reports/averagebandwidth'

export const getInboundPerformanceRuleAverageBandwidth_URL = (parameters = {}) => {
  let path = getInboundPerformanceRuleAverageBandwidth_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
