import dashboard from '@/router/parts/dashboard'
import globalCommit from '@/router/parts/globalCommit'
import maintenanceWindow from '@/router/parts/maintenanceWindow'
import settings from '@/router/parts/settings'
import report from '@/router/parts/report'
import configuration from '@/router/parts/configuration'
import troubleshooting from '@/router/parts/troubleshooting'
import login from '@/router/parts/login'
import logout from '@/router/parts/logout'
import resetPassword from '@/router/parts/resetPassword'
import restricted from '@/router/parts/restricted'
import graph from '@/router/parts/graph'
import profile from '@/router/parts/profile'
import event from '@/router/parts/event'
import inbound from '@/router/parts/inbound'
import instanceEvent from '@/router/parts/instanceEvent'
import notFound from '@/router/parts/notFound'
import search from '@/router/parts/search'
import setup from '@/router/parts/setup'
import graphsTrial from '@/router/parts/graphsTrial'
import policies from '@/router/parts/policies'
import mitigation from '@/router/parts/mitigation'

export default [
  login(),
  logout(),
  resetPassword(),
  restricted(),
  dashboard(),
  report(),
  graph(),
  graphsTrial(),
  inbound(),
  ...policies(),
  troubleshooting(),
  mitigation(),
  globalCommit(),
  configuration(),
  maintenanceWindow(),
  search(),
  settings(),
  event(),
  instanceEvent(),
  profile(),
  notFound(),
  ...setup()
]
