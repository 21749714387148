import types from './types'

export default {
  [types.USERS] (state, users) {
    state.all = users
  },
  [types.ADD_USER] (state, user) {
    state.all.push(user)
  },
  [types.UPDATE_USER] (state, user) {
    const foundUser = state.all.find(i => i.id === user.id)
    state.all.splice(state.all.indexOf(foundUser), 1, user)
  },
  [types.REMOVE_USER] (state, user) {
    state.all.splice(state.all.indexOf(state.all.find(u => u.id === user.id)), 1)
  },
  [types.REMOVE_USER_INSTANCE] (state, instanceId) {
    state.all.forEach(u => {
      const foundIndex = u.instances.indexOf(instanceId)
      if (foundIndex !== -1) u.instances.splice(foundIndex, 1)
    })
  }
}
