import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: deleteInboundImprovement
 * url: /irp/inboundImprovement
 * @param hosts { Array }
 * @param prefixes { Array }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/inboundImprovement'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.prefixes !== undefined) {
    queryParameters.prefixes = parameters.prefixes
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('delete', domain + path, body, queryParameters, form, config)
}
