export default {
  start: {
    name: 'start',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'start'
  },
  end: {
    name: 'end',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'end'
  },
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  ipVer: {
    name: 'ipVer',
    in: 'query',
    description: 'IPv4/IPv6',
    'x-ui': { dictionary: 'ipVersions', component: 'ui-filter-select', props: { placeholder: 'Any IP version' } },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'ipVer'
  }
}
