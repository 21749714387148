import actions from './actions'
import mutations from './mutations'

const state = {
  all: []
}

const getters = {
  getUserNames: state => state.all.map(u => u.username),
  userWarning: state => state.all.some(u => u.instances.length === 0)
}

export default {
  state,
  getters,
  actions,
  mutations
}
