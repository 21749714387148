export default {
  page: {
    name: 'page',
    in: 'query',
    schema: { type: 'number', default: 1 },
    camelCaseName: 'page'
  },
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  start: {
    name: 'start',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'start'
  },
  end: {
    name: 'end',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'end'
  },
  pageSize: {
    name: 'pageSize',
    in: 'query',
    description: 'Number of records',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Results per page' } },
    schema: { type: 'number', default: 20, allOf: [{ type: 'number', minimum: 1, errorMessage: 'must be > 0' }, { type: 'number', maximum: 300, errorMessage: 'must be < 300' }] },
    camelCaseName: 'pageSize'
  },
  sortBy: {
    name: 'sortBy',
    in: 'query',
    schema: { type: 'string', default: 'ts' },
    camelCaseName: 'sortBy'
  },
  order: {
    name: 'order',
    in: 'query',
    schema: { type: 'string', enum: ['ASC', 'DESC'], default: 'DESC' },
    camelCaseName: 'order'
  },
  aspattern: {
    in: 'query',
    description: 'ASN Pattern',
    name: 'aspattern',
    'x-ui': { dictionary: 'patterns', component: 'ui-filter-input', props: { placeholder: 'Pattern' } },
    schema: { type: 'string' },
    camelCaseName: 'aspattern'
  },
  problem: {
    in: 'query',
    name: 'problem',
    description: 'By problem',
    'x-ui': { dictionary: 'problems', component: 'ui-filter-select' },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'problem'
  },
  problemstatus: {
    in: 'query',
    name: 'problemstatus',
    description: 'By Status',
    'x-ui': { dictionary: 'problemStatuses', component: 'ui-filter-select' },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'problemstatus'
  }
}
