export default {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  LOGOUT: 'LOGOUT',
  FETCH_PROFILE: 'FETCH_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  REMOVE_PROFILE: 'REMOVE_PROFILE',
  NEEDS_RE_AUTH: 'NEEDS_RE_AUTH',
  ACCESS_RESTRICTED: 'ACCESS_RESTRICTED',
  ADD_INSTANCE_TO_SYSTEM_DASHBOARD: 'ADD_INSTANCE_TO_SYSTEM_DASHBOARD',
  TOGGLE_SYSTEM_DASHBOARD: 'TOGGLE_SYSTEM_DASHBOARD',
  UPDATE_CURRENT_SYSTEM_DASHBOARD: 'UPDATE_CURRENT_SYSTEM_DASHBOARD',
  UPDATE_OUTDATED_VERSION: 'UPDATE_OUTDATED_VERSION',
  UPDATE_CURRENT_VERSION: 'UPDATE_CURRENT_VERSION',
  SSL_CERTIFICATE_EXPIRE: 'SSL_CERTIFICATE_EXPIRE',
  SCROLL_LOCK_UPDATE: 'SCROLL_LOCK_UPDATE',
  MEDIA_QUERY_THEME: 'MEDIA_QUERY_THEME'
}
