<template>
  <div id="app">
    <app-notifications />
    <vue-extend-layouts />
  </div>
</template>

<script>
import AppNotifications from '@/components/parts/AppNotifications'
import VueExtendLayouts from 'vue-extend-layout'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    AppNotifications,
    VueExtendLayouts
  },
  data () {
    return {
      media: null
    }
  },
  computed: {
    ...mapGetters(['getCurrentTheme'])
  },
  watch: {
    getCurrentTheme () {
      this.updateTheme()
    }
  },
  created () {
    this.media = window.matchMedia('(prefers-color-scheme: dark)')
    this.media.addListener(this.onMediaQueryChanged.bind(this))
    this.onMediaQueryChanged()
    this.updateTheme()
  },
  methods: {
    onMediaQueryChanged () {
      this.media.matches
        ? this.$store.commit('MEDIA_QUERY_THEME', 'dark')
        : this.$store.commit('MEDIA_QUERY_THEME', 'light')
    },
    updateTheme () {
      const theme = this.getCurrentTheme
      if (theme === 'dark') {
        document.documentElement.classList.add('dark')
      } else if (theme === 'light') {
        document.documentElement.classList.remove('dark')
      }
    }
  }
}
</script>

<style lang="scss">
  @import "assets/global";
  @import "assets/tooltip";
  @import "assets/dragula";
  @import "~uilib/style/icons.css";

  :root {
    --color-orange: #f6841f;
    --color-greyish: #454545;
    --red-color: #f05d5d;
    --green-color: #64c571;
    --blue-color: #499aea;
    --secondary-color-1: 69, 69, 69;
    --secondary-color-opacity: 0.5;

    /** input vars **/
    --select-border-color: #c9c9c9;
  }
</style>
