export default {
  page: {
    name: 'page',
    in: 'query',
    schema: { type: 'number', default: 1 },
    camelCaseName: 'page'
  },
  order: {
    name: 'order',
    in: 'query',
    schema: { type: 'string', enum: ['ASC', 'DESC'], default: 'DESC' },
    camelCaseName: 'order'
  },
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  asname: {
    in: 'query',
    description: 'AS name',
    name: 'asname',
    'x-ui': { dictionary: 'textSearch', component: 'ui-filter-autocomplete', props: { placeholder: 'Any AS name' } },
    schema: { type: 'string' },
    camelCaseName: 'asname'
  },
  asnumber: {
    in: 'query',
    name: 'asnumber',
    description: 'AS number',
    'x-ui': { dictionary: 'textSearch', component: 'ui-filter-autocomplete', props: { placeholder: 'Any AS number' } },
    schema: { type: 'number' },
    camelCaseName: 'asnumber'
  },
  start: {
    name: 'start',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'start'
  },
  end: {
    name: 'end',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'end'
  },
  pageSize: {
    name: 'pageSize',
    in: 'query',
    description: 'Number of records',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Results per page' } },
    schema: { type: 'number', default: 20, allOf: [{ type: 'number', minimum: 1, errorMessage: 'must be > 0' }, { type: 'number', maximum: 300, errorMessage: 'must be < 300' }] },
    camelCaseName: 'pageSize'
  },
  sortBy: {
    name: 'sortBy',
    in: 'query',
    schema: { type: 'string', default: 'volume' },
    camelCaseName: 'sortBy'
  }
}
