<template>
  <div
    v-if="crumbs.length"
    class="breadcrumbs"
    data-test="breadcrumbs"
  >
    <ul class="inline-block float-left p-0 m-0 list-none">
      <template v-for="(crumb, index) in crumbs">
        <li
          v-if="crumb.meta.crumbName"
          :key="crumb.name"
          class="inline-block font-semibold dark:text-white"
        >
          <router-link
            v-if="index < (crumbs.length - 1) || (index <= (crumbs.length - 1) && !hasMenu)"
            :to="crumb.path"
            :class="{ last: !hasMenu && crumbs.indexOf(crumb) === crumbs.length - 1 }"
          >
            {{ crumb.meta.crumbName }}
            <ui-icon
              v-if="!(!hasMenu && crumbs.indexOf(crumb) === crumbs.length - 1)"
              icon="c_right"
            />
          </router-link>
          <div
            v-else-if="(index === (crumbs.length - 1)) && hasMenu"
          >
            <VDropdown
              ref="popover"
              :placement="'bottom-start'"
              :distance="10"
              auto-hide
              @show="$emit('opened'); popoverVisible = true"
              @hide="$emit('closed'); popoverVisible = false"
            >
              <span class="cursor-pointer">
                {{ customLastBreadcrumb }}
                <dropdown-toggle :down="popoverVisible" />
              </span>

              <template #popper>
                <slot name="menu">
                  <router-link
                    v-for="item in menuItems"
                    :key="item.name"
                    class="breadcrumbs-menu-item"
                    :to="item.path"
                    @click="hideMenu"
                  >
                    {{ item.name }} <ui-icon
                      v-if="item.icon"
                      :icon="item.icon"
                    />
                  </router-link>
                </slot>
              </template>
            </VDropdown>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import UiIcon from 'uilib/src/Icon'
import { result } from '@/utils/lodash'
import DropdownToggle from './DropdownToggle'

export default {
  name: 'Breadcrumbs',
  components: {
    DropdownToggle,
    UiIcon
  },
  props: {
    hasNext: {
      type: Boolean,
      default: true
    },
    hasMenu: {
      type: Boolean,
      default: false
    },
    menuItems: {
      type: Array,
      default: () => []
    },
    customLastBreadcrumb: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      popoverVisible: false
    }
  },
  computed: {
    crumbs () {
      return this.$route.matched.filter(r => !!r.meta.crumbName)
    }
  },
  methods: {
    hideMenu () {
      result(this, '$refs.popover[0].hide')
    }
  }
}
</script>
<style lang="scss">
  .breadcrumbs {
    ul {
      li { font-size: 16px; }

      .noction-ui-icon {
        padding-left: 5px;
        font-size: 10px;
        color: #a2a2a2;
      }
    }
  }

  .v-popper--theme-dropdown {
    .v-popper__inner {
      .breadcrumbs-menu-group-container {
        max-width: 1000px;
        padding: 10px 0;
        overflow: auto;
        box-shadow:
          0 2px 4px -5px rgba(0, 0, 0, 0.02),
          0 4px 5px -2px rgba(0, 0, 0, 0.06),
          0 1px 10px 0 rgba(0, 0, 0, 0.02);

        .breadcrumbs-menu-group {
          float: left;
          padding: 5px 23px 15px 23px;

          &:first-of-type {
            padding: 5px 23px 15px 33px;
          }

          &:last-of-type {
            padding: 5px 33px 15px 23px;
          }

          .breadcrumbs-menu-group-list {
            max-height: 700px;

            ul {
              padding-inline-start: 0;

              li {
                .map {
                  padding-right: 5px;
                  font-size: 13px;
                }
              }
            }
          }

          &__name {
            padding: 10px 0;
            border-bottom: 2px solid rgba(#dedede, 1);

            &--improvements {
              color: #ff8400;
            }

            &--traffic {
              color: #59aacb;
            }
          }
        }
      }

      .breadcrumbs-menu-item {
        position: relative;
        display: block;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: -0.14px;

        &.router-link-exact-active {
          font-weight: 600;
          pointer-events: none;
          background-color: rgba(#515151, 0.15);

          &::before {
            background-color: rgba(#515151, 0.15);
          }
        }

        &:hover {
          background-color: #e4e4e4;

          &.breadcrumbs-menu-graph-item--improvements {
            background-color: rgba(#ff8400, 0.15);
          }

          &.breadcrumbs-menu-graph-item--traffic {
            background-color: rgba(#59aacb, 0.15);
          }
        }
      }
    }
  }

  .dark .v-popper--theme-dropdown .v-popper__inner .breadcrumbs-menu-item {
    &.router-link-exact-active,
    &:hover {
      background-color: rgba(#e4e4e4, 0.15);
    }
  }
</style>
