const ReportBase = () => import(/* webpackChunkName: "ReportBase" */ '@/views/Reports/ReportBase')
const ReportList = () => import(/* webpackChunkName: "ReportList" */ '@/views/Reports/ReportList')

export default function () {
  return {
    path: '/reports',
    component: ReportBase,
    meta: {
      menu: true,
      icon: 'icon-reports',
      requiredAuth: true,
      crumbName: 'Reports',
      hotkey: 'SHIFT + R',
      layout: 'LoggedLayout'
    },
    children: [
      {
        name: 'ReportList',
        path: '',
        component: ReportList,
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'All Reports' }
      },
      {
        path: ':name',
        component: () => import(/* webpackChunkName: "Report" */ '@/views/Reports/Report'),
        name: 'Report',
        meta: { menu: false, requiredAuth: true, layout: 'LoggedLayout', crumbName: 'Report' }
      }
    ]
  }
}
