import { get } from '@/utils/lodash'
import types from './types'
import { beams_list } from '../../../api/methods'

export default {
  fetchBeams ({ commit }, namespace) {
    return beams_list({ namespace })
      .then(response => {
        const beams = get(response, 'data', [])

        commit(types.BEAMS, beams)

        return beams
      })
  }
}
