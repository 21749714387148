/**
 * Getter for detecting user theme.
 *
 * @param state
 */
const getCurrentTheme = state => {
  // force light theme when printing docs
  if (window.location.href.includes('print=true')) {
    return 'light'
  }

  // if user is authorized use hist settings
  const fromProfile = state.profile?.theme ?? false
  if (fromProfile && (fromProfile === 'light' || fromProfile === 'dark')) {
    return fromProfile
  } else if (fromProfile && fromProfile === 'auto') {
    return state.mediaQueryTheme
  }

  // if user was already authorized once
  const fromLocalStorage = localStorage.getItem('theme')
  if (fromLocalStorage && (fromLocalStorage === 'light' || fromLocalStorage === 'dark')) {
    return fromLocalStorage
  } else if (fromLocalStorage && fromLocalStorage === 'auto') {
    return state.mediaQueryTheme
  }

  return state.mediaQueryTheme ?? 'light'
}

export default {
  isUser: state => (state.profile?.roles ?? []).includes('user'),
  isTrial: state => state.profile?.username === 'trial',
  hasRole: state => roles => roles.some(role => (state.profile?.roles ?? []).includes(role)),
  isAdminOrManager: state => ['admin', 'manager'].some(role => (state.profile?.roles ?? []).includes(role)),
  isRestricted: state => state?.restricted ?? false,
  isScrollLocked: state => state?.scrollLocked ?? false,
  getSslExpireSoon: state => state.sslExpireSoon,
  getAuthUsername: state => state.profile?.username ?? '',
  getDefaultDashboard: state => state.profile?.defaultDashboard ?? false,
  getCurrentTheme,
  email: state => state.profile?.email,
  profile: state => state.profile || null,
  getApplicationVersion: state => state.currentVersion,
  getUserTimeFormat: state => state.profile?.timeFormat ?? 'hh:mm:ss a', // look at common.yml profile for default time format
  getUserDateFormat: state => state.profile?.dateFormat ?? 'dd-MM-yy', // look at common.yml profile for default date format
  getUserDateTimeFormat: state => `${state.profile?.timeFormat ?? 'hh:mm:ss a'} ${state.profile?.dateFormat ?? 'dd-MM-yy'}`
}
