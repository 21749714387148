export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  start: {
    in: 'query',
    name: 'start',
    schema: { type: 'string', format: 'date-time' },
    description: 'From date',
    camelCaseName: 'start'
  },
  end: {
    in: 'query',
    name: 'end',
    schema: { type: 'string', format: 'date-time' },
    description: 'To date',
    camelCaseName: 'end'
  },
  level: {
    in: 'query',
    name: 'level',
    schema: { type: 'string', enum: [{ label: 'Info', value: 'info' }, { label: 'Warnings', value: 'warnings' }, { label: 'Errors', value: 'errors' }] },
    description: 'Type',
    camelCaseName: 'level'
  },
  state: {
    in: 'query',
    name: 'state',
    schema: { type: 'string', enum: [{ label: 'Read', value: 1 }, { label: 'Unread', value: 0 }] },
    description: 'State',
    camelCaseName: 'state'
  },
  component: {
    in: 'query',
    name: 'component',
    schema: { type: 'string', enum: [{ label: 'Irpspand', value: 1 }, { label: 'Core', value: 2 }, { label: 'Explorer', value: 3 }, { label: 'BGPd', value: 4 }, { label: 'BGP Sessions', value: 5 }, { label: 'Irpflowd', value: 6 }, { label: 'Irpstatd', value: 7 }, { label: 'Refresh asn', value: 8 }, { label: 'Dbcron', value: 9 }, { label: 'BGP monitor', value: 10 }, { label: 'Irpapid', value: 11 }, { label: 'Irppushd', value: 12 }, { label: 'Failover', value: 13 }, { label: 'ConfigSync', value: 14 }, { label: 'Irpinperfd', value: 15 }, { label: 'Irpbmpd', value: 16 }, { label: 'GlobalCC', value: 17 }] },
    description: 'Module',
    camelCaseName: 'component'
  }
}
