const Setup = () => import(/* webpackChunkName: "Setup" */ '@/views/Auth/Setup')
const SetupInstances = () => import(/* webpackChunkName: "SetupInstances" */ '@/views/Auth/SetupInstances')
const SetupConfig = () => import(/* webpackChunkName: "SetupConfig" */ '@/views/Auth/SetupConfig')

export default function () {
  return [
    {
      path: '/setup',
      name: 'Setup',
      component: Setup,
      meta: { requiredAuth: false, layout: 'AuthLayout' }
    },
    {
      path: '/setup/instances',
      name: 'SetupInstances',
      component: SetupInstances,
      meta: { requiredAuth: true, layout: 'AuthLayout', roles: ['manager', 'admin'] }
    },
    {
      path: '/setup/config',
      name: 'SetupConfig',
      component: SetupConfig,
      meta: { requiredAuth: true, layout: 'AuthLayout', roles: ['manager', 'admin'] }
    }
  ]
}
