const TroubleshootingBase = () => import(/* webpackChunkName: "TroubleshootingBase" */ '@/views/Troubleshooting/TroubleshootingBase')
const TroubleshootingWhoIs = () => import(/* webpackChunkName: "TroubleshootingWhoIs" */ '@/views/Troubleshooting/WhoIs')
const TroubleshootingPrefixProbing = () => import(/* webpackChunkName: "TroubleshootingPrefixProbing" */ '@/views/Troubleshooting/PrefixProbing')
const TroubleshootingLookingGlass = () => import(/* webpackChunkName: "TroubleshootingLookingGlass" */ '@/views/Troubleshooting/LookingGlass')
const TroubleshootingTraceroute = () => import(/* webpackChunkName: "TroubleshootingTraceroute" */ '@/views/Troubleshooting/Traceroute')

export default function () {
  return {
    path: '/troubleshooting',
    name: 'troubleshooting',
    redirect: { name: 'lookingGlass' },
    component: TroubleshootingBase,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-troubleshoot',
      menu: true,
      crumbMenu: true,
      crumbName: 'Troubleshooting',
      hotkey: 'SHIFT + T'
    },
    children: [
      {
        name: 'lookingGlass',
        path: '/troubleshooting/looking-glass',
        component: TroubleshootingLookingGlass,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'search2',
          crumbName: 'Looking Glass'
        }
      },
      {
        name: 'prefixProbing',
        path: '/troubleshooting/prefix-probing',
        component: TroubleshootingPrefixProbing,
        meta: {
          icon: 'quick',
          requiredAuth: true,
          layout: 'LoggedLayout',
          crumbName: 'Prefix Probing'
        }
      },
      {
        name: 'traceroute',
        path: '/troubleshooting/traceroute',
        component: TroubleshootingTraceroute,
        meta: {
          icon: 'explorer',
          requiredAuth: true,
          layout: 'LoggedLayout',
          crumbName: 'Traceroute'
        }
      },
      {
        name: 'whois',
        path: '/troubleshooting/whois',
        component: TroubleshootingWhoIs,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'global',
          crumbName: 'Whois'
        }
      }
    ]
  }
}
