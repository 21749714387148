import store from '@/store'
import { irp_get } from '@/api/methods'
import { get } from '@/utils/lodash'
import countries from '../constants/countries'

const fetchIrpDictionary = (dictionary, instanceId) => {
  return irp_get({ section: `dictionaries/${dictionary}`, hosts: [instanceId] })
    .then(response => {
      const data = get(response, `data.${instanceId}.dictionaries[0].entries`, [])
      return data.map(item => ({
        label: item.description,
        value: !Number.isNaN(parseInt(item.id, 10)) && item.id >= 0 ? item.id : item.name
      }))
    })
}

export default {
  countries () {
    return Promise.resolve(countries.map(({ name, code }) => ({ label: name, value: code })))
  },

  gmiUsers () {
    return store.dispatch('fetchUsers').then(users => {
      return users.map(u => ({ label: u.username, value: u.username }))
    })
  },

  instances () {
    return Promise.resolve(store.state.instances.all.map(i => ({ label: i.shortName, value: i.id })))
  },

  isAnnounced () {
    return Promise.resolve([{ value: 1, label: 'Announced' }, { value: 0, label: 'Not announced' }])
  },

  providers ({ instanceId }) {
    // just do not fetch providers from empty instance id
    if (!instanceId) return Promise.resolve([])

    // Fetch providers, for the instance. It's fine,
    // that this function requests providers each time,
    // because action in store will return cached providers for this instance,
    // if they was cached in previous request, and not expired interval for 5 minute.
    return store
      .dispatch('fetchProviders', instanceId)
  },

  months () {
    const now = new Date()
    const values = []
    const formatter = {
      value: new Intl.DateTimeFormat('en-US', { month: '2-digit' }),
      label: new Intl.DateTimeFormat('en-US', { month: 'long' })
    }

    for (let i = 0; i < 12; i++) {
      const tmp = new Date(now.getFullYear(), now.getMonth() - i, 1)
      values.push({
        value: formatter.value.format(tmp),
        label: formatter.label.format(tmp)
      })
    }

    return Promise.resolve(values)
  },

  monthsYear () {
    const now = new Date()
    const values = []
    const formatter = {
      yearValue: new Intl.DateTimeFormat('en-US', { year: 'numeric' }),
      value: new Intl.DateTimeFormat('en-US', { month: '2-digit' }),
      label: new Intl.DateTimeFormat('en-US', { month: 'long' })
    }

    for (let i = 0; i < 12; i++) {
      const tmp = new Date(now.getFullYear(), now.getMonth() - i, 1)
      values.push({
        yearValue: formatter.yearValue.format(tmp),
        value: { month: formatter.value.format(tmp), year: formatter.yearValue.format(tmp) },
        label: `${formatter.label.format(tmp)}, ${formatter.yearValue.format(tmp)}`
      })
    }

    return Promise.resolve(values)
  },

  years () {
    const now = new Date()
    const currentYear = now.getFullYear()
    const values = []
    for (let i = currentYear; i >= currentYear - 5; i--) {
      values.push({
        value: i,
        label: i
      })
    }

    return Promise.resolve(values)
  },

  routingDomains ({ instanceId }) {
    return irp_get({ section: 'config/routingDomains', hosts: [instanceId] })
      .then(response => {
        const routingDomains = get(response, `data.${instanceId}.config.routingDomains`, [])
        if (routingDomains) {
          return routingDomains
            .map(item => ({ value: item.id, label: item.shortname }))
            .concat([{ value: 0, label: 'Global' }]) // virtual RD
        }
      })
  },

  textSearch ({ instanceId, filterName, searchText }) {
    // do not request empty text search
    if (!searchText) return Promise.resolve([])

    return irp_get({ section: `reports/textSearch/${filterName}?text=${encodeURIComponent(searchText || '')}`, hosts: [instanceId] })
      .then(response => {
        const suggestions = get(response, `data.${instanceId}.reports[0]`)
        if (!suggestions) return []
        const filterIndex = suggestions.header.indexOf(filterName)
        return suggestions.data.map(row => ({ value: row[filterIndex], label: `${row[filterIndex]}` }))
      })
  },

  prefixSearch (options) {
    return this.textSearch(options)
      .then(suggestions => suggestions)
  },

  problems ({ instanceId }) {
    return fetchIrpDictionary('problem', instanceId)
  },

  problemStatuses ({ instanceId }) {
    return fetchIrpDictionary('problemStatus', instanceId)
  },

  improvementCauses ({ instanceId }) {
    return fetchIrpDictionary('improvementCause', instanceId)
  },

  ipVersions ({ instanceId }) {
    return fetchIrpDictionary('ipVersion', instanceId)
  },

  bgpdModes ({ instanceId }) {
    return fetchIrpDictionary('bgpdMode', instanceId)
  },

  improvementAges ({ instanceId }) {
    return fetchIrpDictionary('improvementAge', instanceId)
  },

  monitorTypes ({ instanceId }) {
    return fetchIrpDictionary('monitorType', instanceId)
  },

  probingSources ({ instanceId }) {
    return fetchIrpDictionary('probingSource', instanceId)
  },

  probeState ({ instanceId }) {
    return fetchIrpDictionary('probeState', instanceId)
  },

  inboundRulesStatuses () {
    const rulesStatuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
      { label: 'Off', value: 'off' },
      { label: 'Proposed', value: 'proposed' }
    ]

    return Promise.resolve(rulesStatuses)
  }
}
