import filterDictionaries from '@/services/reportFilterDictionaries'
import Filter from './Filter'
import { all, clearItem, getItem, setItem } from '@/utils/localStorage'
import { buildSchema } from './buildSchema'

export default {
  require: ['instances'],
  state: {
    registered: []
  },
  getters: {
    isFiltersChanged: state => {
      return !!state
        .registered
        .filter(f => f.isChanged).length
    },
    filtersParameters: state => state.registered,
    filtersDisplayedParameters: state => state.registered.filter(f => f.isDisplayed),
    queryFiltersValue: state => {
      return state
        .registered
        .filter(f => f.isChanged || !f.isEmpty)
        .reduce((reducer, filter) => Object.assign(reducer, { [filter.name]: filter.value }), {})
    },
    filtersSchema: (state, getters) => {
      return buildSchema(state.registered, getters.queryFiltersValue)
    }
  },
  mutations: {
    MEMOIZE_FILTER_STATE (state) {
      state.registered.forEach(f => f.memoize())
    },
    CLEAR_FILTERS (state) {
      state.registered = []
    },
    RESET_FILTERS (state) {
      state.registered.forEach(f => f.reset())
    },
    DROP_LAST_STATE (state) {
      state.registered.forEach(f => f.restore())
    },
    UPDATE_FILTER_VALUE (state, { name, value }) {
      const filter = state.registered.find(f => f.name === name)
      if (filter) {
        filter.value = value
      }
    },
    UPDATE_FILTER_OPTIONS (state, { key, options }) {
      if (!Array.isArray(options)) return

      const filter = state.registered.find(f => f.name === key)
      const { id, version } = filter.instance

      // cleanup cache for prev. irp version
      Array
        .from(all().keys())
        .forEach(cacheKey => {
          if (cacheKey.startsWith(`enums_${filter.name}_${id}`)) {
            clearItem(cacheKey)
          }
        })

      const storageKey = `enums_${filter.name}_${id}_${version}`

      setItem(storageKey, options, { minutes: 10 })
      filter.options = [{ value: '', label: filter.ui.placeholder || 'Any' }, ...options]
      filter.schema.enums = options.map(e => e.value)
    },
    ADD_FILTER (state, { definition, value, isDashboard }) {
      const key = definition?.camelCaseName ?? false
      if (!key) {
        return console.error('Filter not added because key is undefined')
      }

      const index = state.registered.findIndex(f => f.name === key)
      let filter = new Filter(definition)
      if (!isDashboard) {
        const { id: instanceId, version } = this.getters.getInstanceById(this.state.instances.selectedInstance)
        filter = new Filter(definition, instanceId, version)

        const dictionaryName = filter.dictionaryName()
        if (dictionaryName && filterDictionaries[dictionaryName]) {
          filter.dictionary = filterDictionaries[dictionaryName].bind(filterDictionaries)
        }

        const storageKey = `enums_${filter.name}_${instanceId}_${version}`
        const enums = getItem(storageKey, false)

        if (!enums && filter.hasDictionary) {
          filter.dictionary({ instanceId, filterName: filter.name })
            .then(options => {
              this.commit('UPDATE_FILTER_OPTIONS', { key, options })
            })
        } else if (enums && enums.length && filter.hasDictionary) {
          filter.options = [{ value: '', label: filter.ui.placeholder || 'Any' }, ...enums]
          filter.schema.enums = enums.map(e => e.value)
        }
      }

      if (value) {
        filter.value = value
      }

      index === -1
        ? state.registered.push(filter)
        : state.registered.splice(index, 1, filter)
    }
  },
  actions: {
    setNewFilterValues ({ commit }, payload) {
      Object.entries(payload).forEach(([name, value]) => {
        commit('UPDATE_FILTER_VALUE', { name, value })
      })
    }
  }
}
