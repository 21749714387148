export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  page: {
    name: 'page',
    in: 'query',
    schema: { type: 'number', default: 1 },
    camelCaseName: 'page'
  },
  prefix: {
    in: 'query',
    name: 'prefix',
    description: 'Prefix/IP',
    'x-ui': { component: 'ui-filter-autocomplete', dictionary: 'prefixSearch', props: { placeholder: 'Any Prefix/IP' } },
    schema: { type: 'string' },
    camelCaseName: 'prefix'
  },
  type: {
    name: 'type',
    in: 'query',
    description: 'Improvement type',
    'x-ui': { dictionary: 'improvementCauses', component: 'ui-filter-select', props: { placeholder: 'Any type', multiple: true } },
    schema: { type: 'array', minLength: 1, items: { oneOf: [{ type: 'string' }, { type: 'number' }] } },
    camelCaseName: 'type'
  },
  isannounced: {
    name: 'isannounced',
    in: 'query',
    description: 'Announced',
    'x-ui': { dictionary: 'isAnnounced', component: 'ui-filter-select', props: { placeholder: 'Any state' } },
    schema: { type: 'number' },
    camelCaseName: 'isannounced'
  },
  start: {
    name: 'start',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'start'
  },
  end: {
    name: 'end',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'end'
  },
  pageSize: {
    name: 'pageSize',
    in: 'query',
    description: 'Number of records',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Results per page' } },
    schema: { type: 'number', default: 20, allOf: [{ type: 'number', minimum: 1, errorMessage: 'must be > 0' }, { type: 'number', maximum: 300, errorMessage: 'must be < 300' }] },
    camelCaseName: 'pageSize'
  },
  sortBy: {
    name: 'sortBy',
    in: 'query',
    schema: { type: 'string', default: 'ts' },
    camelCaseName: 'sortBy'
  },
  order: {
    name: 'order',
    in: 'query',
    schema: { type: 'string', enum: ['ASC', 'DESC'], default: 'DESC' },
    camelCaseName: 'order'
  },
  providers: {
    name: 'providers',
    in: 'query',
    description: 'Providers',
    'x-ui': { dictionary: 'providers', component: 'ui-filter-select', props: { placeholder: 'Any Provider', multiple: true } },
    camelCaseName: 'providers'
  }
}
