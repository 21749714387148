import Vue from 'vue'
import types from './types'
import { irp_get, irp_patch, irp_delete } from '@/api/methods'
import { each, get } from '@/utils/lodash'
import { exchangeParameterMappings } from '@/components/config/data/parameterMappings'

const state = {
  busy: false,
  configurations: [],
  newEntities: []
}

const actions = {
  fetchConfiguration ({ commit }, instances) {
    commit(types.UPDATE_BUSY, true)
    return irp_get({
      section: 'config/v2',
      hosts: instances
    }).then(result => {
      each(result.data, ({ data }, instanceId) => {
        if (data) {
          commit(types.ADD_CONFIGURATION, { instanceId, config: data, initialConfig: JSON.parse(JSON.stringify(data)) })
        }
      })
      return result
    }).finally(() => commit(types.UPDATE_BUSY, false))
  },
  saveConfiguration ({ commit }, { instanceId, configuration }) {
    commit(types.UPDATE_BUSY, true)
    return new Promise((resolve, reject) => {
      irp_patch({
        section: 'config/v2',
        hosts: [instanceId],
        body: configuration
      }).then(result => {
        if (get(result.data, `${instanceId}.status`) !== 'error') {
          commit(types.CLEAR_NEW_ENTITIES)
          commit(types.ADD_CONFIGURATION, {
            instanceId,
            config: get(result.data, `${instanceId}.data`),
            initialConfig: JSON.parse(JSON.stringify(get(result.data, `${instanceId}.data`)))
          })
          resolve(result.data)
        } else {
          const message = get(result.data, `${instanceId}.message`)
          reject(message)
        }
      }).finally(() => commit(types.UPDATE_BUSY, false))
    })
  },
  createEntity ({ commit }, { entityType, instanceId, section, body, method }) {
    commit(types.UPDATE_BUSY, true)
    return new Promise((resolve, reject) => {
      method({ section, hosts: [instanceId], body: body.parameters })
        .then(result => {
          if (get(result.data, `${instanceId}.status`) !== 'error') {
            const cloned = entity => { return JSON.parse(JSON.stringify(entity)) }

            commit(types.ADD_ENTITY, { entityType, data: cloned(get(result.data, `${instanceId}.data`)), instanceId, to: 'config' })
            commit(types.ADD_ENTITY, { entityType, data: cloned(get(result.data, `${instanceId}.data`)), instanceId, to: 'initialConfig' })
            resolve(result.data)
          } else {
            const message = get(result.data, `${instanceId}.message`)
            reject(message)
          }
        }).catch(error => {
          reject(error.message)
        }).finally(() => commit(types.UPDATE_BUSY, false))
    })
  },
  deleteEntity ({ commit }, { entityType, entity, instanceId }) {
    commit(types.UPDATE_BUSY, true)
    return new Promise((resolve, reject) => {
      irp_delete({
        section: 'config/v2/' + entityType + '/' + entity.id,
        hosts: [instanceId]
      }).then(result => {
        if (get(result.data, `${instanceId}.status`) !== 'error') {
          commit(types.REMOVE_ENTITY, { entityType, entity, instanceId, from: 'config' })
          commit(types.REMOVE_ENTITY, { entityType, entity, instanceId, from: 'initialConfig' })
          resolve(true)
        } else {
          const message = get(result.data, `${instanceId}.message`)
          reject(message)
        }
      }).catch(error => {
        reject(error.message)
      }).finally(() => commit(types.UPDATE_BUSY, false))
    })
  },
  deleteExchangeRule ({ commit }, { entityType, providerId, entity, instanceId }) {
    commit(types.UPDATE_BUSY, true)
    return new Promise((resolve, reject) => {
      irp_delete({
        section: 'config/v2/Exchange/' + providerId + '/' + entity.id,
        hosts: [instanceId]
      }).then(result => {
        if (get(result.data, `${instanceId}.status`) !== 'error') {
          commit(types.REMOVE_EXCHANGE_RULE, { entityType, entity, instanceId, from: 'config', providerId })
          commit(types.REMOVE_EXCHANGE_RULE, { entityType, entity, instanceId, from: 'initialConfig', providerId })
          resolve(result.data)
        } else {
          const message = get(result.data, `${instanceId}.message`)
          reject(message)
        }
      }).catch(error => {
        reject(error.message)
      }).finally(() => commit(types.UPDATE_BUSY, false))
    })
  }
}

const mutations = {
  [types.UPDATE_BUSY] (state, status) {
    state.busy = status
  },
  [types.ADD_CONFIGURATION] (state, instanceConfiguration) {
    const idx = state.configurations.findIndex(c => c.instanceId === instanceConfiguration.instanceId)
    if (idx > -1) {
      state.configurations.splice(idx, 1, instanceConfiguration)
    } else {
      state.configurations.push(instanceConfiguration)
    }
  },
  [types.ADD_NEW_ENTITY] (state, entity) {
    state.newEntities.push(entity)
  },
  [types.CLEAR_NEW_ENTITIES] (state) {
    state.newEntities = []
  },
  [types.RESET_CONFIGURATION] (state, instanceId) {
    const instanceConfiguration = state.configurations.find(c => c.instanceId === instanceId)
    Object.assign(instanceConfiguration.config, JSON.parse(JSON.stringify(instanceConfiguration.initialConfig)))
  },
  [types.UPDATE_PARAMETER] (state, { id, value, instanceId }) {
    const path = id.split('/properties/')
    path.splice(0, 1)
    const config = state.configurations.find(c => c.instanceId === instanceId).config
    const parameterName = path.pop()
    const parameters = get(config, path)
    if (value === null) {
      Vue.delete(parameters, parameterName)
    } else {
      Vue.set(parameters, parameterName, value)
    }
  },
  [types.UPDATE_ENTITY_PARAMETER_BY_ID] (state, { id, entityType, entityId, value, instanceId }) {
    const entity = state.configurations.find(c => c.instanceId === instanceId).config[entityType].find(e => e.id === entityId)
    const parameterParts = id.split('/')
    const parameterName = parameterParts[parameterParts.length - 1]
    if (value === null) {
      Vue.delete(entity.parameters, parameterName)
    } else {
      Vue.set(entity.parameters, parameterName, value)
    }
  },
  [types.UPDATE_ENTITY_PARAMETER] (state, { id, entityType, entity, value, instanceId }) {
    const foundEntity = state.configurations.find(c => c.instanceId === instanceId).config[entityType].find(e => e === entity)
    const parameterParts = id.split('/')
    const parameterName = parameterParts[parameterParts.length - 1]
    if (value === null) {
      Vue.delete(foundEntity.parameters, parameterName)
    } else {
      Vue.set(foundEntity.parameters, parameterName, value)
    }
  },
  [types.ADD_ENTITY] (state, { entityType, data, instanceId, to = 'config' }) {
    const config = state.configurations.find(c => c.instanceId === instanceId)[to]
    if (config[entityType]) config[entityType] = [...config[entityType], data]
  },
  [types.REMOVE_ENTITY] (state, { entityType, entity, instanceId, from = 'config' }) {
    const config = state.configurations.find(c => c.instanceId === instanceId)[from]
    if (config) config[entityType] = config[entityType].filter(saved => saved.id !== entity.id)
  },
  [types.ADD_EXCHANGE_RULE] (state, { providerId, data, instanceId }) {
    const config = state.configurations.find(c => c.instanceId === instanceId).config
    if (config.Exchange) {
      const Exchange = config.Exchange.find(e => e.provider === providerId)
      if (!Exchange) {
        config.Exchange.push({
          provider: providerId,
          rules: []
        })
      }
      config.Exchange.find(e => e.provider === providerId).rules.push(data)
    }
  },
  [types.REMOVE_EXCHANGE_RULE] (state, { providerId, entity, instanceId, from = 'config' }) {
    const config = state.configurations.find(c => c.instanceId === instanceId)[from]
    if (config) {
      const rules = config.Exchange.find(e => e.provider === providerId).rules
      const idx = rules.indexOf(entity)
      rules.splice(idx, 1)
    }
  },
  [types.UPDATE_EXCHANGE_RULE_PARAMETER] (state, { providerId, entity, id, value, instanceId }) {
    const config = state.configurations.find(c => c.instanceId === instanceId).config
    if (config) {
      const rule = config.Exchange.find(e => e.provider === providerId).rules.find(r => r === entity)
      const parameterParts = id.split('/')
      const parameterName = parameterParts[parameterParts.length - 1]
      if (value === '' || value === null) {
        Vue.delete(rule.parameters, parameterName)
      } else {
        Vue.set(rule.parameters, parameterName, value)
      }
    }
  },
  [types.PBR_CHECK] (state, { providerId, value, instanceId }) {
    const config = state.configurations.find(c => c.instanceId === instanceId).config
    if (config) {
      const rules = config.Exchange.find(e => e.provider === providerId).rules
      rules.forEach(rule => {
        rule.parameters[exchangeParameterMappings.pbrCheck] = value
      })
    }
  }
}

const getters = {
  getInstanceConfiguration: state => instanceId => state.configurations.find(c => c.instanceId === instanceId),
  getParameterValue: state => (instanceId, parameter) => {
    return state.configurations.find(c => c.instanceId === instanceId).config.Global.parameters[parameter]
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
