import { upperFirst } from '@/utils/lodash'
import { createMutationName } from './mutations'

const pluralize = string => string + 's'

const createActionName = (entityType, type) => {
  const capitalized = upperFirst(entityType)
  return type === 'fetch' ? `${type}${pluralize(capitalized)}` : `${type}${capitalized}`
}

const requestAction = (method, methods) => {
  const request = methods[method]
  if (typeof request !== 'function') {
    throw new Error('No fetch method provided')
  }
  return request
}

const createAction = {
  fetch (entityType, methods) {
    const request = requestAction(`${pluralize(entityType)}_list`, methods)

    return ({ state, commit }, force = false) => {
      if (state[pluralize(entityType)].length > 0 && !force) {
        return Promise.resolve({ data: state[pluralize(entityType)] })
      }
      return request().then(response => {
        commit(createMutationName(entityType, 'set'), response.data)
        return response
      })
    }
  },

  create (entityType, methods) {
    const request = requestAction(`${pluralize(entityType)}_create`, methods)

    return ({ dispatch, commit, state }, payload) => {
      return request({ body: payload }).then(response => {
        commit(createMutationName(entityType, 'add'), response.data)
        return response
      })
    }
  },

  update (entityType, methods) {
    const request = requestAction(`${pluralize(entityType)}_update`, methods)

    return ({ dispatch, commit, state }, payload) => {
      return request({ id: payload.id, body: payload }).then(response => {
        commit(createMutationName(entityType, 'update'), response.data)
        return response
      })
    }
  },

  patch (entityType, methods) {
    const request = requestAction(`${pluralize(entityType)}_patch`, methods)

    return ({ dispatch, commit, state }, payload) => {
      return request({ id: payload.id, body: payload }).then(response => {
        commit(createMutationName(entityType, 'patch'), { id: payload.id, payload })
        return response
      })
    }
  },

  delete (entityType, methods) {
    const request = requestAction(`${pluralize(entityType)}_delete`, methods)

    return ({ dispatch, commit, state }, id) => {
      return request({ id }).then(response => {
        commit(createMutationName(entityType, 'remove'), id)
        return response
      })
    }
  }
}

// Supported actions names
const ACTIONS = ['fetch', 'create', 'update', 'patch', 'save', 'delete']

/**
 * Create actions stubs for the module
 *
 * @param entityType {String}        Name of entity like: 'user' -> 'fetchUsers', 'createUser'
 * @param types      {Array<String>} Array of actions which need to create
 * @param methods    {Object}        Array of function to use as requests
 * @return {Object}
 * @see ACTIONS
 */
export function entityActions (entityType, types = ACTIONS, methods = {}) {
  const actions = {}
  if (types.length === 0) {
    throw new Error('Require at least one action name')
  }

  types.forEach(type => {
    actions[createActionName(entityType, type)] = createAction[type](entityType, methods)
  })
  return actions
}

/**
 * Generate actions for receiving schema actions for the store entity. Usually this is
 * create and update. For example if call this method with such parameters:
 *  schemaActions ({ create: () =>  {} })
 *
*  This will create store next store actions:
 *  { 'actionName' + 'entity' + 'Schema': fn() }
 *
 * @note Currently this function support only "create" and "update" actions !!!
 *
 * @param entity  {String} Entity name for prefix actions name.
 * @param types {Object} Which schema store actions to create
 */
export function schemaActions (entity, types) {
  const actions = {}

  if (entity.length === 0) {
    throw new Error('Require entity name to be provided')
  }

  if (Object.keys(types).length === 0) {
    throw new Error('Require at least one action name and function to be provided')
  }

  const request = (type, payload, fn) => {
    const parameters = type === 'create'
      ? { body: {}, $queryParameters: { schema: 'body' } }
      : { body: {}, id: payload.id, $queryParameters: { schema: 'body' } }

    return fn(parameters)
  }

  Object.entries(types).forEach(([type, fn]) => {
    actions[`${createActionName(entity, type)}Schema`] = (_, payload) => request(type, payload, fn)
  })

  return actions
}
