<template>
  <div>
    <pie-placeholder v-if="type === 'Pie' && !loaded && !reload" />
    <graph-placeholder v-else-if="!loaded && !reload" />
    <fade-transition>
      <div v-if="(loaded || reload) && error === null">
        <div
          v-if="type.match(/(fixed|)NegativeBar/gi)"
          class="flex flex-row space-x-3 mb-4"
        >
          <div
            v-for="({ value, unit, name }) in dataSummary"
            :key="name"
            class="flex flex-row space-x-1"
          >
            <span
              class="text-darkgray-500 dark:text-darkgray-100"
              v-text="`${name}:`"
            />
            <span
              class="text-darkgray-400 dark:text-white font-bold"
              v-text="`${value} ${unit}`"
            />
          </div>
        </div>
        <div v-if="type !== 'SyncGraph'">
          <highcharts
            ref="highCharts"
            constructor-type="chart"
            :options="graphOptions"
            :deep-copy-on-update="false"
          />
          <highcharts
            v-if="shouldRenderOverview"
            ref="highChartsOverview"
            style="height: 70px;"
            constructor-type="chart"
            :deep-copy-on-update="false"
            class="chart-overview"
            :options="graphOverviewOptions"
          />
        </div>
        <div v-else>
          <highcharts
            v-for="(graphOpts, name) in graphOptions.syncCharts"
            ref="highcharts"
            :key="name"
            style="height: calc((100vh - 280px)/3); min-height: 190px;"
            :options="graphOpts"
            class="relative"
          />
          <ui-button
            v-if="zoomButton && type === 'SyncGraph'"
            class="absolute right-10 top-16"
            @click="syncResetZoom()"
            v-text="'reset zoom'"
          />
        </div>
      </div>
    </fade-transition>

    <div v-if="loaded && error !== null" class="full-error">
      <common-errors
        :messages="error"
        :instances="[instanceId]"
      />
    </div>
  </div>
</template>
<script>
import CommonErrors from '@/components/common/CommonErrors'
import FadeTransition from 'vue2-transitions/src/Fade/FadeTransition'
import UiButton from 'uilib/src/Button'
import GraphEngine from './classes/GraphEngine'
import GraphDataRequest from './utils/graphDataRequest'
import GraphPlaceholder from '@/components/graphs/GraphPlaceholder'
import Pie from '@/components/graphs/placeholders/Pie'
import { get, set, cloneDeep } from '@/utils/lodash'
import { mapGetters } from 'vuex'

const aditionFiltersOption = {
  total: ['sumloss', 'sumrttavg', 'cntprobesfailed', 'cntprobes'],
  global: ['sumlossglobal', 'sumrttavgglobal', 'cntprobesfailedglobal', 'cntprobesglobal'],
  transcontinental: ['sumlosstranscontinental', 'sumrttavgtranscontinental', 'cntprobesfailedtranscontinental', 'cntprobestranscontinental'],
  continental: ['sumlosscontinental', 'sumrttavgcontinental', 'cntprobesfailedcontinental', 'cntprobescontinental'],
  regional: ['sumlossregional', 'sumrttavgregional', 'cntprobesfailedregional', 'cntprobesregional'],
  local: ['sumlosslocal', 'sumrttavglocal', 'cntprobesfailedlocal', 'cntprobeslocal']
}

export default {
  name: 'Graph',
  components: {
    CommonErrors,
    FadeTransition,
    PiePlaceholder: Pie,
    GraphPlaceholder,
    UiButton
  },
  props: {
    instanceId: {
      type: String,
      required: true
    },
    api: {
      type: Object,
      required: true
    },
    showOverview: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    callback: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: false,
      default: () => {}
    },
    keys: {
      type: Array,
      required: false,
      default: () => []
    },
    units: {
      type: Array,
      required: false,
      default: () => {}
    },
    defaultFilters: {
      type: Object,
      required: false,
      default: () => {}
    },
    initialProviders: {
      type: Array,
      required: false,
      default: () => []
    },
    initialReportDataPath: {
      type: [Array, String],
      required: false,
      default: undefined
    },
    region: {
      type: String,
      required: false,
      default: undefined
    },
    isGroupedGraph: {
      type: Boolean,
      required: false,
      default: false
    },
    // TODO: remove this prop when we have a better solution
    isHistoricalInbound: {
      type: Boolean,
      default: false
    },
    // TODO: remove this prop when we have a better solution
    typeHistoricalInbound: {
      type: String,
      default: 'throughput'
    }
  },
  data () {
    return {
      graphEngine: new GraphEngine(this.$props),
      error: null,
      loaded: false,
      reload: false,
      zoomButton: null,
      providers: this.initialProviders
    }
  },
  computed: {
    ...mapGetters(['getProvidersByInstanceId']),
    shouldRenderOverview () {
      return this.type.includes('Area') && this.showOverview
    },
    graphOptions () {
      const options = cloneDeep(this.graphEngine.graph)
      options.providers = this.providers

      if (this.isHistoricalInbound) {
        this.typeHistoricalInbound === 'throughput'
          ? options.series = options.seriesvth
          : options.series = options.seriesv
      }

      if (this.region && this.isGroupedGraph) {
        options.series = options.series.filter(series => aditionFiltersOption[this.region].includes(series.id))
      }
      if (this.showOverview) {
        const addMask = this.addMask
        const removeMask = this.removeMask
        if (this.units && options.yAxis && (options.yAxis.title || options.yAxis.length > 1)) {
          if (this.units.length > 1) {
            options.yAxis[0].title.text = this.units[0]
            options.yAxis[1].title.text = this.units[1]
          } else if (this.units[0]) {
            options.yAxis.title.text = this.units[0]
          }
        }
        if (this.isHistoricalInbound) {
          if (this.typeHistoricalInbound === 'throughput') {
            options.yAxis.labels.formatter = options.yAxis.labelsvth.formatter
            options.tooltip.formatter = options.tooltipvth.formatter
          } else {
            options.yAxis.labels.formatter = options.yAxis.labelsv.formatter
            options.tooltip.formatter = options.tooltipv.formatter
          }
        }
        options.chart.resetZoomButton = {
          position: { align: 'right', x: -15, y: 40 },
          theme: { zIndex: 20 }
        }
        options.chart.events = {
          ...options.chart.events,
          selection (event) {
            if (event.xAxis) {
              const extremesObject = event.xAxis[0]
              const min = extremesObject.min
              const max = extremesObject.max
              addMask(min, max)
            } else {
              removeMask()
            }
          }
        }
      }
      if (this.$route.query.print) {
        if (options.plotOptions.series) options.plotOptions.series.animation = false
        else options.plotOptions.series = { animation: false }
      }
      options.chart.backgroundColor = 'transparent'
      options.exporting = {
        enabled: false
      }
      return options
    },
    graphOverviewOptions () {
      const options = this.graphEngine.overviewGraph
      const addMask = this.addMask
      const zoom = this.zoom
      const addResetButton = this.addResetButton
      options.chart.events = {
        ...options.chart.events,
        selection (event) {
          const extremesObject = event.xAxis[0]
          const min = extremesObject.min
          const max = extremesObject.max
          zoom(min, max)
          addMask(min, max)
          addResetButton()
          return false
        }
      }
      options.exporting = {
        enabled: false
      }

      if (this.isHistoricalInbound) {
        this.typeHistoricalInbound === 'throughput'
          ? options.series = options.seriesvth
          : options.series = options.seriesv
      }

      return options
    },
    dataSummary () {
      if (this.callback !== 'providersOverallPrefixes') return

      const totalRerouted = (this.graphOptions.series[0]?.data || []).reduce((acc, el) => acc + Math.abs(el), 0)
      const roundedValue = Math.round(totalRerouted)

      return [{ name: 'TOTAL REROUTED', value: roundedValue, unit: 'prefixes' }]
    },
    isSyncGraph () {
      return this.type === 'SyncGraph'
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeprint', this.beforePrintHandler)
    window.removeEventListener('afterprint', this.afterPrintHandler)
  },
  methods: {
    zoom (min, max) {
      const xAxis = this.$refs.highCharts.chart.xAxis[0]
      xAxis.setExtremes(min, max, true)
    },
    syncResetZoom () {
      this.$refs.highcharts.forEach(({ chart }) => {
        chart.xAxis[0].setExtremes(null, null)
      })
      this.zoomButton = null
    },
    addResetButton () {
      const chart = this.$refs.highCharts.chart
      if (!chart.resetZoomButton) {
        chart.showResetZoom()
      }
    },
    removeMask () {
      if (!this.shouldRenderOverview) {
        return
      }

      const xAxis = this.$refs.highChartsOverview.chart.xAxis[0]
      xAxis.removePlotBand('mask')
    },
    addMask (min, max) {
      if (!this.shouldRenderOverview) {
        return
      }

      const xAxis = this.$refs.highChartsOverview.chart.xAxis[0]
      xAxis.removePlotBand('mask')
      xAxis.addPlotBand({
        id: 'mask',
        from: min,
        to: max,
        zIndex: 10000,
        color: 'rgba(0, 0, 0, 0.2)'
      })
    },
    updateChartForPrint () {
      window.addEventListener('beforeprint', this.beforePrintHandler)
      window.addEventListener('afterprint', this.afterPrintHandler)
    },
    beforePrintHandler () {
      const { highCharts } = this.$refs
      if (!highCharts) return
      highCharts.chart.setSize(1000, null, 0)
    },
    afterPrintHandler () {
      const { highCharts } = this.$refs
      if (!highCharts) return
      highCharts.chart.setSize(null, null, 0)
    },
    load (reload = false) {
      let reportDataPath = typeof this.initialReportDataPath !== 'undefined'
        ? this.initialReportDataPath
        : [this.instanceId, 'reports', 0]
      this.zoomButton = null
      this.reload = reload
      const request = new GraphDataRequest(this.api)
      request.prepare(this.instanceId, this.filters)

      return this.api.fetch(request.options)
        .then(({ data }) => {
          this.error = null
          if (this.isSyncGraph) {
            reportDataPath = (this.callback === 'probesToday')
              ? Object.keys(data[this.instanceId])[1]
              : Object.keys(data[this.instanceId])[0]
          }
          const report = get(data, reportDataPath, false)
          const reportData = get(data, [this.instanceId, reportDataPath], false)
          if (report !== false || reportData !== false) {
            this.graphEngine.parse(report || reportData, this.instanceId, this.filters, this.$store, this)
              .then(this.onResponseParsed)
              .then(this.updateChartForPrint())
              .then(() => {
                if (this.graphEngine.error.length) throw new Error(this.graphEngine.error)
              })
              .catch(e => {
                this.error = e.hasOwnProperty('type') ? e : [e.message]
              })
              .finally(() => (this.loaded = true))
          } else {
            throw Error(get(data, [this.instanceId, 'message'], 'Unknown error happen'))
          }
        })
        .catch(error => {
          const instance = this.$store.getters.getInstanceById(this.instanceId)

          this.error = [`${instance.shortName}: ${error.response?.data?.message || error.message}`]

          this.loaded = true
        })
    },
    onResponseParsed () {
      if (this.$route.params.name === 'asnTrafficPercentage') {
        const vm = this

        set(this.graphOptions, 'plotOptions.series.point.events.click', function () {
          vm.$router.push({
            path: '/reports/asStatistics',
            query: { asnumber: this.id }
          })
        })
      }
    },
    reloadProviders (newProviders) {
      const instanceProviders = this.getProvidersByInstanceId(this.instanceId)
      this.providers = newProviders.map(providerId => instanceProviders.find(p => p.value === providerId)?.label.toUpperCase())
    },
    getChart () {
      return this.$refs.highCharts?.chart
    }
  }
}
</script>
<style lang="scss" scoped>
  @media print {
    .chart-overview { display: none; }
  }

  .chart-legend {
    .legend-color {
      display: block;
      width: 20px;
      height: 10px;
    }

    td {
      padding: 0 10px 0 0;

      .label {
        padding-right: 10px;
      }
    }
  }

  .full-error {
    text-align: center;
  }
</style>
