export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  prefixes: {
    in: 'query',
    name: 'prefixes',
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'prefixes'
  }
}
