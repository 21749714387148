export default {
  is_prod: true,
  productionTip: true,
  HOST: window.location.hostname,
  WEB_SOCKET_PORT: window.location.port || 443,
  WEB_SOCKET_PROTOCOL: 'wss://',
  WS_OPTIONS: {
    autoConnect: false,
    transports: ['websocket'],
    rejectUnauthorized: false,
    secure: true
  },
  WEB_SOCKET_HOST: function () {
    return this.WEB_SOCKET_PROTOCOL + this.HOST + ':' + this.WEB_SOCKET_PORT
  }
}
