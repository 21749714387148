import { getDomain } from './request'
export const tools_remove_task_RAW_URL = () => '/tools/tasks/{id}'

export const tools_remove_task_URL = (parameters = {}) => {
  let path = tools_remove_task_RAW_URL()
  let query = ''

  path = path.replace('{id}', `${parameters.id}`)
  return getDomain() + path + query
}
