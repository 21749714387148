const GlobalCommitBase = () => import(/* webpackChunkName: "GlobalCommitBase" */ '@/views/GlobalCommit/GlobalCommitBase')
const GlobalCommitSummary = () => import(/* webpackChunkName: "GlobalCommitSummary" */ '@/views/GlobalCommit/GlobalCommitSummary')
const GlobalCommitStatus = () => import(/* webpackChunkName: "GlobalCommitSummary" */ '@/views/GlobalCommit/GlobalCommitStatus')
const GlobalCommitConfig = () => import(/* webpackChunkName: "GlobalCommitConfig" */ '@/views/GlobalCommit/GlobalCommitConfig')
const GlobalCommitGraph = () => import(/* webpackChunkName: "GlobalCommitGraph" */ '@/views/GlobalCommit/GlobalCommitGraph')

export default function () {
  return {
    path: '/global-commit',
    name: 'globalcommit',
    component: GlobalCommitBase,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout',
      icon: 'icon-global',
      menu: true,
      crumbName: 'Global Commit',
      crumbMenu: true,
      hotkey: 'SHIFT + M'
    },
    children: [
      {
        name: 'summary',
        path: '/global-commit/summary',
        component: GlobalCommitSummary,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'summary',
          crumbName: 'Summary'
        }
      },
      {
        name: 'status',
        path: '/global-commit/status',
        component: GlobalCommitStatus,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'system_not',
          crumbName: 'Status'
        }
      },
      {
        name: 'config',
        path: '/global-commit/config',
        component: GlobalCommitConfig,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'cfg',
          crumbName: 'Configuration',
          roles: ['manager', 'admin']
        }
      },
      {
        name: 'graph',
        path: '/global-commit/graph',
        component: GlobalCommitGraph,
        meta: {
          requiredAuth: true,
          layout: 'LoggedLayout',
          icon: 'graphs',
          crumbName: 'Graph'
        },
        children: [
          {
            name: 'group_graph',
            path: '/global-commit/graph/:groupId',
            component: GlobalCommitGraph,
            meta: {
              requiredAuth: true,
              layout: 'LoggedLayout'
            }
          }
        ]
      }
    ]
  }
}
