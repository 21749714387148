import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import beams from './modules/beams'
import dashboards from './modules/dashboards'
import instances from './modules/instances'
import tools from './modules/tools'
import globalCommit from './modules/globalCommit'
import configuration from './modules/configuration'
import instancesEvents from './modules/instancesEvents'
import notifications from './modules/notifications'
import users from './modules/users'
import maintenanceWindow from './modules/maintenanceWindow'
import savedReports from './modules/savedReports'
import senders from './modules/senders'
import search from './modules/search'
import subscriptions from './modules/subscriptions'
import providers from './modules/providers'
import filters from './modules/filters'
import createLogger from './plugins/storeLogger'

Vue.use(Vuex)

const debug = !process.env.NODE_ENV.match(/(production|test)\b/gi)

export default new Vuex.Store({
  modules: {
    auth,
    beams,
    dashboards,
    instances,
    globalCommit,
    configuration,
    instancesEvents,
    notifications,
    users,
    tools,
    maintenanceWindow,
    savedReports,
    senders,
    search,
    subscriptions,
    providers,
    filters
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
