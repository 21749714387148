import { entityActions } from '../../helpers/actions'
import { createMutationName, entityMutations } from '../../helpers/mutations'
import senders_create from '@/api/methods/senders_create'
import senders_delete from '@/api/methods/senders_delete'
import senders_update from '@/api/methods/senders_update'
import senders_list from '@/api/methods/senders_list'

export default {
  state: {
    senders: []
  },
  actions: {
    ...entityActions(
      'sender',
      ['create', 'update', 'fetch'],
      { senders_create, senders_update, senders_list }
    ),
    deleteSender ({ commit }, { id, confirm = false }) {
      return senders_delete({ id, confirm })
        .then(response => {
          commit(createMutationName('sender', 'remove'), id)
          return response
        })
    }
  },
  mutations: {
    ...entityMutations('sender', ['add', 'update', 'remove', 'set'])
  }
}
