import { matches } from '@/utils/lodash'
import Type from './types'

export default {
  [Type.BEAMS] (state, beams) {
    state.beams = beams
  },
  [Type.ADD_BEAM] (state, beam) {
    const { namespace, userId } = beam
    const index = state.beams.findIndex(matches({ namespace, userId }))
    if (index !== -1) {
      Object.assign(state.beams[index], beam)
    } else {
      state.beams.push(beam)
    }
  },
  [Type.REMOVE_BEAM] (state, beam) {
    const { namespace, userId } = beam
    const index = state.beams.findIndex(matches({ namespace, userId }))
    if (index !== -1) {
      state.beams.splice(index, 1)
    }
  }
}
