import { getDomain } from './request'
export const getInboundPerformanceActiveFeed_RAW_URL = () => '/irp/inboundperformance/rules/status/active'

export const getInboundPerformanceActiveFeed_URL = (parameters = {}) => {
  let path = getInboundPerformanceActiveFeed_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.text !== undefined) {
    queryParameters.text = parameters.text
  }
  if (parameters.provider_id !== undefined) {
    queryParameters.provider_id = parameters.provider_id
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
