import { irp_get, irp_post, irp_delete, irp_patch } from '@/api/methods'
import { get } from '@/utils/lodash'
import types from '@/store/modules/maintenanceWindow/types'

let BUSY = false

const createResponseHandle = (instanceId, isNew, commit) => {
  return response => {
    // check that we have privileges to add maintenance window on the instance,
    // because irp api response with 200 on error
    if (response?.data?.[instanceId]?.status === 'error') {
      throw new Error(response?.data?.[instanceId]?.message ?? `Failed to ${isNew ? 'create' : 'update'} maintenance window`)
    }
    const warning = get(response, `data.${instanceId}.results.0.description`, null)
    const reqMaintenanceWindow = get(response, `data.${instanceId}.maintenanceWindows.0`, false)
    const maintenanceWindow = { ...reqMaintenanceWindow, start: (new Date(reqMaintenanceWindow.start)).toString(), end: (new Date(reqMaintenanceWindow.end)).toString() }
    commit(isNew ? types.ADD_MAINTENANCE_WINDOW : types.UPDATE_MAINTENANCE_WINDOW, { instanceId, maintenanceWindow })
    return { maintenanceWindow, warning }
  }
}

const getMaintenanceWindowId = mw => mw.ids ? `?${mw.ids.reduce((acc, curr, ix, arr) => `${acc}ids[]=${curr}${ix !== arr.length - 1 ? '&' : ''}`, '')}` : mw.id

export default {
  fetchMaintenanceWindows ({ state, commit }, hosts) {
    if (BUSY) return state.all
    BUSY = true
    return irp_get({ section: 'mw', hosts })
      .then(response => {
        const mws = Object
          .keys(get(response, 'data', {}))
          .reduce((map, key) => {
            map[key] = get(response, ['data', key, 'maintenanceWindows'], [])
            return map
          }, {})
        // Convert the Time from UTC to Local
        commit(types.MAINTENANCE_WINDOWS, mws)
        BUSY = false
        return mws
      })
      .catch(err => console.warn(err))
  },
  createMaintenanceWindow ({ commit }, { instance, payload }) {
    const parameters = {
      section: 'mw',
      hosts: [instance],
      body: payload
    }

    return irp_post(parameters)
      .then(createResponseHandle(instance, true, commit))
  },
  updateMaintenanceWindow ({ commit }, { instance, payload }) {
    const parameters = {
      section: `mw/${getMaintenanceWindowId(payload)}`,
      hosts: [instance],
      body: payload
    }

    return irp_patch(parameters)
      .then(createResponseHandle(instance, false, commit))
  },
  deleteMaintenanceWindow ({ commit }, { instance, payload }) {
    const parameters = {
      section: `mw/${getMaintenanceWindowId(payload)}`,
      hosts: [instance]
    }

    const commitParameters = {
      instanceId: instance,
      maintenanceWindowId: payload.id ?? payload.ids
    }

    return irp_delete(parameters)
      .then(() => commit(types.REMOVE_MAINTENANCE_WINDOW, commitParameters))
  }
}
