import { theme } from '@/components/graphs/types/Theme'
import { merge } from 'highcharts'
import colors from '@/constants/colors'

export default () => merge(true, theme(), {
  chart: {
    type: 'bar'
  },
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    symbolRadius: 0,
    padding: 3,
    itemMarginTop: 5,
    itemMarginBottom: 5
  },
  plotOptions: {
    series: {
      stacking: 'normal'
    }
  },
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    }]
  },
  colors
})
