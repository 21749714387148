import { getDomain } from './request'
export const getTotalAndImprovedTraffic_RAW_URL = () => '/irp/reports/totalAndImprovedTraffic'

export const getTotalAndImprovedTraffic_URL = (parameters = {}) => {
  let path = getTotalAndImprovedTraffic_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.inbound !== undefined) {
    queryParameters.inbound = parameters.inbound
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
