import { theme } from '@/components/graphs/types/Theme'
import { merge } from 'highcharts'

export default () => merge(true, {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  boost: { seriesThreshold: 5000 },
  title: {
    text: ''
  },
  tooltip: {
    pointFormat: '{point.percentage:.2f} %'
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.2f} %'
      }
    }
  },
  series: []
}, theme())
