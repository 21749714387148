import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: getInboundRuleList
 * url: /irp/inboundperformance/rules
 * @param hosts { Array }
 * @param page {  }
 * @param pageSize {  } Number of records
 * @param id {  }
 * @param providerId {  }
 * @param text { String }
 * @param status {  }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/inboundperformance/rules'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.page !== undefined) {
    queryParameters.page = parameters.page
  } else if (parameters.page === undefined) {
    queryParameters.page = 1
  }
  if (parameters.pageSize !== undefined) {
    queryParameters.pageSize = parameters.pageSize
  } else if (parameters.pageSize === undefined) {
    queryParameters.pageSize = 20
  }
  if (parameters.id !== undefined) {
    queryParameters.id = parameters.id
  } if (parameters.providerId !== undefined) {
    queryParameters.provider_id = parameters.providerId
  } if (parameters.text !== undefined) {
    queryParameters.text = parameters.text
  } if (parameters.status !== undefined) {
    queryParameters.status = parameters.status
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
