import { getDomain } from './request'
export const userDirectoriesGroups_RAW_URL = () => '/userDirectories/groups'

export const userDirectoriesGroups_URL = (parameters = {}) => {
  let path = userDirectoriesGroups_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.search !== undefined) {
    queryParameters.search = parameters.search
  }
  if (parameters.size !== undefined) {
    queryParameters.size = parameters.size
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
