<template>
  <div class="breadcrumbs-menu-group-container dark:bg-darkgray-500">
    <div
      v-for="groupName in Object.keys(groupedReports)"
      :key="groupName"
      class="breadcrumbs-menu-group"
      :style="{ width: columnNumber }"
    >
      <div :class="'breadcrumbs-menu-group__name text-darkgray-500 text-center dark:text-white  breadcrumbs-menu-group__name--' + groupName.toLowerCase()">
        {{ groupName | capitalize }}
      </div>
      <div class="mt-4 breadcrumbs-menu-group-list">
        <ul>
          <router-link
            v-for="item in groupedReports[groupName]"
            :key="item.name"
            class="truncate whitespace-no-wrap px-2 cursor-pointer transition-colors duration-300 ease-in-out mb-1 dark:text-white"
            :class="'breadcrumbs-menu-item breadcrumbs-menu-graph-item breadcrumbs-menu-graph-item--' + groupName.toLowerCase()"
            :to="item.path"
            tag="li"
          >
            {{ item.name }}
            <span
              v-if="item.map"
              class="float-right font-bold text-primary-500 underline map"
              @click.stop="navigateWithMap(item.id)"
              v-text="'Map View'"
            />
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BreadcrumbsMenu',
  props: {
    reports: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    groupedReports () {
      const groupedReports = this.reports.reduce((acc, current) => {
        if (!acc.hasOwnProperty(current.sectionMenu)) acc[current.sectionMenu] = []
        acc[current.sectionMenu].push(current)
        return acc
      }, {})
      return groupedReports
    },
    columnNumber () {
      return this.$route.name === 'Report'
        ? 1000 / (Object.keys(this.groupedReports).length - 1) + 'px'
        : 100 / Object.keys(this.groupedReports).length + '%'
    }
  },
  methods: {
    navigateWithMap (name) {
      this.$router.push({ name: 'Report', params: { name, map: true } })
    }
  }
}
</script>
