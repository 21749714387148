import actions from './actions'
import mutations from './mutations'

const state = {
  events: {
    errors: [],
    warnings: [],
    info: []
  },
  warnings: {
    all: {}
  }
}

export default {
  state,
  actions,
  mutations
}
