import { theme } from '@/components/graphs/types/Theme'
import { merge } from 'highcharts'

export default () => merge(true, theme(), {
  providers: null,
  customData: null,
  chart: {
    type: 'bar',
    backgroundColor: null,
    marginBottom: 70,
    events: {
      load: function () {
        if (!this.xAxis[0].categories) return
        if (this.options.providers === null || !this.options.providers.length) {
          this.options.providers = this.xAxis[0].categories.slice(0, 5)
        }
        const providerPositions = this.options.providers.map(p => this.xAxis[0].categories.indexOf(p))
        if (this.options.customData === null) {
          this.options.customData = {
            series: this.options.series,
            categories: this.xAxis[0].categories
          }
        }
        this.update({
          series: this.options.customData.series.map(serie => ({
            ...serie,
            data: serie.data.filter((value, index) => providerPositions.includes(index))
          })),
          xAxis: {
            ...this.userOptions.xAxis,
            categories: this.options.customData.categories.filter(c => this.options.providers.includes(c))
          }
        })
      }
    }
  },
  boost: { seriesThreshold: 5000 },
  colors: ['#FFA94D', '#7da7d9'],
  xAxis: {
    labels: {
      step: 1,
      reserveSpace: true,
      labels: { overflow: 'justify' },
      style: {
        fontSize: '12px',
        fontFamily: 'Open Sans',
        color: 'var(--secondary-color)'
      }
    },
    reversed: false,
    visible: true,
    lineColor: '#dbdbdb',
    lineWidth: 2,
    tickWidth: 0
  },
  yAxis: {
    title: { text: null },
    labels: { enabled: false },
    gridLineWidth: 0,
    plotLines: [{
      color: 'var(--secondary-color)',
      width: 3,
      value: 0,
      zIndex: 5
    }]
  },
  plotOptions: {
    bar: {
      grouping: false,
      events: { legendItemClick: () => false },
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        inside: false,
        padding: 10,
        style: {
          color: 'var(--secondary-color)',
          fontSize: '12px',
          fontFamily: 'Open Sans',
          fontWeight: '600',
          textOutline: 0
        },
        formatter: function () {
          const { unit = '' } = this.point.series.userOptions
          return `${Math.round(Math.abs(this.point.y))}${unit ? ' ' + unit : ''}`
        }
      }
    },
    series: {
      maxPointWidth: 30,
      minPointLength: 5
    }
  },
  legend: { symbolRadius: 0 },
  tooltip: {
    shared: true,
    pointFormatter: function () {
      return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${Math.round(Math.abs(this.y))} ${this.series.userOptions.unit}</b><br/>`
    }
  }
})
