import { sortBy, get } from '@/utils/lodash'
import types from './types'
import userTypes from '../users/types'
import { instances_list, instances_create, instances_update, instances_delete } from '@/api/methods'

// initial state
const state = {
  all: [],
  noInstancesAlert: false,
  selectedInstance: undefined
}

// getters
const getters = {
  currentInstance: state => state.selectedInstance,
  instancesList: state => state.all,
  getActiveInstances: state => state.all.filter(i => i.status === 'active'),
  allInstancesIds: state => sortBy(state.all, ['shortName', 'host']).map(instance => instance.id),
  getInstanceById: state => id => state.all.find(instance => instance.id === id)
}

// actions
const actions = {
  fetchInstances ({ commit }) {
    return instances_list()
      .then(response => {
        const instances = get(response, 'data', [])
        commit(types.INSTANCES, instances)
        commit('NO_INSTANCES_ALERT', instances.length === 0)
      })
  },

  createInstance ({ commit, getters, dispatch }, body) {
    return instances_create({ body })
      .then(response => {
        const instance = get(response, 'data', [])
        commit(types.ADD_INSTANCE, instance)
        commit('NO_INSTANCES_ALERT', false)
        return instance
      })
  },

  updateInstance: function ({ commit, getters, dispatch }, body) {
    return instances_update({ body, id: body.id }, { commit, getters, dispatch })
      .then(response => {
        const instance = get(response, 'data', [])
        commit(types.UPDATE_INSTANCE, instance)
        return instance
      })
  },

  deleteInstance ({ commit, getters, dispatch }, instance) {
    return instances_delete(instance, { commit, getters, dispatch })
      .then(() => {
        commit(userTypes.REMOVE_USER_INSTANCE, instance.id)
        commit(types.REMOVE_INSTANCE, instance.id)
      })
  },

  refreshInstance: ({ commit, getters, dispatch }, payload) => {
    const instance = getters.getInstanceById(payload.id)
    if (instance) {
      // Create an notification if the status changed
      if (instance.status !== payload.status) {
        commit('ADD_NOTIFICATION', {
          id: payload.id,
          title: payload.status !== 'active' ? 'Warning' : ' ',
          type: payload.status !== 'active' ? 'warn' : null,
          text: instance.shortName + ' Instance status - ' + payload.status
        })
      }

      // Update instance status
      commit(types.UPDATE_INSTANCE, {
        ...instance,
        ...payload
      })
    }
  }
}

// mutations
const mutations = {
  [types.INSTANCES] (state, instances) {
    state.all = instances
  },

  [types.ADD_INSTANCE] (state, instance) {
    // Preventing duplicates
    const foundInstance = state.all.find(i => i.id === instance.id)
    if (foundInstance) {
      state.all.splice(state.all.indexOf(foundInstance), 1, instance)
    } else {
      state.all.push(instance)
    }
  },

  [types.UPDATE_INSTANCE] (state, instance) {
    const foundInstance = state.all.find(i => i.id === instance.id)
    if (foundInstance) {
      state.all.splice(state.all.indexOf(foundInstance), 1, instance)
    }
  },

  [types.REMOVE_INSTANCE] (state, instanceId) {
    const foundInstance = state.all.find(i => i.id === instanceId)
    if (foundInstance) {
      state.all.splice(state.all.indexOf(foundInstance), 1)
    }
  },

  [types.NO_INSTANCES_ALERT] (state, alert) {
    state.noInstancesAlert = alert
  },
  [types.SELECTED_INSTANCE] (state, value) {
    state.selectedInstance = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
