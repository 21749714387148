import { get } from '@/utils/lodash'
import Type from './types'

/* eslint-disable-next-line */
import { profile_get, profile_update, security_login, security_logout, security_signup } from '@/api/methods'

export default {
  [Type.SIGNUP]: ({ commit }, body) => {
    return security_signup({ body })
      .then(response => {
        commit(Type.UPDATE_PROFILE, get(response, 'data', {}))
        return response
      })
  },
  [Type.LOGIN]: ({ commit }, body) => {
    return security_login({ body })
      .then(response => {
        commit(Type.UPDATE_PROFILE, get(response, 'data', {}))
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error))
  },
  [Type.LOGOUT]: ({ commit }) => {
    return security_logout().then(response => {
      commit(Type.REMOVE_PROFILE)
      return response
    })
  },
  [Type.FETCH_PROFILE]: ({ commit }) => {
    return profile_get()
      .then(response => {
        commit(Type.UPDATE_PROFILE, get(response, 'data', {}))
        return response
      })
  },
  updateProfile ({ commit, dispatch }, body) {
    return profile_update({ body })
      .then(() => {
        return dispatch(Type.FETCH_PROFILE)
      })
  },
  reboot ({ commit }) {
    return security_logout().then(() => commit(Type.REMOVE_PROFILE, true))
  },
  fetchProfileSchema () {
    return profile_update({ body: {}, id: '', $queryParameters: { schema: 'body' } })
  }
}
