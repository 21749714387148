export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  start: {
    name: 'start',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'start'
  },
  end: {
    name: 'end',
    in: 'query',
    description: 'Date and time start in the form (YYYY-MM-DD HH:MM:SS)',
    schema: { type: 'string', format: 'date-time' },
    camelCaseName: 'end'
  },
  providers: {
    name: 'providers',
    in: 'query',
    description: 'Providers',
    'x-ui': { dictionary: 'providers', component: 'ui-filter-select', props: { placeholder: 'Any Provider', multiple: true } },
    camelCaseName: 'providers'
  },
  page: {
    name: 'page',
    in: 'query',
    schema: { type: 'number', default: 1 },
    camelCaseName: 'page'
  },
  pageSize: {
    name: 'pageSize',
    in: 'query',
    description: 'Number of records',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Results per page' } },
    schema: { type: 'number', default: 20, allOf: [{ type: 'number', minimum: 1, errorMessage: 'must be > 0' }, { type: 'number', maximum: 300, errorMessage: 'must be < 300' }] },
    camelCaseName: 'pageSize'
  }
}
