export default {
  ADD_CONFIGURATION: 'ADD_CONFIGURATION',
  RESET_CONFIGURATION: 'RESET_CONFIGURATION',
  UPDATE_PARAMETER: 'UPDATE_PARAMETER',
  UPDATE_ENTITY_PARAMETER: 'UPDATE_ENTITY_PARAMETER',
  UPDATE_ENTITY_PARAMETER_BY_ID: 'UPDATE_ENTITY_PARAMETER_BY_ID',
  ADD_ENTITY: 'ADD_ENTITY',
  REMOVE_ENTITY: 'REMOVE_ENTITY',
  REMOVE_EXCHANGE_RULE: 'REMOVE_EXCHANGE_RULE',
  ADD_EXCHANGE_RULE: 'ADD_EXCHANGE_RULE',
  UPDATE_EXCHANGE_RULE_PARAMETER: 'UPDATE_EXCHANGE_RULE_PARAMETER',
  PBR_CHECK: 'PBR_CHECK',
  ADD_NEW_ENTITY: 'ADD_NEW_ENTITY',
  CLEAR_NEW_ENTITIES: 'CLEAR_NEW_ENTITIES',
  UPDATE_BUSY: 'UPDATE_BUSY'
}
