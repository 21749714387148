export default {
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  id: {
    in: 'path',
    name: 'id',
    required: true,
    schema: { type: 'string' },
    camelCaseName: 'id'
  }
}
