import actions from './actions'
import mutations from './mutations'

const state = {
  all: [],
  widgets: [],
  filters: null,
  currentDashboard: null
}

const getters = {
  getDashboardsNames: state => state.all.map(dashboard => dashboard.name),
  getDashboardById: state => id => state.all.find(dashboard => dashboard.id === id),
  getPredefinedDashboards: state => state.all.filter(dashboard => dashboard.predefined === true),
  getWidgetById: state => id => state.widgets.find(widget => widget.id === id)
}

export default {
  state,
  getters,
  mutations,
  actions
}
