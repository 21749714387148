import { getDomain } from './request'
export const policies_create_RAW_URL = () => '/irp/policies/{type}'

export const policies_create_URL = (parameters = {}) => {
  let path = policies_create_RAW_URL()
  let query = ''

  path = path.replace('{type}', `${parameters.type}`)
  return getDomain() + path + query
}
