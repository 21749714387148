import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: logos_get
 * url: /logos
 * @param type { String }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/logos'
  let body
  let queryParameters = {}
  let form = {}

  if (parameters.type === undefined) {
    return Promise.reject(new Error('Missing required String parameter: type'))
  }
  if (parameters.type !== undefined) {
    queryParameters.type = parameters.type
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
