import { getDomain } from './request'
export const tools_get_tasks_RAW_URL = () => '/tools/tasks'

export const tools_get_tasks_URL = (parameters = {}) => {
  let path = tools_get_tasks_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.tool !== undefined) {
    queryParameters.tool = parameters.tool
  }
  if (parameters.isHost !== undefined) {
    queryParameters.isHost = parameters.isHost
  }
  if (parameters.offset !== undefined) {
    queryParameters.offset = parameters.offset
  }
  if (parameters.limit !== undefined) {
    queryParameters.limit = parameters.limit
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
