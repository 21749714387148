export const providerParameterMappings = {
  description: 'description',
  asn: 'asn',
  bgpPeer: 'bgp_peer',
  bmp: 'bmp',
  rd: 'rd',
  '95thMode': '95th.mode',
  ccDisabled: 'cc_disable',
  loadBalancing: 'group_loadbalance',
  type: 'type',
  '95thInbound': '95th.in',
  snmpInterfaces: 'snmp.interfaces',
  shortName: 'shortname',
  globalGroup: 'global_group',
  ipv4DiagHop: 'ipv4.diag_hop',
  ipv4Probing: 'ipv4.master_probing',
  ipv4NextHop: 'ipv4.next_hop',
  ipv4NextHopAs: 'ipv4.next_hop_as',
  ipv6DiagHop: 'ipv6.diag_hop',
  ipv6Probing: 'ipv6.master_probing',
  ipv6NextHop: 'ipv6.next_hop',
  ipv6NextHopAs: 'ipv6.next_hop_as',
  cost: 'cost',
  limitLoad: 'limit_load',
  '95th': '95th',
  improveInGroup: 'improve_in_group',
  precedence: 'precedence',
  snpmInterfaces: 'snmp.interfaces',
  monIpv4ExternalState: 'mon.ipv4.external.state',
  monIpv6ExternalState: 'mon.ipv6.external.state',
  ipv4Mon: 'ipv4.mon',
  ipv6Mon: 'ipv6.mon',
  monIpv4InternalState: 'mon.ipv4.internal.state',
  monIpv6InternalState: 'mon.ipv6.internal.state',
  monIpv4BgpPeer: 'mon.ipv4.bgp_peer',
  monIpv6BgpPeer: 'mon.ipv6.bgp_peer',
  monIpv4InternalMode: 'mon.ipv4.internal.mode',
  monIpv6InternalMode: 'mon.ipv6.internal.mode',
  monSnmp: 'mon.snmp'
}

export const exchangeParameterMappings = {
  pbrCheck: 'pbr_check'
}

export const snmpHostMappings = {
  name: 'name',
  ip: 'ip',
  version: 'version',
  community: 'community',
  authPassword: 'auth_password',
  authProtocol: 'auth_protocol',
  authUsername: 'auth_username',
  privPassword: 'priv_password',
  privProtocol: 'priv_protocol',
  secLevel: 'seclevel'
}

export const globalParametersMapping = {
  ipv6Enabled: 'global.ipv6_enabled',
  infraIps: 'explorer.infra_ips',
  ourNets: 'collector.ournets',
  improveMode: 'global.improve_mode',
  flowEnabled: 'collector.flow.enabled',
  managementInterface: 'global.master_management_interface',
  probingInterfaces: 'global.master_probing_interface',
  globalFailover: 'global.failover',

  spanEnabled: 'collector.span.enabled',
  spanInterfaces: 'collector.span.interfaces',
  spanMinDelay: 'collector.span.min_delay',
  spanMinDelayProbingQueueSize: 'collector.span.min_delay.probing_queue_size',

  flowListenNf: 'collector.flow.listen.nf',
  flowListenSf: 'collector.flow.listen.sf',
  flowSources: 'collector.flow.sources'
}

export const groupsParametersMapping = {
  shortName: 'shortname',
  outbound95th: 'outbound.95th',
  outbound95thReserve: 'outbound.95th.reserve',
  outboundRateHigh: 'outbound.rate_high',
  outboundRateLow: 'outbound.rate_low',
  members: 'members'
}
