import { getDomain } from './request'
export const events_list_RAW_URL = () => '/events'

export const events_list_URL = (parameters = {}) => {
  let path = events_list_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.instance !== undefined) {
    queryParameters.instance = parameters.instance
  }
  if (parameters.user !== undefined) {
    queryParameters.user = parameters.user
  }
  if (parameters.type !== undefined) {
    queryParameters.type = parameters.type
  }
  if (parameters.scope !== undefined) {
    queryParameters.scope = parameters.scope
  }
  if (parameters.start !== undefined) {
    queryParameters.start = parameters.start
  }
  if (parameters.end !== undefined) {
    queryParameters.end = parameters.end
  }
  if (parameters.page !== undefined) {
    queryParameters.page = parameters.page
  }
  if (parameters.pageSize !== undefined) {
    queryParameters.pageSize = parameters.pageSize
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
