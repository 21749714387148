export default {
  is_prod: false,
  productionTip: false,
  HOST: window.location.hostname,
  WEB_SOCKET_PORT: 1024,
  WEB_SOCKET_PROTOCOL: 'wss://',
  WS_OPTIONS: {
    autoConnect: false,
    transports: ['websocket', 'polling']
  },
  WEB_SOCKET_HOST: function () {
    return this.WEB_SOCKET_PROTOCOL + this.HOST + ':' + this.WEB_SOCKET_PORT
  }
}
