import { getDomain } from './request'
export const getInboundPerformanceActiveFeedHistory_RAW_URL = () => '/irp/inboundperformance/rules/status/active/history'

export const getInboundPerformanceActiveFeedHistory_URL = (parameters = {}) => {
  let path = getInboundPerformanceActiveFeedHistory_RAW_URL()
  let query = ''
  let queryParameters = {}

  if (parameters.text !== undefined) {
    queryParameters.text = parameters.text
  }
  if (parameters.idrule !== undefined) {
    queryParameters.idrule = parameters.idrule
  }
  const keys = Object.keys(queryParameters)
  query = (keys.length ? '?' : '') + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&'))
  return getDomain() + path + query
}
