import { get, merge } from '@/utils/lodash'
import { createDefaultValue } from '@/components/config/utils/configuration'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import irp_get from '@/api/methods/irp_get'

/**
 * Internal function for filling default values to the parameters.
 *
 * @param parameters {Object} Actual model for filling with default values
 * @param entityMeta {Object} Json schema from API
 *
 * @return {{}}
 */
const fillWithDefaultValues = (parameters = {}, entityMeta) => {
  return Object
    .keys(entityMeta)
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: parameters[key] ?? createDefaultValue(entityMeta[key])
      }
    }, {})
}

/**
 * Sort parameters from json schema by their order defined by the key "gmiOrder".
 *
 * @param a {Object[gmiOrder]} JsonSchema object of parameter
 * @param b {Object[gmiOrder]} JsonSchema object of parameter
 *
 * @return {number}
 */
export const sortByOrder = (a, b) => {
  if (a.gmiOrder === b.gmiOrder) {
    return 0
  }
  return a.gmiOrder > b.gmiOrder ? 1 : -1
}

export const throwIfFalse = (value, message) => {
  if (value === false) throw new Error(message)

  return value
}

export const createEntity = (model, entityMeta) => {
  return merge({
    id: model?.id ?? null,
    parameters: fillWithDefaultValues(model?.parameters, entityMeta)
  }, model)
}

// if param in model is object, fill its properties
const fillRecursiveDefaults = (parameters = {}, properties = {}) => {
  return Object
    .keys(properties)
    .reduce((acc, key) => {
      if (properties[key].type === 'object') return { ...acc, [key]: fillRecursiveDefaults(parameters[key], properties[key].properties) }
      if (typeof parameters[key] !== 'undefined') return { ...acc, [key]: parameters[key] }

      return { ...acc, [key]: properties[key].default }
    }, {})
}

export const createCustomModel = (model, properties) => {
  return merge({
    id: model?.id ?? null,
    parameters: fillRecursiveDefaults(model?.parameters, properties)
  }, model)
}

// This function can't get all response errors, because there is not a standard for error responses in irp
export const checkIrpResponse = (response, instanceId) => {
  const { status, message } = get(response, `data.${instanceId}`, { status: '', message: '' })

  if (status !== 'error') return response

  if (message) throw new Error(message)

  throw new Error('Request failed')
}

export const specialCasesHandler = caseTitle => {
  const specialCases = ['Provider 95th percentile']
  return specialCases.includes(caseTitle)
}

/**
 * Takes correct type from meta. In case when parameter is nullable, type of parameter will be sended as array.
 * Example ['integer', 'null']
 */
export const typesHandler = type => {
  return Array.isArray(type) ? type[0] : type
}

/**
 * Remove from entity defaults or empty parameters. Some of them, is not acceptable
 * by configuration endpoint.
 *
 * @param model               {Object} State's model
 * @param metadataProperties  {Object} Properties of state's metadata
 */
export const cleanEntity = (model, metadataProperties) => {
  const parameters = Object
    .keys(metadataProperties)
    .reduce((acc, key) => {
      const isItVirtual = metadataProperties[key].virtual === true
      let isItEqual = !isEqual(model.parameters[key], createDefaultValue(metadataProperties[key]))
      if (specialCasesHandler(metadataProperties[key].title)) {
        isItEqual = true
      }

      // The reason why I used isEqual here is that the value can be an array
      if (isItEqual || isItVirtual) {
        acc[key] = model.parameters[key] === '' ? acc[key] = null : acc[key] = model.parameters[key]
        return acc
      }

      return acc
    }, {})

  return { id: model.id, parameters }
}

export const createParametersModel = (model, properties) => {
  return Object
    .keys(properties)
    .filter(key => properties[key].gmiWizardStep >= 0)
    .reduce((acc, key) => {
      acc[key] = model[key] ? model[key] : createDefaultValue(properties[key])
      return acc
    }, {})
}

export const filterEntityMappings = ({ globals, stepParameters, modelParameters }) => {
  const methods = {
    anyOf: Array.prototype.some,
    oneOf: Array.prototype.some,
    allOf: Array.prototype.every
  }

  return stepParameters.filter(({ metadata: { showWhen = null } }) => {
    if (showWhen === null) return true

    const cb = rule => {
      const value = modelParameters[rule.parameter] ?? globals.parameters[rule.parameter]

      if (!Array.isArray(rule.value)) return rule.value === value
      return rule.value.includes(value)
    }

    if (Array.isArray(showWhen)) return methods.allOf.call(showWhen, cb)

    if (typeof showWhen === 'object') {
      return Object.keys(showWhen).every(operation => methods[operation].call(showWhen[operation], cb))
    }

    throw new Error(`showWhen has an unhandled case -> [ showWhen: ${showWhen} ]`)
  }).sort((a, b) => sortByOrder(a.metadata, b.metadata))
}

/**
 * Return only properties which assigned to the step number.
 *
 * @param entityMeta {Object} Json schema from API
 * @param step       {Number} Step index number
 * @param filter     {Array}  Empty of filled array with parameters to be displayed
 *
 * @return {{metadata: *, id: *}[]}
 */
export const extractMappings = (entityMeta, step, filter = []) => {
  return Object.keys(entityMeta)
    .filter(key => {
      return Number(entityMeta[key].gmiWizardStep) === step && (filter.length === 0 || filter.includes(key))
    })
    .map(id => {
      return {
        id,
        metadata: entityMeta[id]
      }
    }).sort((a, b) => sortByOrder(a.metadata, b.metadata))
}

export const getSchema = (state, instance) => {
  return new Promise((resolve, reject) => {
    const { id } = instance

    return irp_get({ section: '/config/v2/meta', hosts: [id] })
      .then(({ data }) => resolve(data[id].data))
      .catch(error => reject(error))
  })
}

export const applyHook = (state, type) => {
  const hook = get(state.wizard, `configuration.hooks.${type}`, false)
  if (typeof hook === 'function') {
    hook(state, state)
  }
}

export const applySchemaHook = (schema, wizard) => {
  const hook = get(wizard, 'configuration.hooks.schema', false)
  return typeof hook === 'function'
    ? hook(schema)
    : schema
}

/**
 * This function actually don't need, and can be removed as soon all changes for wizards, will
 * be landed in IRP API, for metadata. But to be sure, we need to keep for some time, while all
 * clients will upgrade their instances to required version.
 *
 * @param params {Object} Describe how to change metadata
 * @param scope  {String} Root of meta
 *
 * @returns {(function(*))|*}
 */
export const createMetadataHook = (params, scope) => {
  return metadata => {
    const isArray = get(metadata, `properties.${scope}`, {})?.items !== undefined
    const path = `properties.${scope}${isArray ? '.items' : ''}.properties.parameters`
    let necessaryMeta = get(metadata, path, false)
    if (necessaryMeta === false) necessaryMeta = cloneDeep(metadata)

    Object
      .entries(params)
      .forEach(([key, value]) => { merge(necessaryMeta.properties[key], value) })

    return { [scope]: necessaryMeta }
  }
}

/**
 * Number of algorithms to extract error, from api response.
 *
 * @param response {Object{status: String, message: String}} Response object
 *
 * @return {Array<Error>}
 */
export const extractErrorFromServerResponse = (configuration, response) => {
  const { message = '' } = response
  const keys = []
  const errors = []

  configuration.hasOwnProperty('parameters')
    ? keys.push(...Object.keys(configuration.parameters))
    : keys.push(...Object.keys(configuration))

  // First when message contains list of errors: 'properties 'ip', 'name' are missing'
  const r1 = new RegExp('^properties\\s+(.*)\\s+are\\s+missing')
  const extracted = r1.exec(message)?.[1] ?? ''
  if (extracted) {
    const missing = keys.filter(k => extracted.includes(k))
    if (missing.length) {
      missing.forEach(key => {
        errors.push({ key, message: `${key} is required` })
      })
    }
  }

  return errors
}
