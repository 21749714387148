const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/components/common/NotFound')

export default function () {
  return {
    path: '*',
    component: NotFound,
    meta: {
      requiredAuth: true,
      layout: 'LoggedLayout'
    }
  }
}
