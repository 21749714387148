import { matches } from '@/utils/lodash'
import Type from './types'

export default {
  [Type.ADD_TASK] (state, task) {
    state.tasks.push(task)
  },
  [Type.REMOVE_TASK] (state, id) {
    const index = state.tasks.findIndex(matches({ id }))
    if (index !== -1) {
      state.tasks.splice(index, 1)
    }
  },
  [Type.UPDATE_TASK] (state, properties) {
    const { id } = properties
    const task = state.tasks.find(matches({ id }))
    if (task) {
      if (task.tool === 'prefixProbing' && !task.result && properties.result) {
        task.canBeRerouted = true
      }
      Object.assign(task, properties)
    }
  }
}
