const isAfter = require('date-fns/isAfter/index')
const add = require('date-fns/add/index')

const saveFromStringToJson = str => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return str
  }
}

/**
 * Returns all items stored in local storage
 *
 * @returns {Map<string, any>}
 */
export const all = () => new Map(Object.entries(localStorage))

/**
 * Get item from locale storage, if not expired
 * @see setItem
 * @see isAfter
 */
export const getItem = (key, defaultValue = undefined) => {
  const value = localStorage.getItem(key)

  // do not do anything, if the value is null
  if (!value) return defaultValue

  // in some cases where was a bug related to this
  if (value === 'undefined') return defaultValue

  // check if expire is set
  const time = localStorage.getItem(`${key}_expireIn`)
  if (time === null) return saveFromStringToJson(value) || defaultValue

  const expire = new Date(Number(time))
  if (!expire.getTime()) return defaultValue

  return isAfter(expire.getTime(), Date.now())
    ? saveFromStringToJson(value)
    : defaultValue
}

/**
 * Set localStorage key => value, and expiration time if needed.
 *
 * @param key       {String}        Key for localStorage
 * @param value     {Array|Object}  Value
 * @param expireIn  {Object}        See date fns add function, for arguments
 *
 * @see add
 * @see getItem
 * @return {Any}
 */
export const setItem = (key, value, expireIn = {}) => {
  if (typeof value === 'string') {
    localStorage.setItem(key, value)
  } else if (typeof value === 'object') {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    localStorage.setItem(key, value)
  }

  const { day, minutes, seconds, hours } = expireIn
  if (day || minutes || seconds || hours) {
    const duration = {}
    if (day) duration.day = Number(day)
    if (minutes) duration.minutes = Number(minutes)
    if (seconds) duration.seconds = Number(seconds)
    if (hours) duration.hours = Number(hours)

    localStorage.setItem(`${key}_expireIn`, add(Date.now(), duration).getTime())
  }

  return value
}

export const hasItem = key => {
  const value = localStorage.getItem(key)

  // do not do anything, if the value is null
  if (!value) return false

  // check if expire is set
  const expire = new Date(localStorage.getItem(`${key}_expireIn`))
  if (expire.getTime() === 0) return false

  return isAfter(Date.now(), expire.getTime())
}

/**
 * Clear localStorage item and it's cache
 *
 * @param key {String} Key to clear
 */
export const clearItem = key => {
  localStorage.removeItem(key)
  localStorage.removeItem(`${key}_expireIn`)
}

/**
 * Export as module
 */
export default {
  all,
  getItem,
  setItem,
  hasItem,
  clearItem
}
