import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: polling_get
 * url: /polling
 * @param hosts { Array }
 * @param alias { String }
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/polling'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.alias === undefined) {
    return Promise.reject(new Error('Missing required String parameter: alias'))
  }
  if (parameters.alias !== undefined) {
    queryParameters.alias = parameters.alias
  }
  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
