export default {
  page: {
    name: 'page',
    in: 'query',
    schema: { type: 'number', default: 1 },
    camelCaseName: 'page'
  },
  sortBy: {
    name: 'sortBy',
    in: 'query',
    schema: { type: 'string', default: 'ts' },
    camelCaseName: 'sortBy'
  },
  order: {
    name: 'order',
    in: 'query',
    schema: { type: 'string', enum: ['ASC', 'DESC'], default: 'DESC' },
    camelCaseName: 'order'
  },
  hosts: {
    name: 'hosts',
    in: 'header',
    required: true,
    schema: { type: 'array', items: { type: 'string' } },
    camelCaseName: 'hosts'
  },
  provider: {
    name: 'provider',
    in: 'query',
    description: 'Provider',
    'x-ui': { dictionary: 'providers', component: 'ui-filter-select' },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'provider'
  },
  ipVer: {
    name: 'ipVer',
    in: 'query',
    description: 'IPv4/IPv6',
    'x-ui': { dictionary: 'ipVersions', component: 'ui-filter-select', props: { placeholder: 'Any IP version' } },
    schema: { oneOf: [{ type: 'string' }, { type: 'number' }] },
    camelCaseName: 'ipVer'
  },
  month: {
    in: 'query',
    name: 'month',
    description: 'Month for current year',
    'x-ui': { dictionary: 'monthsYear', component: 'ui-filter-select-month-year' },
    schema: { type: 'string' },
    camelCaseName: 'month'
  },
  pageSize: {
    name: 'pageSize',
    in: 'query',
    description: 'Number of records',
    'x-ui': { component: 'ui-filter-input', props: { placeholder: 'Results per page' } },
    schema: { type: 'number', default: 20, allOf: [{ type: 'number', minimum: 1, errorMessage: 'must be > 0' }, { type: 'number', maximum: 300, errorMessage: 'must be < 300' }] },
    camelCaseName: 'pageSize'
  },
  year: {
    name: 'year',
    in: 'query',
    schema: { type: 'string' },
    camelCaseName: 'year'
  }
}
