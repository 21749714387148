import Vue from 'vue'
import { get } from '@/utils/lodash'
import * as API from '@/api/methods'

export default {
  methods: {
    requestResource (name, parameters = {}, handleError) {
      if (!(name in API)) {
        return Promise.reject(new Error('No such network resource ' + name))
      }

      this.setLoading(true)

      if (handleError) {
        return API[name](parameters)
          .catch(this.networkError)
          .finally(this.setLoading)
      } else {
        return API[name](parameters).finally(this.setLoading)
      }
    },
    setLoading (state = false) {
      if (this && 'loading' in this) {
        if (typeof this.loading === 'boolean') {
          Vue.set(this, 'loading', state)
        } else if (typeof this.loading === 'function') {
          this.loading(state)
        }
      }
    },
    networkError (error) {
      this.showNotification({
        type: 'error',
        group: 'bottom',
        title: 'Error occurred',
        body: get(error, 'errors', get(error, 'data', 'Unknown error'))
      })
    }
  }
}
