import { getDomain, request, applyQueryParameters } from './request'

/**
 *
 * request: getHistoricalRecords
 * url: /irp/reports/historicalRecords
 * @param page {  }
 * @param sortBy { String }
 * @param order { String }
 * @param hosts { Array }
 * @param prefix { String } Prefix/IP
 * @param prefixSize {  } Prefix size
 * @param asnumber {  } AS number
 * @param asname { String } AS name
 * @param bgpdMode {  } BGPd mode
 * @param ipVer {  } IPv4/IPv6
 * @param providerFrom {  } From provider
 * @param providerTo {  } To provider
 * @param probingSource {  } Probing source
 * @param improvementAge {  } Improvement age
 * @param rd {  } RD
 * @param type { Array } Improvement type
 * @param start { String } Date and time start in the form (YYYY-MM-DD HH:MM:SS)
 * @param end { String } Date and time start in the form (YYYY-MM-DD HH:MM:SS)
 * @param pageSize {  } Number of records
 */
export default function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {}
  let path = '/irp/reports/historicalRecords'
  let body
  let queryParameters = {}
  let form = {}

  if (!config.headers) {
    config.headers = {}
  }
  if (parameters.page !== undefined) {
    queryParameters.page = parameters.page
  } else if (parameters.page === undefined) {
    queryParameters.page = 1
  }
  if (parameters.sortBy !== undefined) {
    queryParameters.sortBy = parameters.sortBy
  } else if (parameters.sortBy === undefined) {
    queryParameters.sortBy = 'ts'
  }
  if (parameters.order !== undefined) {
    queryParameters.order = parameters.order
  } else if (parameters.order === undefined) {
    queryParameters.order = 'DESC'
  }
  if (parameters.hosts === undefined) {
    return Promise.reject(new Error('Missing required Array parameter: hosts'))
  }
  config.headers['x-hosts'] = parameters.hosts
  if (parameters.prefix !== undefined) {
    queryParameters.prefix = parameters.prefix
  } if (parameters.prefixSize !== undefined) {
    queryParameters.prefixSize = parameters.prefixSize
  } if (parameters.asnumber !== undefined) {
    queryParameters.asnumber = parameters.asnumber
  } if (parameters.asname !== undefined) {
    queryParameters.asname = parameters.asname
  } if (parameters.bgpdMode !== undefined) {
    queryParameters.bgpdMode = parameters.bgpdMode
  } if (parameters.ipVer !== undefined) {
    queryParameters.ipVer = parameters.ipVer
  } if (parameters.providerFrom !== undefined) {
    queryParameters.providerFrom = parameters.providerFrom
  } if (parameters.providerTo !== undefined) {
    queryParameters.providerTo = parameters.providerTo
  } if (parameters.probingSource !== undefined) {
    queryParameters.probingSource = parameters.probingSource
  } if (parameters.improvementAge !== undefined) {
    queryParameters.improvementAge = parameters.improvementAge
  } if (parameters.rd !== undefined) {
    queryParameters.rd = parameters.rd
  } if (parameters.type !== undefined) {
    queryParameters.type = parameters.type
  } if (parameters.start !== undefined) {
    queryParameters.start = parameters.start
  } if (parameters.end !== undefined) {
    queryParameters.end = parameters.end
  } if (parameters.pageSize !== undefined) {
    queryParameters.pageSize = parameters.pageSize
  } else if (parameters.pageSize === undefined) {
    queryParameters.pageSize = 20
  }

  applyQueryParameters(queryParameters, parameters.$queryParameters)

  return request('get', domain + path, body, queryParameters, form, config)
}
