import colors from '@/constants/colors'

export default () => ({
  chart: {
    type: 'area',
    zoomType: 'x'
  },
  boost: { seriesThreshold: 5000 },
  title: {
    text: ''
  },
  colors,
  credits: {
    enabled: false
  },
  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%e %b %k:%M}'
    },
    tickmarkPlacement: 'on',
    title: {
      enabled: false
    }
  },
  yAxis: {
    title: {
      text: ''
    },
    min: 0,
    minRange: 0.1
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'bottom',
    itemStyle: {
      color: 'rgba(var(--secondary-color-1), var(--secondary-color-opacity, .5))'
    },
    itemHoverStyle: {
      color: 'var(--secondary-color)'
    }
  },
  tooltip: {
    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f} prefixes)<br/>',
    split: true
  },
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    }]
  },
  series: {
    area: {
      borderWidth: 1
    }
  },
  plotOptions: {
    area: {
      stacking: 'percent',
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 3
      },
      accessibility: {
        pointDescriptionFormatter: function (point) {
          function round (x) {
            return Math.round(x * 100) / 100
          }
          return (point.index + 1) + ', ' + point.category + ', ' +
            point.y + ' %' + round(point.percentage) + '%, ' +
            point.series.name
        }
      }
    }
  }
})
